import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';
import './index.css';
import LoginRouter from './pages/LoginRouter/LoginRouter';
import { AuthWrapper } from './shared/auth/AuthWrapper/AuthWrapper';
import { SnackbarProvider } from './shared/contexts/SnackbarContext';
import AccountPreferencesContextProvider from './shared/contexts/accountPreferencesContext';
import CompanyLinksContextProvider from './shared/contexts/companyLinksContext';
import DialogContextProvider from './shared/contexts/dialogContext';
import { ErrorRetryContextProvider } from './shared/contexts/errorRetryContext';
import FilterContextProvider from './shared/contexts/filterContext';
import { FirebaseApiContextWrapper } from './shared/contexts/firebaseApiContext';
import NotificationContextProvider from './shared/contexts/notificationContext';
import SitesContextProvider from './shared/contexts/siteContext';
import UserContextProvider from './shared/contexts/userContext';
import AppConfiguration from './shared/models/AppConfiguration';
import CommonComponentWrapper from './shared/org/CommonComponentWrapper';
import PackageContextProvider from './shared/contexts/packageContext';
import createNewTheme from './shared/lib/styles/theme';

interface AppProps {
  config: AppConfiguration;
}

const queryClient = new QueryClient({ defaultOptions: { queries: { useErrorBoundary: true } } });

export default function App(props: AppProps) {
  const { config } = props;
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);

  useEffect(() => {
    const app = initializeApp(config);
    setFirebaseApp(app);
  }, [config]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-N9JJ3R7',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  // TODO: Load Screen
  if (!firebaseApp) {
    return null;
  }

  const theme = createNewTheme(darkMode, true);

  setLocale({
    mixed: {
      required: t('formMessage.required'),
    },
  });

  // For some reason, overrides within the theme aren't working. Will investigate later.
  if (theme.components && theme.components.MuiCheckbox && theme.components.MuiCheckbox.defaultProps) {
    theme.components.MuiCheckbox.defaultProps = {
      sx: { color: theme.palette.grey[500] },
    };
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthWrapper>
        <CommonComponentWrapper>
          <NotificationContextProvider>
            <FirebaseApiContextWrapper app={firebaseApp}>
              <UserContextProvider vapidKey={config.vapidKey}>
                <PackageContextProvider>
                  <SitesContextProvider>
                    <AccountPreferencesContextProvider setDarkMode={setDarkMode} preferencesToSet={null}>
                      <ThemeProvider theme={theme}>
                        <CssBaseline enableColorScheme />
                        <SnackbarProvider>
                          <ErrorRetryContextProvider>
                            <CompanyLinksContextProvider linksToSet={null} sidebarLinksToSet={null}>
                              <DialogContextProvider>
                                <FilterContextProvider>
                                  <LoginRouter apiRoot={config.apiRoot} ncridUrl={config.ncridUrl} />
                                </FilterContextProvider>
                              </DialogContextProvider>
                            </CompanyLinksContextProvider>
                          </ErrorRetryContextProvider>
                        </SnackbarProvider>
                      </ThemeProvider>
                    </AccountPreferencesContextProvider>
                  </SitesContextProvider>
                </PackageContextProvider>
              </UserContextProvider>
            </FirebaseApiContextWrapper>
          </NotificationContextProvider>
        </CommonComponentWrapper>
      </AuthWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
