import { Res } from '@cbo/shared-library';

// eslint-disable-next-line import/prefer-default-export
export const formatStringList = (list: string[], preferences?: Res.Admin.UserPreferences): string => {
  const listFormatter = new Intl.ListFormat(preferences?.language ?? 'en-US', { style: 'long', type: 'conjunction' });

  return listFormatter.format(list);
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};

/**
 * Capitalize the first letter of every word.
 * https://en.wikipedia.org/wiki/Letter_case#:~:text=capital%20case%2C%20headline%20style
 * @param str
 * @returns A new string with the first letter of every word capitalized.
 */
export const convertToTitleCase = (str: string): string =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
