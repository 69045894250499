import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearOutlined from '@mui/icons-material/ClearOutlined';

export type ButtonResetIconProps = {
  handleReset: () => void;
};

function ButtonResetIcon({ handleReset }: ButtonResetIconProps) {
  const theme = useTheme();
  return (
    <IconButton
      component='div'
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleReset();
      }}
      sx={{ maxHeight: '15px', maxWidth: '15px', marginLeft: '4px', marginRight: '4px' }}
      data-testid='filter-button-reset'
    >
      <ClearOutlined sx={{ color: theme.palette.primary.main, fontSize: 'medium' }} fontSize='inherit' />
    </IconButton>
  );
}

export default ButtonResetIcon;
