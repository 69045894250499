import React, { ReactNode } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Ty from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import SplitButton, { ISplitButtonOption } from '../SplitButton/SplitButton';
import colors from '../../lib/styles/colors';

export interface DrawerWrapperProps {
  open: boolean;
  closeDrawer: () => void;
  children: React.ReactNode;
  onHeaderActionClick?: () => void;
  headerActionButtonText?: string;
  headerActionButtonType?: 'outlined' | 'contained';
  onActionsEditClick?: () => void;
  onActionsDeleteClick?: () => void;
  onFooterActionClick?: () => void;
  hasCancelButton?: boolean;
  onFooterCancelClick?: () => void;
  footerActionButtonText?: string;
  footerActionCloseText?: string;
  hasActionsMenu?: boolean;
  hasDeleteButton?: boolean;
  hasSaveFooter?: boolean;
  hasSaveExitButton?: boolean;
  useProcessingFlow?: boolean;
  isProcessing?: boolean;
  drawerWidth?: number;
  disabled?: boolean;
  splitAction?: boolean;
  splitActionAdditionalOptions?: ISplitButtonOption[];
  customFooter?: ReactNode;
}

function DrawerWrapper({
  open,
  closeDrawer,
  children,
  onHeaderActionClick,
  headerActionButtonText,
  headerActionButtonType,
  hasSaveFooter,
  onActionsEditClick,
  onActionsDeleteClick,
  hasActionsMenu,
  hasDeleteButton,
  footerActionCloseText,
  onFooterActionClick,
  hasCancelButton,
  hasSaveExitButton,
  onFooterCancelClick,
  footerActionButtonText,
  useProcessingFlow,
  isProcessing,
  drawerWidth,
  disabled,
  splitAction,
  splitActionAdditionalOptions,
  customFooter,
}: DrawerWrapperProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const topSpacing = theme.spacing(9);
  const isMediumOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  React.useEffect(() => {
    if (!open && menuAnchorEl !== null) {
      handleMenuClose();
    }
  }, [open, menuAnchorEl]);

  const splitActionOptions: ISplitButtonOption[] = [];
  if (splitAction && onHeaderActionClick) {
    if (headerActionButtonText)
      splitActionOptions.push({
        text: headerActionButtonText,
        callback: onHeaderActionClick,
        sx: { display: 'none' }, // hides button from dropdown list
      });
    splitActionAdditionalOptions?.forEach((option) => {
      splitActionOptions.push(option);
    });
  }

  return (
    <Drawer
      open={open}
      variant={isMediumOrLess || isMobile ? 'temporary' : 'persistent'}
      anchor='right'
      sx={{ width: `${drawerWidth}px` }}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : `${drawerWidth}px`,
          boxSizing: 'border-box',
          marginBottom: '16px',
          paddingTop: 0,
          height: isMobile ? '100%' : `calc(100% - ${topSpacing})`,
          top: isMobile ? 0 : topSpacing,
          border: `1px solid ${colors.surfaceBorder[theme.palette.mode]}`,
        },
      }}
      data-testid='drawer-container'
    >
      {/* Drawer button header */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          background: theme.palette.background.paper,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', padding: '8px 24px', justifyContent: 'space-between' }}>
          <Button variant='text' startIcon={<CloseIcon />} onClick={closeDrawer} data-testid='drawer-action-close-btn'>
            {t('buttonText.close')}
          </Button>
          <Stack direction='row' spacing={1}>
            {hasActionsMenu && (
              <>
                <Button onClick={handleMoreClick} variant='text' size='medium' startIcon={<MoreVertIcon />}>
                  {t('buttonText.more')}
                </Button>
                <Menu
                  open={isMenuOpen}
                  anchorEl={menuAnchorEl}
                  onClose={handleMenuClose}
                  sx={{
                    '.MuiPaper-root': {
                      borderRadius: '4px',
                      boxShadow: 5,
                    },
                  }}
                  data-testid='drawer-action-more-dropdown-menu'
                >
                  {onActionsEditClick && (
                    <MenuItem dense onClick={onActionsEditClick} data-testid='edit-menu-item'>
                      <Ty variant='body1'>{t('buttonText.edit')}</Ty>
                    </MenuItem>
                  )}
                  {onActionsDeleteClick && (
                    <MenuItem
                      dense
                      onClick={onActionsDeleteClick}
                      sx={{ marginTop: '8px', marginBottom: '8px' }}
                      data-testid='delete-menu-item'
                    >
                      <Ty variant='body1'>{t('buttonText.delete')}</Ty>
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
            {hasDeleteButton && (
              <Button
                variant='text'
                color='error'
                onClick={onActionsDeleteClick}
                data-testid='drawer-action-delete-btn'
              >
                {t('buttonText.delete')}
              </Button>
            )}
            {!!headerActionButtonText &&
              !!onHeaderActionClick &&
              (splitAction ? (
                <SplitButton
                  dataTestId='drawer-wrapper-split-btn'
                  optionsMapping={splitActionOptions}
                  sx={{
                    minWidth: '112',
                    height: '40px',
                  }}
                  variant='outlined'
                />
              ) : (
                <Button
                  variant={headerActionButtonType}
                  sx={{ marginLeft: '16px' }}
                  onClick={onHeaderActionClick}
                  data-testid='drawer-action-btn'
                >
                  {headerActionButtonText}
                </Button>
              ))}
          </Stack>
        </Box>
        <Divider light />
      </Box>

      {/* Drawer content */}
      <Grid
        sx={{ padding: '0 24px', height: '100%', overflowY: 'scroll' }}
        container
        spacing={3}
        marginTop={0}
        data-testid='drawer-content'
      >
        {children}
      </Grid>
      {/* Drawer footer */}
      {(hasSaveFooter || customFooter) && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            paddingBottom: 2,
            background: theme.palette.background.paper,
          }}
          data-testid='footer-container'
        >
          <Divider sx={{ borderColor: '#0000001f' }} />
          {customFooter}
          {hasSaveFooter && (
            <Box sx={{ padding: '16px 24px 0px 24px' }}>
              {hasSaveExitButton && (
                <Button
                  variant='outlined'
                  type='submit'
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  onClick={onFooterCancelClick}
                  data-testid='drawer-action-save-exit-btn'
                  disabled={disabled}
                >
                  {footerActionCloseText ?? t('buttonText.saveAndExit')}
                </Button>
              )}
              {hasCancelButton && (
                <Button
                  variant='outlined'
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  onClick={onFooterCancelClick ?? closeDrawer}
                  data-testid='drawer-action-cancel-btn'
                >
                  {footerActionCloseText ?? t('buttonText.cancel')}
                </Button>
              )}
              {useProcessingFlow ? (
                <Button
                  variant='contained'
                  fullWidth
                  type='submit'
                  onClick={onFooterActionClick}
                  data-testid='drawer-action-save-btn'
                  disabled={isProcessing || disabled}
                >
                  <CircularProgress
                    color='inherit'
                    size={20}
                    sx={{ display: isProcessing ? 'inherit' : 'none', marginRight: '8px' }}
                    data-testid='drawer-action-processing'
                  />
                  {isProcessing ? t('buttonText.processing') : t('buttonText.save')}
                </Button>
              ) : (
                <Button
                  variant='contained'
                  fullWidth
                  type='submit'
                  onClick={onFooterActionClick}
                  data-testid='drawer-action-save-btn'
                  disabled={disabled}
                >
                  {footerActionButtonText ?? t('buttonText.save')}
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  );
}

DrawerWrapper.defaultProps = {
  onHeaderActionClick: undefined,
  onActionsEditClick: undefined,
  onActionsDeleteClick: undefined,
  headerActionButtonType: 'outlined',
  headerActionButtonText: undefined,
  hasSaveFooter: false,
  hasActionsMenu: false,
  hasDeleteButton: false,
  hasSaveExitButton: false,
  onFooterActionClick: undefined,
  hasCancelButton: true,
  onFooterCancelClick: undefined,
  footerActionButtonText: undefined,
  footerActionCloseText: undefined,
  useProcessingFlow: false,
  isProcessing: false,
  disabled: false,
  customFooter: undefined,
  drawerWidth: 350,
  splitAction: false,
  splitActionAdditionalOptions: [],
};

export default DrawerWrapper;
