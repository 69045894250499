import MuiSkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton';
import { forwardRef } from 'react';

// Contains only component for now, but will be extended as more
// custom components are added to the design system
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NCRCommonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is for component override prop
  // component?: any;
}

export interface SkeletonProps extends NCRCommonProps, MuiSkeletonProps {}

/**
 * ### Provides visual feedback to the user while content is loading or being fetched from a server
 *
 * - [NCR Storybook](https://storybook.ncr.com/?path=/docs/feedback-skeleton--quick-look)
 *
 * Related:
 *
 * - [Material UI API](https://mui.com/material-ui/api/skeleton/)
 */

export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({ ...rest }, ref) => (
  <MuiSkeleton ref={ref} data-testid='Skeleton' {...rest} />
));
