import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { LaborRule, LaborRuleConfigData } from '../models/LaborRuleConfiguration';
import {
  isChangesSetCustomRules,
  preProcessRuleConfigsForTemplates,
  areDefaultRules,
  isMinorDefinedSchoolDaysRule,
  isPredictabilityPayRule,
} from '../utils/utils';
import LaborRuleTemplate from '../LaborRuleTemplate/LaborRuleTemplate';
import { Exemptions } from '../Exemptions/Exemptions';

export interface RuleWithEffectiveDateProps {
  effectiveDate: string;
  effectiveUntil?: string;
  currentConfigs: LaborRuleConfigData[];
  ruleType: string;
  mainView?: boolean;
  currentDefaultRuleConfigs: LaborRuleConfigData[];
  locality?: string;
}

export function RuleWithEffectiveDate(props: RuleWithEffectiveDateProps) {
  const { effectiveDate, effectiveUntil, currentConfigs, ruleType, mainView, currentDefaultRuleConfigs, locality } =
    props;
  const configs = preProcessRuleConfigsForTemplates(currentConfigs, ruleType);
  const { t } = useTranslation();
  const mainViewEffectiveBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  };
  const mainViewEffectiveTextStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };
  const mainViewTypographyStyles = { fontSize: '.875rem', color: 'text.secondary', fontWeight: 400 };
  const effectiveCopy = effectiveUntil
    ? `, effective until ${effectiveUntil.split('-')[1]}/${effectiveUntil.split('-')[2]}/${
        effectiveUntil.split('-')[0]
      } `
    : effectiveDate &&
      `, effective ${effectiveDate.split('-')[1]}/${effectiveDate.split('-')[2]}/${effectiveDate.split('-')[0]} `;
  return configs.length > 0 ? (
    <Box data-testid='current-rule-templates-div' sx={mainView ? mainViewEffectiveBoxStyle : undefined}>
      <Box data-testid='effective-copy-div' sx={mainView ? mainViewEffectiveTextStyle : undefined}>
        {isMinorDefinedSchoolDaysRule({ type: ruleType } as LaborRule) && mainView && (
          <Typography>{t('laborRules.rules.MinorSchoolDayDefinitionRule.header')}</Typography>
        )}
        <Typography sx={mainView ? mainViewTypographyStyles : { fontWeight: 500 }}>
          {isChangesSetCustomRules(currentConfigs) && !areDefaultRules(currentDefaultRuleConfigs, currentConfigs)
            ? t('laborRules.editRuleView.customRuleLabel')
            : t('laborRules.editRuleView.jurisdictionDefaultLabel')}
          {effectiveCopy}
          {!mainView && `(${t('laborRules.editRuleView.currentRuleLabel')})`}
        </Typography>
      </Box>
      {configs.map((config: LaborRuleConfigData, index: number) => (
        <LaborRuleTemplate
          // eslint-disable-next-line react/no-array-index-key
          key={`edit-rule-${index}-${config.rule.type}-${config.id}`}
          id={index}
          rule={config.rule}
          focusMode
          sx={{ m: 0, mt: 2 }}
          locality={locality}
        />
      ))}
      {isPredictabilityPayRule(configs[0].rule) && <Exemptions selectedExemptions={configs[0].rule.exemptions} />}
    </Box>
  ) : (
    <Typography data-testid='no-rules-configured-text'> {t('laborRules.editRuleView.noRulesConfigured')} </Typography>
  );
}

RuleWithEffectiveDate.defaultProps = {
  mainView: false,
  effectiveUntil: undefined,
  locality: undefined,
};
