import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { CboRole, PersonaRole } from '@cbo/shared-library';
import { Redirect } from 'react-router-dom';
import PlaceholderPage from '../../../pages/PlaceholderPage';
import { useUsers } from '../../contexts/userContext';
import DashboardPage from '../DashboardPage/DashboardPage';
import { useEmployeeDetailsSelfQuery, useGetEmployeeByEmployeeIdQuery } from '../../labor/requests/queries';
import routes from '../../constants/routes';
import { EmploymentStatus } from '../../labor/types';
import isPermitted from '../../lib/permissions';

function HomePage() {
  const user = useUsers();
  const isAuthenticated =
    user.fullyAuthenticated === 'authenticated' && isPermitted(user, [CboRole.EMPLOYEE_PERSONAL_EDIT]);
  const shouldCallEmployeeSelfQuery = !(user.bslAuth?.personas || []).every((persona) =>
    [PersonaRole.ADMIN, PersonaRole.SUPPORT].includes(persona)
  );

  const { data, isLoading } = useEmployeeDetailsSelfQuery(isAuthenticated && shouldCallEmployeeSelfQuery);

  const { data: employeeDetails, isLoading: isEmployeeDetailsInitalLoading } = useGetEmployeeByEmployeeIdQuery(
    data?.employeeId ?? '',
    isAuthenticated && shouldCallEmployeeSelfQuery
  );

  const loadingComponent = (
    <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
      <CircularProgress />
    </Grid>
  );

  if (user.fullyAuthenticated === 'authenticated') {
    if (user.bslAuth?.roles.includes(CboRole.ADMIN_DASHBOARD_VIEWER)) {
      return <DashboardPage />;
    }

    if (isLoading || isEmployeeDetailsInitalLoading) {
      return loadingComponent;
    }
    if (employeeDetails?.employmentStatus === EmploymentStatus.HIRED) {
      return <Redirect to={routes.MY_SCHEDULE} />;
    }
    return <Redirect to={routes.EMPLOYEE_REGISTRATION_HOME} />;
  }

  if (user.oktaStatus === 'unauthenticated') {
    return <PlaceholderPage contentText='Welcome to NCR Aloha Smart Manager' dataTestId='temp-landing-page' />;
  }

  return loadingComponent;
}

export default HomePage;
