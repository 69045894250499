import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import SchedulingUtilities from '../../utilities/schedulingUtilities';

interface SelectDaysFieldProps {
  selectedDays: string[];
  weekStart: number;
  isReadOnly: boolean;
  handleChange?: (value: string[]) => void;
}

function SelectDaysField({ selectedDays, weekStart, isReadOnly, handleChange }: SelectDaysFieldProps) {
  const theme = useTheme();
  const [selected, setSelected] = useState<string[]>(selectedDays);
  const applyToData = SchedulingUtilities.configureApplyToStartDay(weekStart);

  const handleSelection = (newValue: string[]) => {
    setSelected(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
  };

  // TODO: Use styling instead of introducing new theme provider.
  const selectedDaysTheme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: theme.palette.common.white,
              background: theme.palette.primary.dark,
            },
            '&.Mui-disabled': {
              color: theme.palette.common.white,
            },
            '&:hover': {
              borderColor: theme.palette.grey[500],
              background: theme.palette.grey[500],
            },
            '&:hover&.Mui-selected': {
              borderColor: theme.palette.primary.main,
              background: theme.palette.primary.main,
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={selectedDaysTheme}>
      <Box display='flex' justifyContent='center'>
        <ToggleButtonGroup
          exclusive={false}
          value={selected}
          onChange={(event, val) => handleSelection(val)}
          sx={{
            '.MuiToggleButtonGroup-grouped': {
              margin: theme.spacing(1.5),
              borderRadius: '50% !important',
              border: '1px solid',
              borderColor: 'transparent',
            },
          }}
        >
          {applyToData.map((day) => (
            <ToggleButton
              data-testid={`select-days-${day.key}`}
              key={day.key}
              value={day.day}
              aria-label={day.label}
              disabled={isReadOnly}
              sx={{
                width: 33,
                height: 33,
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '14px',
                color: theme.palette.common.white,
                background: theme.palette.grey[500],
                textTransform: 'capitalize',
              }}
            >
              {day.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </ThemeProvider>
  );
}

SelectDaysField.defaultProps = {
  handleChange: undefined,
};

export default SelectDaysField;
