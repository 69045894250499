/* eslint-disable react/require-default-props */
import Box, { BoxProps } from '@mui/material/Box';
import Backdrop, { BackdropProps } from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { SidebarContext, useSidebar } from './CustomSidebarContext';
import colors from '../../../../lib/styles/colors';

const cap = (str: string) => str[0].toUpperCase() + str.slice(1);

export type SideProps = {
  className?: string;
  children?: ReactNode;
  content?: ReactNode;
  width?: number | string;
  side?: 'left' | 'right';
  hidden?: boolean;
  slotProps?: {
    sidebar?: BoxProps<'div'>;
    backdrop?: BackdropProps<'div'>;
    contentContainer?: BoxProps<'div'>;
  };
};

export function CustomV4Sidebar(props: SideProps) {
  const { children, content, width: widthProp = 300, side = 'left', hidden, slotProps } = props;
  const theme = useTheme();
  const { isCollapsable } = useSidebar();
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  const [isCollapsed, setCollapsed] = useState(useMediaQuery(theme.breakpoints.down('md')));

  const oppositeSide = useMemo(() => (side === 'left' ? 'right' : 'left'), [side]);

  const width = typeof widthProp === 'number' ? `${widthProp}px` : widthProp;

  return (
    <SidebarContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isCollapsable,
        isCollapsed,
        setCollapsed,
        isAttached: true,
      }}
    >
      {hidden ? (
        <Box flex={1} {...slotProps?.contentContainer}>
          {children}
        </Box>
      ) : (
        <>
          <Backdrop
            sx={{
              zIndex: '1001',
            }}
            open={isCollapsable && !isCollapsed && isSmallerThanMedium}
            // eslint-disable-next-line no-void
            onClick={() => void setCollapsed(true)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(slotProps?.backdrop as any)}
          />
          <Stack
            position='fixed'
            height='100%'
            width={{
              xs: '100vw',
              sm: width,
            }}
            top={0}
            overflow='auto'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(slotProps?.sidebar as any)}
            sx={{
              backgroundColor: theme.palette.background.paper,
              transition: isSmallerThanMedium ? 'left 0.2s ease-out, right 0.2s ease-out' : 'none',
              zIndex: 60,
              ...{
                [side]: {
                  xs: isCollapsed ? '-100vw' : 0,
                  // eslint-disable-next-line radix
                  md: isCollapsed ? -parseInt(`${width || 0}px`) : 0,
                },
                [`border${cap(oppositeSide)}`]: {
                  sm: `1px solid ${colors.surfaceBorder[theme.palette.mode]}`,
                },
              },
              ...slotProps?.sidebar?.sx,
            }}
          >
            {content}
          </Stack>
          <Box
            flex={1}
            {...{
              [`margin${cap(side)}`]: {
                md: isCollapsed ? '0' : width,
              },
            }}
            {...slotProps?.contentContainer}
          >
            {children}
          </Box>
        </>
      )}
    </SidebarContext.Provider>
  );
}
