import Grid from '@mui/material/Grid';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  dialogOptions: DialogProps;
  titleSX?: SxProps<Theme>;
  dataTestId?: string;
  hasBackButton?: boolean;
  onBackButtonClick?: () => void;
}

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '24px',
  },
  '& .MuiDialogContent-root': {
    padding: '0px 24px',
  },
  '& .MuiDialogActions-root': {
    padding: '24px',
  },
}));

function DialogWrapper({
  title,
  children,
  dialogOptions,
  titleSX,
  dataTestId,
  hasBackButton,
  onBackButtonClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <BootstrapDialog {...dialogOptions} data-testid={dataTestId}>
      <DialogTitle data-testid='dialog-title' sx={{ ...titleSX }}>
        {typeof title === 'string' ? (
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {hasBackButton ? (
              <Grid item>
                <IconButton
                  aria-label={t('buttonText.close')}
                  data-testid='back-icon-btn'
                  onClick={onBackButtonClick}
                  sx={{
                    position: 'relative',
                    top: 1,
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={10}>
              <Typography variant='h6'>{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label={t('buttonText.close')}
                data-testid='close-icon-btn'
                onClick={dialogOptions.onClose as unknown as () => void}
                sx={{
                  position: 'relative',
                  top: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          title
        )}
      </DialogTitle>
      {children}
    </BootstrapDialog>
  );
}

DialogWrapper.defaultProps = {
  titleSX: {},
  dataTestId: '',
  hasBackButton: false,
  onBackButtonClick: null,
};

export default DialogWrapper;
