import BusinessIcon from '@mui/icons-material/Business';
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useState, useRef, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSites } from '../../contexts/siteContext';
import { getSiteButtonText } from '../utils/siteUtils';
import SiteSelectorMenu from './SiteSelectorMenu';

interface SiteSelectorProps {
  isOrgView: boolean;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    textAlign: 'center',
  },
}));

function SiteSelector({ isOrgView }: SiteSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedSite, isSiteFiltering, multiSelectedSites, sortedSites } = useSites();
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleSiteSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonText = !selectedSite.name?.length
    ? t('global.loading')
    : getSiteButtonText(
        selectedSite.name ?? '',
        multiSelectedSites,
        sortedSites.length,
        isSiteFiltering,
        true,
        t('globalFilters.sites'),
        t('globalFilters.allSites')
      );

  return isOrgView ? (
    <CustomTooltip arrow title={t('global.organizationViewTooltipText')}>
      <span>
        <Button disabled data-testid='org-view-tooltip-btn' startIcon={<StoreMallDirectory />} endIcon={<UnfoldMore />}>
          {t('navItems.organizationView')}
        </Button>
      </span>
    </CustomTooltip>
  ) : (
    <>
      <LoadingButton
        onClick={handleSiteSelectorClick}
        aria-label={buttonText}
        data-testid='site-selector-btn'
        startIcon={selectedSite?.enterpriseUnitId !== '' ? <StoreMallDirectory /> : <BusinessIcon />}
        endIcon={<UnfoldMore />}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        disabled={isSiteFiltering}
        sx={{
          color: 'text.secondary',
          fontWeight: 'normal',
          '&:visited, &:focus, &:active, &:hover, &:focus:hover': {
            color: 'text.secondary',
            backgroundColor: 'transparent',
          },
        }}
        loading={!selectedSite.name?.length}
        loadingPosition='start'
        ref={buttonRef}
      >
        {isMobile ? null : buttonText}
      </LoadingButton>
      <SiteSelectorMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

export default SiteSelector;
