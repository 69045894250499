import { KeyboardEvent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemButton from '@mui/material/ListItemButton';
import { SiteInfo } from '@cbo/shared-library';
import { useSites } from '../../contexts/siteContext';

type AllSitesListProps = {
  searchValue: string;
  handleSiteSelection: (site: SiteInfo) => void;
};

function AllSitesList(props: AllSitesListProps) {
  const { searchValue, handleSiteSelection } = props;
  const { t } = useTranslation();
  const { selectedSite, sortedSites } = useSites();

  const filteredSites = sortedSites.filter(
    (site: SiteInfo) =>
      site.referenceId?.toLowerCase().includes(searchValue.toLowerCase()) ||
      site.name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>, site: SiteInfo) => {
    if (event.key === 'Enter') {
      handleSiteSelection(site);
    }
  };

  if (filteredSites.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '100px', alignItems: 'center' }}>
        <Typography variant='body1'>{t('multiselect.noResults')}</Typography>
      </Box>
    );
  }

  return (
    <MenuList>
      {filteredSites.map((site) => (
        <MenuItem key={site.enterpriseUnitId} onKeyDown={(e) => handleKeyDown(e, site)}>
          <ListItemButton
            selected={site.enterpriseUnitId === selectedSite?.enterpriseUnitId}
            sx={{
              padding: '12px',
              borderRadius: '8px',
            }}
            onClick={() => handleSiteSelection(site)}
          >
            <Typography variant='body1'>{site.name}</Typography>
          </ListItemButton>
        </MenuItem>
      ))}
    </MenuList>
  );
}

export default AllSitesList;
