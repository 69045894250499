import { Route, Switch, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LaborRulesMainView, { LaborRulesMainViewProps } from '../LaborRulesMainView/LaborRulesMainView';
import LaborRuleEditView, { LaborRuleEditViewProps } from '../LaborRuleEditView/LaborRuleEditView';
import routes from '../../constants/routes';
import {
  useDefaultLaborRulesConfigurationsQuery,
  useEulaAgreementQuery,
  useLaborRulesForEnterpriseQuery,
  useSitesByEnterpriseUnitGroup,
} from '../requests/queries';
import { getLocalityIdByState, organizeRuleConfigsByScope } from '../utils/utils';
import UserAgreementModal from '../UserAgreementModal/UserAgreementModal';
import { LaborRuleConfigData } from '../models/LaborRuleConfiguration';
import { useUsers } from '../../contexts/userContext';

interface LaborRuleRouteConfig {
  key: string;
  path: string;
  children: JSX.Element;
}

export const onClose = (setEulaModalOpen: Dispatch<SetStateAction<boolean>>, go: (n: number) => void) => {
  setEulaModalOpen(false);
  go(0);
};

export default function LaborRulesPage() {
  const [eulaModalOpen, setEulaModalOpen] = useState(false);
  const [hasSignedEula, setHasSignedEula] = useState(false);
  // TODO: Future enhancement work to get path ids from rules service should fix this.
  const defaultJurisdiction = 'United_States.Alabama';
  const defaultPath = 'United_States_of_America.Alabama';
  const [jurisdictionPath, setJurisdictionPath] = useState(defaultPath); // Represents string for query
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(defaultJurisdiction); // String in shared countryAndRegions list
  const [scopeOrganizedRules, setRuleConfigMap] = useState<Map<string, LaborRuleConfigData[]>>(new Map());
  const pathSegments = jurisdictionPath.split('.');
  const state = pathSegments.at(-1) || '';
  const history = useHistory();
  const { org } = useUsers();

  const bslId = org?.bslId;
  // Queries
  const { data: eulaRecord, isLoading: eulaRecordLoading, isError: eulaFetchError } = useEulaAgreementQuery(bslId);
  const { data: defaultRuleConfigs, isLoading: defaultRuleConfigsLoading } = useDefaultLaborRulesConfigurationsQuery(
    jurisdictionPath,
    hasSignedEula,
    bslId
  );

  const {
    data: orgRuleConfigs,
    refetch: refetchConfigs,
    isFetching: configsFetching,
    isLoading: orgRuleConfigsLoading,
  } = useLaborRulesForEnterpriseQuery(jurisdictionPath, hasSignedEula, bslId);

  const [configsBeingFetched, setConfigsBeingFetched] = useState(configsFetching || orgRuleConfigsLoading);

  const { data: sitesByUnitId } = useSitesByEnterpriseUnitGroup(
    [state, ...getLocalityIdByState(state)],
    hasSignedEula,
    bslId
  );
  const sites = sitesByUnitId?.flat().filter((site) => site?.id);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const laborRulesMainViewProps: LaborRulesMainViewProps = {
    setEulaModalOpen,
    selectedJurisdiction,
    setSelectedJurisdiction,
    jurisdictionPath,
    setJurisdictionPath,
    defaultRuleConfigs,
    defaultRuleConfigsLoading,
    orgRuleConfigsLoading: configsBeingFetched,
    eulaRecordLoading,
    eulaRecord,
    orgRuleConfigs,
    sites,
    refetchConfigs,
  };

  const laborRuleEditViewProps: LaborRuleEditViewProps = {
    jurisdictionPath,
    orgRuleConfigsLoading,
    sites,
    scopeOrganizedRules,
    orgRuleConfigs: orgRuleConfigs || [],
    refetchConfigs,
    allDefaultRuleConfigs: defaultRuleConfigs ?? [],
  };

  useEffect(() => {
    setConfigsBeingFetched(configsFetching || orgRuleConfigsLoading);
  }, [configsFetching, orgRuleConfigsLoading]);

  useEffect(() => {
    if (!eulaRecordLoading) {
      setHasSignedEula(!!eulaRecord?.acceptanceDate || eulaFetchError);
      setEulaModalOpen(!eulaRecord?.acceptanceDate || eulaFetchError);
    }
  }, [eulaRecord, eulaFetchError, eulaRecordLoading]);

  useEffect(() => {
    if (orgRuleConfigs && !orgRuleConfigsLoading) {
      setRuleConfigMap(organizeRuleConfigsByScope(orgRuleConfigs));
    }
  }, [orgRuleConfigs, orgRuleConfigsLoading]);

  const LABOR_RULE_FOCUS_VIEWS: LaborRuleRouteConfig[] = [
    {
      key: 'labor-rules-edit-view',
      path: routes.LABOR_RULES_EDIT,
      children: <LaborRuleEditView {...laborRuleEditViewProps} />,
    },
    {
      key: 'labor-rules-landing-view',
      path: routes.LABOR_RULES,
      children: <LaborRulesMainView {...laborRulesMainViewProps} />,
    },
  ];

  const createLaborRulesRoute = (laborRulesRoute: LaborRuleRouteConfig): JSX.Element => (
    <Route key={laborRulesRoute.key} path={laborRulesRoute.path}>
      {laborRulesRoute.children}
    </Route>
  );

  return (
    <Grid container data-testid='labor-rules-home'>
      {eulaModalOpen && (
        <UserAgreementModal eulaRecord={eulaRecord} onClose={() => onClose(setEulaModalOpen, history.go)} />
      )}
      <Grid item sm={12} px={isMobile ? 2 : 4} justifyContent='flex-end' width='100%'>
        <Switch>{LABOR_RULE_FOCUS_VIEWS.map((route) => createLaborRulesRoute(route))}</Switch>
      </Grid>
    </Grid>
  );
}
