import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme, SxProps, Theme } from '@mui/material/styles';

export interface BreadcrumbsProps {
  breadcrumbLabels: string[];
  // eslint-disable-next-line react/require-default-props
  styleOverrides?: SxProps<Theme> | undefined;
}

export interface BreadcrumbLinkProps {
  linkLabel: string;
  linkLocation: string;
  lastLink: boolean;
}

function BreadcrumbLink(props: BreadcrumbLinkProps) {
  const theme = useTheme();
  const { linkLabel, linkLocation, lastLink } = props;

  return lastLink ? (
    <Typography component='a' aria-current='page' color='text.primary' variant='overline' data-testid={`${linkLabel}`}>
      {linkLabel.toUpperCase()}
    </Typography>
  ) : (
    <Typography
      component={RouterLink}
      to={linkLocation}
      variant='overline'
      sx={{
        textDecoration: 'none',
        color: theme.palette.primary.main,
      }}
      data-testid={`${linkLabel}`}
    >
      {linkLabel.toUpperCase()}
    </Typography>
  );
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { breadcrumbLabels, styleOverrides = {} } = props;
  const lastElementIndex = breadcrumbLabels.length - 1;
  return (
    <MuiBreadcrumbs
      aria-label='breadcrumb'
      separator={<Typography variant='overline'>/</Typography>}
      sx={{
        pb: 2,
        pl: { xs: 3, xl: 4 },
        ...styleOverrides,
      }}
    >
      {breadcrumbLabels.map((label, index, labelsArr) => (
        <BreadcrumbLink
          key={`${label}${index.toString()}`}
          linkLabel={label}
          linkLocation={'.'.repeat(labelsArr.length - index)}
          lastLink={index === lastElementIndex}
        />
      ))}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
