import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Ty from '@mui/material/Typography';
import { GlAccount } from '../requests/requests';
import SalesUtilities from '../utilities';

export type GeneralLedgerAccountsDialogContentProps = {
  glAccounts: GlAccount[];
  endMessage?: string;
};

function GeneralLedgerAccountsDialogContent(props: GeneralLedgerAccountsDialogContentProps) {
  const { glAccounts, endMessage } = props;
  const { t } = useTranslation();
  const renderSubAccounts = () => {
    if (glAccounts.length === 1) {
      return glAccounts[0].subAccounts && glAccounts[0].subAccounts.length > 0;
    }
    if (glAccounts.length > 1) {
      return true;
    }
    return false;
  };

  // Method to generate all nested GL accounts text (More than 1 level of nesting)
  const generateNestedGlAccountsText = (account: GlAccount, curLevel: number): React.ReactNode => {
    const paddingLeftAmount = 16 * curLevel;
    const translationKey = SalesUtilities.checkIfSubAccountsExist([account])
      ? 'admin.glAccountConfiguration.generalLedgerAccounts.glAccountTitle'
      : 'admin.glAccountConfiguration.generalLedgerAccounts.glAccountTitleNoChildren';
    return (
      <React.Fragment key={`account-${account.accountId}`}>
        <Ty
          sx={{
            paddingLeft: `${paddingLeftAmount}px`,
            marginTop: '16px',
            fontWeight: SalesUtilities.checkIfSubAccountsExist([account]) ? '500' : '400',
          }}
          data-testid='dialog-content-account-title'
        >
          {t(translationKey, {
            glAccountNumber: account.accountNumber,
            glAccountName: account.accountName,
          })}
        </Ty>
        {SalesUtilities.checkIfSubAccountsExist([account]) &&
          account.subAccounts.map((childGlAccount) => generateNestedGlAccountsText(childGlAccount, curLevel + 1))}
      </React.Fragment>
    );
  };

  if (renderSubAccounts()) {
    return (
      <>
        <Box
          sx={{
            flexWrap: 'wrap',
            maxHeight: '300px',
            maxWidth: 'sm',
            minWidth: 'sm',
            overflow: 'auto',
            marginBottom: endMessage ? '16px' : undefined,
          }}
          data-testid='gl-accounts-dialog-content'
        >
          {glAccounts.map((account) => generateNestedGlAccountsText(account, 0))}
        </Box>
        {!!endMessage && endMessage.length > 0 && <Ty component='span'>{endMessage}</Ty>}
      </>
    );
  }
  return null;
}

GeneralLedgerAccountsDialogContent.defaultProps = {
  endMessage: undefined,
};

export default GeneralLedgerAccountsDialogContent;
