import { UseQueryResult, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useState } from 'react';
import useLaborRequests from '.';
import staleTimeSettings from '../../config/reactQueryConfig';
import { PaginationResponse } from '../../models/Paginated';
import SalesUtilities from '../../sales/utilities';
import {
  EmployeeBreakReportCommonRequestSchema,
  GenericPayrollExportGridDataRequest,
  GenericPayrollExportGridDataResponse,
  IntervalSalesAndLaborReportCommonRequestSchema,
  JobcodeHistoryGridRequest,
  JobcodeHistoryGridResponse,
  OvertimeThresholdCommonRequest,
  OvertimeThresholdGridDataResponse,
  OvertimeThresholdKpiCardsResponse,
  EmployeeSalesPerformanceCommonRequest,
  EmployeeSalesPerformanceKpiCardsResponse,
  EmployeeSalesPerformanceGridDataResponse,
  ApproachingACAHourlyThresholdGridDataRequest,
  ApproachingACAHourlyThresholdGridDataResponse,
  ApproachingACAThresholdAverageHoursWorkedGridResponse,
  EmployeePayrollDto,
  Job,
  LaborGroup,
  JobProfile,
  EmployeeDetailsInfo,
  Employee,
  GetEmployeesRequestSchema,
  GetPaginatedJobsRequest,
  GetJobProfilesRequestSchema,
  SelfJobProfile,
} from '../types';
import useLaborQueryKeys from './queryKeys';
import { BaseReportDataRequest } from '../../sales/requests/requests';
import { useSites } from '../../contexts/siteContext';

export const useGetEmployeeSalesPerformanceReportGridData = () => {
  const { getEmployeeSalesPerformanceReportGridData } = useLaborRequests();
  const [request, setEmployeeSalesPerformanceReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeSalesPerformanceCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeSalesPerformanceReport.list(request, 'grid-data'),
    () => getEmployeeSalesPerformanceReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    employeeSalesPerformanceReportGridDataResponse: data as EmployeeSalesPerformanceGridDataResponse | undefined,
    setEmployeeSalesPerformanceReportGridDataRequest,
    isEmployeeSalesPerformanceReportGridDataLoading: isLoading,
  };
};

export const useGetEmployeeSalesPerformanceReportKpiCardsData = () => {
  const { getEmployeeSalesPerformanceReportKpiCardsData } = useLaborRequests();
  const [request, setEmployeeSalesPerformanceReportKpiCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeSalesPerformanceCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeSalesPerformanceReport.list(request, 'kpi-cards'),
    () => getEmployeeSalesPerformanceReportKpiCardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    employeeSalesPerformanceReportKpiCardsDataResponse: data as EmployeeSalesPerformanceKpiCardsResponse | undefined,
    setEmployeeSalesPerformanceReportKpiCardsDataRequest,
    isEmployeeSalesPerformanceReportKpiCardsDataLoading: isLoading,
  };
};

export const useGetOvertimeThresholdReportGridData = () => {
  const { getOvertimeThresholdReportGridData } = useLaborRequests();
  const [request, setOvertimeThresholdReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as OvertimeThresholdCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().overtimeThresholdReport.list(request, 'grid-data'),
    () => getOvertimeThresholdReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    overtimeThresholdReportGridDataResponse: data as OvertimeThresholdGridDataResponse | undefined,
    setOvertimeThresholdReportGridDataRequest,
    isOvertimeThresholdReportGridDataLoading: isLoading,
  };
};

export const useGetOvertimeThresholdReportKpiCardsData = () => {
  const { getOvertimeThresholdReportKpiCardsData } = useLaborRequests();
  const [request, setOvertimeThresholdReportKpiCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as OvertimeThresholdCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().overtimeThresholdReport.list(request, 'kpi-cards'),
    () => getOvertimeThresholdReportKpiCardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    overtimeThresholdReportKpiCardsDataResponse: data as OvertimeThresholdKpiCardsResponse | undefined,
    setOvertimeThresholdReportKpiCardsDataRequest,
    isOvertimeThresholdReportKpiCardsDataLoading: isLoading,
  };
};

export const useEmployeeSelfQuery = (isAuthenticated: boolean): UseQueryResult<Employee> => {
  const { getSelfEmployee } = useLaborRequests();
  return useQuery(useLaborQueryKeys().employee.listByIdSelf(), () => getSelfEmployee(), {
    staleTime: staleTimeSettings.NONE,
    useErrorBoundary: false,
    enabled: isAuthenticated,
  });
};

export const useEmployeeDetailsSelfQuery = (isAuthenticated: boolean): UseQueryResult<EmployeeDetailsInfo> => {
  const { getSelfEmployeeDetailsInfo } = useLaborRequests();

  return useQuery(useLaborQueryKeys().employeeDetails.self(), () => getSelfEmployeeDetailsInfo(), {
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
    enabled: isAuthenticated,
  });
};

export const useJobProfileSelfQuery = (isAuthenticated: boolean): UseQueryResult<SelfJobProfile[]> => {
  const { getSelfJobProfile } = useLaborRequests();

  return useQuery(useLaborQueryKeys().jobProfile.self(), () => getSelfJobProfile(), {
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
    enabled: isAuthenticated,
  });
};

export const useJobcodeHistoryQuery = (
  request: JobcodeHistoryGridRequest
): UseQueryResult<JobcodeHistoryGridResponse> => {
  const { getJobcodeHistoryGridData } = useLaborRequests();

  return useQuery(useLaborQueryKeys().jobcodeHistory.list(request), () => getJobcodeHistoryGridData(request), {
    staleTime: staleTimeSettings.NONE,
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

// TODO - Rename once all pages are updated to use new API's
export const useLaborGroupsQuery = (): UseQueryResult<PaginationResponse<LaborGroup>> => {
  const { getLaborGroups } = useLaborRequests();

  return useQuery(useLaborQueryKeys().laborGroup.all, () => getLaborGroups());
};

// TODO : Rename once all pages are updated to use new API's
export const useLaborGroupByIdQuery = (laborGroupId: string): UseQueryResult<LaborGroup> => {
  const { getLaborGroupByIdApi } = useLaborRequests();
  return useQuery(useLaborQueryKeys().laborGroup.listById(laborGroupId), () => getLaborGroupByIdApi(laborGroupId), {
    enabled: laborGroupId.length > 0,
    staleTime: staleTimeSettings.LOW,
    useErrorBoundary: true,
  });
};

export const useGetEmployeeTipReportGridDataQuery = () => {
  const { getEmployeeTipReportGridData } = useLaborRequests();
  const [request, setGetEmployeeTipReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeBreakReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeTipReport.page('grid-data', request),
    () => getEmployeeTipReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: true,
      enabled: isEnabled,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeeTipReportGridData: data,
    setGetEmployeeTipReportGridDataRequest,
    isGetEmployeeTipReportGridDataLoading: isLoading,
  };
};

export const useGetEmployeeTipReportKpiCardsAndChartsDataQuery = () => {
  const { getEmployeeTipReportKpiCardsData } = useLaborRequests();
  const [request, setGetEmployeeTipReportKpiCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeBreakReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeTipReport.page('kpi-cards', request),
    () => getEmployeeTipReportKpiCardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeeTipReportKpiCardsData: data,
    setGetEmployeeTipReportKpiCardsDataRequest,
    isGetEmployeeTipReportKpiCardsDataLoading: isLoading,
  };
};

export const useGetEmployeeBreakReportGridDataQuery = () => {
  const { getEmployeeBreakReportGridData } = useLaborRequests();
  const [request, setGetEmployeeBreakReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeBreakReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeBreakReport.list('grid-data', request),
    () => getEmployeeBreakReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeeBreakReportGridData: data,
    setGetEmployeeBreakReportGridDataRequest,
    isGetEmployeeBreakReportGridDataLoading: isLoading,
  };
};

export const useGetEmployeeBreakReportKpiCardsDataQuery = () => {
  const { getEmployeeBreakReportKpiCardsData } = useLaborRequests();
  const [request, setGetEmployeeBreakReportKpiCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeeBreakReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeeBreakReport.list('kpi-cards', request),
    () => getEmployeeBreakReportKpiCardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeeBreakReportKpiCardsData: data,
    setGetEmployeeBreakReportKpiCardsDataRequest,
    isGetEmployeeBreakReportKpiCardsDataLoading: isLoading,
  };
};

export const useGetIntervalSalesAndLaborReportGridDataQuery = () => {
  const { getIntervalSalesAndLaborGridData } = useLaborRequests();
  const [request, setGetIntervalSalesAndLaborReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as IntervalSalesAndLaborReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().intervalSalesAndLaborReport.list('grid-data', request),
    () => getIntervalSalesAndLaborGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    intervalSalesAndLaborReportGridData: data,
    setGetIntervalSalesAndLaborReportGridDataRequest,
    isGetIntervalSalesAndLaborReportGridDataLoading: isLoading,
  };
};

export const useGetIntervalSalesAndLaborReportKpiCardsDataQuery = () => {
  const { getIntervalSalesAndLaborKPICards } = useLaborRequests();
  const [request, setGetIntervalSalesAndLaborReportKpiCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as IntervalSalesAndLaborReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().intervalSalesAndLaborReport.list('kpi-cards', request),
    () => getIntervalSalesAndLaborKPICards(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    intervalSalesAndLaborReportKpiCardsData: data,
    setGetIntervalSalesAndLaborReportKpiCardsDataRequest,
    isGetIntervalSalesAndLaborReportKpiCardsDataLoading: isLoading,
  };
};

export const useGetIntervalSalesAndLaborReportAverageSalesDataQuery = () => {
  const { getIntervalSalesAndLaborAverageSales } = useLaborRequests();
  const [request, setGetIntervalSalesAndLaborReportAverageSalesDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as IntervalSalesAndLaborReportCommonRequestSchema);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().intervalSalesAndLaborReport.list('average-sales', request),
    () => getIntervalSalesAndLaborAverageSales(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    intervalSalesAndLaborReportAverageSalesData: data,
    setGetIntervalSalesAndLaborReportAverageSalesDataRequest,
    isIntervalSalesAndLaborReportAverageSalesDataLoading: isLoading,
  };
};

export const useGetGenericPayrollExportGridData = () => {
  const { getGenericPayrollExportGridData } = useLaborRequests();
  const [request, setGenericPayrollGridData] = useState({
    startDate: '',
    endDate: '',
  } as GenericPayrollExportGridDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().genericPayrollExport.grid(request),
    () => getGenericPayrollExportGridData(request),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    genericPayrollData: data as GenericPayrollExportGridDataResponse[] | undefined,
    setGenericPayrollGridData,
    isLoading,
  };
};

export const useGetApproachingACAHourlyThresholdGridData = () => {
  const { getApproachingACAHourlyThresholdGridData } = useLaborRequests();
  const [request, setGetApproachingACAHourlyThresholdGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as ApproachingACAHourlyThresholdGridDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().acaThresholdReport.hourlyGrid(request),
    () => getApproachingACAHourlyThresholdGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    getApproachingACAHourlyThresholdGridData: data as ApproachingACAHourlyThresholdGridDataResponse | undefined,
    setGetApproachingACAHourlyThresholdGridDataRequest,
    isGetApproachingACAHourlyThresholdGridDataLoading: isLoading,
  };
};

export const useGetAcaAverageHoursGridData = () => {
  const { getAcaAverageHoursGridData } = useLaborRequests();
  const [request, setACAAverageHoursRequest] = useState({
    startDate: '',
    endDate: '',
  } as BaseReportDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().acaThresholdReport.averageHoursGrid(request),
    () => getAcaAverageHoursGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: true,
    }
  );

  return {
    gridData: data as ApproachingACAThresholdAverageHoursWorkedGridResponse | undefined,
    setACAAverageHoursRequest,
    isLoading,
  };
};

export const useEmployeePayrollGridQuery = () => {
  const { getEmployeePayrollGridData } = useLaborRequests();
  const [payrollGridRequest, setPayrollGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeePayrollDto);
  const isEnabled =
    payrollGridRequest.startDate.length > 0 &&
    payrollGridRequest.endDate.length > 0 &&
    payrollGridRequest.startDate !== 'Invalid Date' &&
    payrollGridRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeePayrollSummary.grid(payrollGridRequest, 'summary'),
    () => getEmployeePayrollGridData(payrollGridRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setPayrollGridRequest,
    loading: isLoading,
  };
};

export const useEmployeePayrollKPICardsQuery = () => {
  const { getEmployeePayrollKPICardsData } = useLaborRequests();
  const [payrollKpiRequest, setKPICardsRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeePayrollDto);
  const isEnabled =
    payrollKpiRequest.startDate.length > 0 &&
    payrollKpiRequest.endDate.length > 0 &&
    payrollKpiRequest.startDate !== 'Invalid Date' &&
    payrollKpiRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeePayrollSummary.list(payrollKpiRequest, 'kpi-cards'),
    () => getEmployeePayrollKPICardsData(payrollKpiRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setKPICardsRequest,
    loading: isLoading,
  };
};

export const useGetEmployeePayrollDetailGridData = () => {
  const { getEmployeePayrollDetailGridData } = useLaborRequests();

  const [payrollDetailGridRequest, setEmployeePayrollDetailGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as EmployeePayrollDto);

  const isEnabled =
    payrollDetailGridRequest.startDate.length > 0 &&
    payrollDetailGridRequest.endDate.length > 0 &&
    payrollDetailGridRequest.startDate !== 'Invalid Date' &&
    payrollDetailGridRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    useLaborQueryKeys().employeePayrollSummary.grid(payrollDetailGridRequest, 'detail'),
    () => getEmployeePayrollDetailGridData(payrollDetailGridRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeePayrollDetailGridDataResponse: data,
    setEmployeePayrollDetailGridDataRequest,
    isEmployeePayrollDetailDataGridLoading: isLoading,
  };
};

export const useJobsQuery = (
  isAuthenticated: boolean,
  query?: GetPaginatedJobsRequest
): UseQueryResult<PaginationResponse<Job>> => {
  const { getJobs } = useLaborRequests();
  return useQuery(useLaborQueryKeys().jobcode.list(), () => getJobs(query), {
    staleTime: staleTimeSettings.NONE,
    useErrorBoundary: true,
    keepPreviousData: true,
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};

export const useJobsByListIdsQuery = (
  jobIds: string[],
  isAuthenticated: boolean
): UseQueryResult<PaginationResponse<Job>> => {
  const { getJobs } = useLaborRequests();

  return useQuery(useLaborQueryKeys().jobcode.jobListByIds(jobIds), () => getJobs({ ids: jobIds }), {
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: true,
    keepPreviousData: true,
    enabled: isAuthenticated,
  });
};

export const useJobByIdQuery = (jobId: string, isAuthenticated: boolean): UseQueryResult<Job> => {
  const { getJobById } = useLaborRequests();

  return useQuery(useLaborQueryKeys().jobcode.byId(jobId), () => getJobById(jobId), {
    staleTime: staleTimeSettings.LOW,
    useErrorBoundary: true,
    enabled: isAuthenticated && jobId.length > 0,
    refetchOnWindowFocus: false,
  });
};

export const useGetEmployeesQuery = (
  authenticated: boolean,
  params: GetEmployeesRequestSchema,
  enabled: boolean,
  gettingByPosEmployeeId?: boolean,
  excludeEUID?: boolean
) => {
  const { getEmployees } = useLaborRequests();
  const { selectedSite } = useSites();
  const [requestParameters, setRequestParameters] = useState<GetEmployeesRequestSchema>(params);

  const enabledForPos = requestParameters.posEmployeeId?.length ? requestParameters.posEmployeeId?.length > 0 : false;

  const { data, refetch, isLoading } = useQuery(
    useLaborQueryKeys().employee.listByFilters({ ...params, enterpriseUnit: selectedSite.enterpriseUnitId }),
    () => getEmployees(requestParameters, excludeEUID),
    {
      staleTime: gettingByPosEmployeeId ? staleTimeSettings.NONE : staleTimeSettings.MEDIUM,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled: authenticated && (gettingByPosEmployeeId ? enabledForPos : enabled),
    }
  );
  return { requestParameters, setRequestParameters, data, isLoading, refetch };
};

export const useGetJobProfilesByJobIdQuery = (jobId: string): UseQueryResult<PaginationResponse<JobProfile>> => {
  const { getJobProfilesByJobId } = useLaborRequests();

  return useQuery(useLaborQueryKeys().jobProfile.listById(jobId), () => getJobProfilesByJobId(jobId), {
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: true,
    enabled: jobId.length > 0,
  });
};

export const useGetEmployeeByEmployeeIdQuery = (
  employeeId: string,
  isAuthenticated: boolean
): UseQueryResult<Employee> => {
  const { getEmployeeByEmployeeId } = useLaborRequests();

  return useQuery(useLaborQueryKeys().employee.byEmpId(employeeId), () => getEmployeeByEmployeeId(employeeId), {
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
    enabled: isAuthenticated && employeeId.length > 0,
  });
};

export const useGetJobProfilesByEmployeeIdQuery = (
  empId: string,
  isAuthenticated: boolean,
  includeHistory = false
): UseQueryResult<PaginationResponse<JobProfile>> => {
  const { getJobProfilesByEmployeeId } = useLaborRequests();

  return useQuery(
    useLaborQueryKeys().jobProfile.jobProfilesByEmployeeId(empId),
    () => getJobProfilesByEmployeeId(empId, includeHistory),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
      enabled: isAuthenticated && empId.length > 0,
    }
  );
};

export const useGetUrlWithEmployeeIdQuery = (): {
  setPosEmployeeId: Dispatch<SetStateAction<string>>;
  url: string | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
} => {
  const { getUrlWithEmployeeId } = useLaborRequests();
  const [posEmployeeId, setPosEmployeeId] = useState('');
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data: url } = useQuery(
    ['employees', posEmployeeId],
    (): Promise<string | undefined> => getUrlWithEmployeeId(posEmployeeId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: posEmployeeId.length > 0 && enabled,
      useErrorBoundary: false,
    }
  );

  return { url, setPosEmployeeId, setEnabled };
};

export const useGetAllJobProfilesQuery = (isAuthenticated: boolean, params?: GetJobProfilesRequestSchema) => {
  const { getAllJobProfiles } = useLaborRequests();

  const { data, refetch, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    useLaborQueryKeys().jobProfile.all,
    ({ pageParam }) => getAllJobProfiles(pageParam ?? params),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: true,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lastPage?.lastPage ? undefined : { pagenum: lastPage!.pageNumber + 1, ...params },
    }
  );

  return {
    data,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
  };
};

export const useGetAllJobsQuery = (isAuthenticated: boolean, query?: GetPaginatedJobsRequest) => {
  const { getJobs } = useLaborRequests();
  const { data, refetch, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    useLaborQueryKeys().jobcode.allJobs(),
    ({ pageParam }) => getJobs(query ?? pageParam),
    {
      staleTime: staleTimeSettings.NONE,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lastPage?.lastPage ? undefined : { pagenum: lastPage!.pageNumber + 1 },
    }
  );

  return {
    data,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
  };
};
