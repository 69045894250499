/* eslint-disable no-nested-ternary */
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import countryAndRegionData from '../../../countryAndRegionData';
import Header from '../../components/Header/Header';
import LaborRuleCard from '../LaborRuleCard/LaborRuleCard';
import RuleGuidanceDrawer from '../RuleGuidanceDrawer/RuleGuidanceDrawer';
import {
  LaborRuleConfigData,
  LaborRuleGroup,
  MinorRuleTypes,
  RuleTypes,
  SchedulingRuleTypes,
  WagesRuleTypes,
  ruleTypesByGroup,
} from '../models/LaborRuleConfiguration';
import { EulaRecordData } from '../models/EulaRecord';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import { filterRuleConfigsForType, buildConfigsForTippedRules, mutateConfigsForRuleForm } from '../utils/utils';

const SUPPORTED_COUNTRIES = ['United States'];
const UNSUPPORTED_JURISDICTIONS = [
  'American Samoa',
  'Armed Forces Europe, Canada, Africa and Middle East',
  'Armed Forces Pacific',
  'Armed Forces Americas',
  'District of Columbia',
  'Marshall Islands',
  'Micronesia',
  'Northern Mariana Islands',
  'Palau',
  'Virgin Islands',
];
const US_TERRITORIES = ['Puerto Rico', 'Guam'];

const RULE_GROUPS = [
  LaborRuleGroup.OVERTIME,
  LaborRuleGroup.WAGES_AND_TIPS,
  LaborRuleGroup.BREAKS,
  LaborRuleGroup.SCHEDULING,
  LaborRuleGroup.MINORS,
];

const jurisdictionGroupings = countryAndRegionData
  .filter((data) => SUPPORTED_COUNTRIES.includes(data.countryName))
  .map((countryData) => ({
    countryName: countryData.countryName,
    regions: countryData.regions.filter((regionData) => !UNSUPPORTED_JURISDICTIONS.includes(regionData.name)),
  }));

const buildJurisdictionOptionValue = (countryName: string, regionName: string) =>
  `${countryName.split(' ').join('_')}${US_TERRITORIES.includes(regionName) ? '.US_Territory' : ''}.${regionName
    .split(' ')
    .join('_')}`;

export const renderTabs = (tabNames: LaborRuleGroup[] | string[], t: TFunction<'translation', undefined>) =>
  tabNames.map((tabName) => (
    <Tab key={tabName} label={tabName && t(`laborRules.tabs.${tabName}`)} data-testid={`${tabName}-tab`} />
  ));

const parseJurisdiction = (jurisdiction: string) => jurisdiction.split('.')[1].replaceAll('_', ' ');

const getRuleConfigsByGroup = (
  ruleConfigs: LaborRuleConfigData[] | undefined,
  currentTabName: LaborRuleGroup,
  currentJurisdictionPath: string
) =>
  ruleConfigs
    ? (mutateConfigsForRuleForm(
        ruleConfigs.filter((ruleConfig) => ruleConfig.rule.group === currentTabName)
      ) as LaborRuleConfigData[])
    : [];

const getDefaultRuleConfigsByGroup = (
  defaultRuleConfigs: LaborRuleConfigData[] | undefined,
  currentTabName: LaborRuleGroup,
  currentJurisdictionPath: string
) =>
  defaultRuleConfigs
    ? mutateConfigsForRuleForm(
        defaultRuleConfigs.filter(
          (defaultRuleConfig) =>
            defaultRuleConfig.rule.group === currentTabName && currentJurisdictionPath.includes(defaultRuleConfig.path)
        )
      )
    : [];

const getRulesForLaborRuleCard = (filteredConfigs: LaborRuleConfigData[], type: string) => {
  let final = filteredConfigs;

  if (type === WagesRuleTypes.TippedMinimumWageRule) {
    final = buildConfigsForTippedRules(filteredConfigs);
  }
  return final;
};

export interface LaborRulesMainViewProps {
  setEulaModalOpen: (value: boolean) => void;
  selectedJurisdiction: string;
  setSelectedJurisdiction: (value: string) => void;
  jurisdictionPath: string;
  setJurisdictionPath: (value: string) => void;
  defaultRuleConfigs: LaborRuleConfigData[] | undefined;
  defaultRuleConfigsLoading: boolean;
  orgRuleConfigsLoading: boolean;
  eulaRecordLoading: boolean;
  eulaRecord: EulaRecordData | undefined | void;
  orgRuleConfigs: LaborRuleConfigData[] | undefined;
  sites: JurisdictionSitesRow[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchConfigs: any;
}

export default function LaborRulesMainView(props: LaborRulesMainViewProps) {
  const {
    setEulaModalOpen,
    jurisdictionPath,
    setJurisdictionPath,
    selectedJurisdiction,
    setSelectedJurisdiction,
    defaultRuleConfigs,
    defaultRuleConfigsLoading,
    orgRuleConfigs,
    orgRuleConfigsLoading,
    eulaRecord,
    eulaRecordLoading,
    sites,
    refetchConfigs,
  } = props;

  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const handleTabChange = (event: SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };
  const currentTabName = RULE_GROUPS[tabIndex];
  const ruleConfigsByGroup = getRuleConfigsByGroup(orgRuleConfigs, currentTabName, jurisdictionPath);
  const defaultRuleConfigsByGroup = getDefaultRuleConfigsByGroup(defaultRuleConfigs, currentTabName, jurisdictionPath);

  const onViewUserAgreement = () => {
    setEulaModalOpen(true);
  };

  const menuWidth = '17rem';

  return (
    <Box data-testid='labor-rules-main-view'>
      <Grid container alignItems='start' py={2}>
        <Grid
          item
          xs={12}
          sx={{ position: 'sticky', top: '4.5rem', zIndex: 100, backgroundColor: theme.palette.background.default }}
        >
          <Grid container sx={{ marginBottom: 2 }}>
            {eulaRecordLoading ? (
              <Grid item xs={12}>
                <Skeleton variant='rounded' height={32} width={400} />
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Header title={t(`laborRules.title`)} dataTestId='labor-rules-header' />
                {!!eulaRecord && (
                  <Button
                    data-testid='view-user-agreement-button'
                    onClick={onViewUserAgreement}
                    color='secondary'
                    endIcon={<InsertDriveFileIcon />}
                  >
                    {t('laborRules.viewUserAgreement')}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={2} sx={{ marginBottom: 2 }}>
            {eulaRecordLoading ? (
              <Skeleton variant='rounded' height={48} width={250} />
            ) : (
              <FormControl variant='standard' data-testid='jurisdiction-selector' fullWidth>
                <InputLabel id='jurisdiction-input-label'>{t('laborRules.jurisdictionSelectLabel')}</InputLabel>
                <Select
                  id='jurisdiction'
                  data-testid='jurisdiction-select'
                  sx={{ width: menuWidth }}
                  inputProps={{ 'data-testid': 'jurisdiction-select-input' }}
                  value={selectedJurisdiction}
                  MenuProps={{
                    sx: {
                      '& .MuiMenu-paper': {
                        maxHeight: '23.25rem',
                        width: menuWidth,
                      },
                    },
                  }}
                  onChange={(e) => {
                    // TODO: Future enhancement work to get path ids from rules service should fix this
                    if (e.target.value.includes('United_States')) {
                      setJurisdictionPath(e.target.value.replace('United_States', 'United_States_of_America'));
                      setSelectedJurisdiction(e.target.value);
                    } else {
                      setJurisdictionPath(e.target.value);
                      setSelectedJurisdiction(e.target.value);
                    }
                  }}
                >
                  {Object.values(jurisdictionGroupings).map((group) => [
                    <ListSubheader> {group.countryName} </ListSubheader>,
                    ...group.regions.map((region) => (
                      <MenuItem
                        key={`region-${region.name}`}
                        value={buildJurisdictionOptionValue(group.countryName, region.name)}
                      >
                        {region.name}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item>
            {eulaRecordLoading ? (
              <Stack direction='row' sx={{ marginBottom: 2 }}>
                {RULE_GROUPS.map((name) => (
                  <Skeleton key={`${name}-tab-skeleton`} width={110} height={35} sx={{ marginRight: 1 }} />
                ))}
              </Stack>
            ) : (
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{ marginBottom: 2 }}
                data-testid='labor-rule-group-tabs'
              >
                {renderTabs(RULE_GROUPS, t)}
              </Tabs>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sx={{ marginBottom: 2 }}>
            {eulaRecordLoading || defaultRuleConfigsLoading ? (
              <Skeleton variant='rounded' height={90} />
            ) : (
              <RuleGuidanceDrawer
                activeTab={currentTabName}
                jurisdiction={parseJurisdiction(selectedJurisdiction)}
                defaultRuleConfigs={defaultRuleConfigsByGroup}
              />
            )}
          </Grid>
          <Grid item sx={{ marginBottom: 2 }}>
            {(ruleTypesByGroup[currentTabName] as RuleTypes[])
              .filter(
                (type: RuleTypes) =>
                  ![MinorRuleTypes.SummerVacationRule, MinorRuleTypes.MinorSchoolDaysRule].includes(
                    type as MinorRuleTypes
                  )
              )
              .filter(
                (type: RuleTypes) =>
                  ![
                    SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
                    SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
                    SchedulingRuleTypes.FixedFeePredictabilityPayRule,
                  ].includes(type as SchedulingRuleTypes)
              )
              .map((type: RuleTypes) =>
                eulaRecordLoading || orgRuleConfigsLoading ? (
                  <Skeleton key={`${type}-rule-card-skeleton`} variant='rounded' height={300} sx={{ marginTop: 2 }} />
                ) : (
                  <LaborRuleCard
                    key={`${type}-rule-card`}
                    ruleType={type}
                    ruleGroup={currentTabName}
                    title={type && t(`laborRules.rules.${type}.name`)}
                    data-testid={`${type}-rule-card`}
                    ruleConfigs={getRulesForLaborRuleCard(
                      ruleConfigsByGroup.filter((conf) => filterRuleConfigsForType(conf, type)),
                      type
                    )}
                    sites={sites}
                    jurisdictionPath={jurisdictionPath}
                    refetchConfigs={refetchConfigs}
                    orgRuleConfigsLoading={orgRuleConfigsLoading}
                    defaultRuleConfigs={defaultRuleConfigsByGroup}
                  />
                )
              )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
