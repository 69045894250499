import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Event from '@mui/icons-material/Event';
import { DateRange, DateRangePicker as MUIDateRangePicker } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

interface Props {
  value: DateRange<Dayjs>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onChange: (date: DateRange<any>) => void;
  hideIcon?: boolean;
  maxDate?: Dayjs;
  inputFormat?: string;
}

function DateRangePicker({ value, onChange, hideIcon, inputFormat, maxDate }: Props) {
  const { t } = useTranslation();
  return (
    <MUIDateRangePicker
      maxDate={maxDate}
      value={value}
      format={inputFormat}
      slotProps={{
        textField: {
          variant: 'standard',
          sx: { maxWidth: '272px' },
          InputProps: {
            endAdornment: hideIcon ? undefined : (
              <InputAdornment position='end'>
                <IconButton aria-label={t('ariaLabel.datePicker')}>
                  <Event />
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      }}
      onChange={onChange}
      data-testid='date-range-picker'
    />
  );
}

DateRangePicker.defaultProps = {
  hideIcon: false,
  maxDate: undefined,
  inputFormat: 'MM/DD/YYYY',
};

export default DateRangePicker;
