import { SxProps, Theme } from '@mui/material/styles';

/** COLORS AND STYLES */

export const outlinedButtonColor = '#134085';
export const darkBackgroundColor = '#053570';
export const chipHoverBackgroundColor = '#B1D7F6';
export const chipBackgroundColor = '#C9E3F9';
export const commonTypographyStyle: SxProps = {
  fontWeight: 500,
  lineHeight: '22px',
  marginRight: '8px',
  marginLeft: '12px',
};

/** FUNCTIONS */
export const defaultOutlineButtonStyling = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  '& .MuiButton-startIcon': { marginRight: '0px' },
});

export const defaultButtonStyling = (anchorEl: HTMLButtonElement | null, theme: Theme) => {
  if (theme.palette.mode === 'dark') {
    return {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.text.primary,
    };
  }
  if (anchorEl) {
    return {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.primary,
      '& .MuiButton-startIcon': { marginRight: '0px' },
    };
  }
  return {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&:focus-visible': {
      boxShadow: 'none',
      boxSizing: 'border-box',
      outline: `2px solid ${outlinedButtonColor}`,
    },
    '&:hover, &:focus:hover': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.primary,
    },
    '&:focus': {
      color: theme.palette.text.primary,
    },
    '& .MuiButton-startIcon': { marginRight: '0px' },
  };
};

export const selectedButtonStyling = (anchorEl: HTMLButtonElement | null, theme: Theme) => {
  if (theme.palette.mode === 'dark') {
    return {
      backgroundColor: darkBackgroundColor,
      color: theme.palette.text.primary,
    };
  }
  if (anchorEl) {
    return {
      backgroundColor: chipHoverBackgroundColor,
      color: darkBackgroundColor,
      '& .MuiButton-startIcon': { marginRight: '0px' },
    };
  }
  return {
    backgroundColor: chipBackgroundColor,
    color: darkBackgroundColor,
    '&:focus': {
      color: darkBackgroundColor,
    },
    '&:hover, &:focus:hover': {
      backgroundColor: chipHoverBackgroundColor,
      color: darkBackgroundColor,
    },
    '&:focus-visible': {
      boxShadow: 'none',
      boxSizing: 'border-box',
      outline: `2px solid ${outlinedButtonColor}`,
    },
    '& .MuiButton-startIcon': { marginRight: '0px' },
  };
};

export const definedFilterStyling = (theme: Theme) => ({
  '&:focus-visible': {
    boxShadow: 'none',
    boxSizing: 'border-box',
    outline: `2px solid ${theme.palette.primary.dark}`,
    outlineOffset: '-2px',
    // This converts the hoverOpacity (default 0.04) to hexadecimal then grabs the first two values past the decimal to append
    // to the main theme hex. These two values represent opacity when they're appended to a 6 digit hex color value
    backgroundColor: `${theme.palette.primary.main}${theme.palette.action.hoverOpacity.toString(16).substring(2, 4)}`,
    '& .MuiButton-startIcon': { marginRight: '0px' },
  },
});

export const searchBarBoxStyling = (theme: Theme, isDarkMode: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: isDarkMode ? theme.palette.grey[800] : '#F5F5F5',
  height: '48px',
  borderRadius: 1,
  paddingLeft: '12px',
  paddingRight: '8px',
  marginLeft: '16px',
  marginRight: '16px',
});

export const filterContainerStyling: SxProps = { gap: '8px', alignContent: 'center', alignItems: 'center' };

export const arrowStyling = (theme: Theme, predefined: boolean) => ({
  color: predefined ? theme.palette.primary.main : theme.palette.grey[600],
  marginLeft: '4px',
  marginRight: '4px',
});

export default { defaultButtonStyling, selectedButtonStyling };
