import { SiteInfo } from '@cbo/shared-library';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useState, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { baseRoutes } from '../../constants/routes';
import { useSites } from '../../contexts/siteContext';
import SiteSelectorDialog from './SiteSelectorDialog';

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

function SiteSelectorMenu(props: Props) {
  const { anchorEl, setAnchorEl } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { selectedSite, updateSelectedSite, sortedSites } = useSites();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleViewAll = () => {
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleSiteSelection = (site: SiteInfo) => {
    setDialogOpen(false);

    if (site.enterpriseUnitId !== selectedSite.enterpriseUnitId) {
      const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

      // Check if the current route has a GUID, which will inevitably be site specific. If so
      // we want to route the user away so they won't encounter a 404
      if (uuidRegex.test(window.location.href)) {
        // Close the site selector dialog
        setAnchorEl(null);

        const baseRoute = baseRoutes.find((path) => window.location.pathname.includes(path));
        if (baseRoute) {
          // The appended ?siteChange optional param will indicate that only this routing
          // event should trigger a site change if user accepts it with unsaved changes.
          const path = `${baseRoute}?siteChange`;
          // If the user has unsaved changes, this will track if they choose to cancel the
          // navigation or continue. If they continue, this will update the site automatically.
          const unregister = history.listen((location) => {
            if (location.pathname + location.search === path) updateSelectedSite(site);

            // TBH this is a bit of a hack, to remove the appended ?siteChange
            window.history.replaceState(null, '', window.location.pathname);

            // This unregister is super important, because we only want to check the first route change
            // that happens after this function is called, and if that route change doesn't result in
            // the user going to that url we expect we don't want the site to change on the following route change
            unregister();
          });
          history.push(path);
        }
      } else {
        updateSelectedSite(site);
      }
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        data-testid='site-selector-menu'
        sx={{
          '.MuiPaper-root': {
            boxShadow:
              '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          },
        }}
        MenuListProps={{
          sx: { width: '380px', padding: '4px 0px 0px' },
        }}
      >
        <MenuItem
          disabled
          sx={{
            fontWeight: 500,
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.text.secondary,
            padding: '12px 8px 12px 12px',
            '&.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          {t('global.sites')}
        </MenuItem>
        {sortedSites.slice(0, 7).map((option) => (
          <MenuItem
            onClick={() => handleSiteSelection(option)}
            key={option.enterpriseUnitId}
            selected={option.enterpriseUnitId === selectedSite?.enterpriseUnitId}
            sx={{ padding: '12px 8px 12px 12px' }}
          >
            <Typography variant='body1'>{option.name}</Typography>
          </MenuItem>
        ))}
        <Divider
          sx={{
            '&.MuiDivider-root.MuiDivider-fullWidth': {
              marginBottom: 0,
            },
          }}
        />
        <MenuItem
          sx={{
            fontWeight: 500,
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.primary.dark,
            padding: '12px',
          }}
          onClick={() => handleViewAll()}
          data-testid='view-all-sites'
        >
          {t('globalFilters.viewAllSites')}
          <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '8px' }} />
        </MenuItem>
      </Menu>
      <SiteSelectorDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleSiteSelection={handleSiteSelection}
      />
    </>
  );
}

export default SiteSelectorMenu;
