import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Ty from '@mui/material/Typography';
import { GridRowId, GridRowSelectionModel, GridSeparatorIcon } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicButtonInfo } from '../../models/DynamicButtonInfo';
import DataGrid, { BaseDataGridProps } from '../DataGrid/DataGrid';
import colors from '../../lib/styles/colors';

export interface BaseGridRow {
  [key: string]: unknown;
  id: GridRowId;
}

export interface BulkActionDataGridProps extends BaseDataGridProps {
  buttons: DynamicButtonInfo[];
  treeCustomSelectionFunction?: (model: GridRowSelectionModel) => GridRowId[];
  customResetActions?: () => void;
}
function BulkActionDataGrid(props: BulkActionDataGridProps) {
  const { t } = useTranslation();
  const {
    buttons,
    rowSelectionModel,
    disableVirtualization,
    onRowSelectionModelChange,
    columns,
    sx,
    onPageChange,
    treeCustomSelectionFunction,
    customResetActions,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [selectionModelWrapper, setSelectionModelWrapper] = useState<GridRowId[]>([]);
  const [columnsWrapper, setColumnsWrapper] = useState(columns);
  const [actionMenu, setActionMenu] = useState<null | HTMLElement>(null);
  const BUTTONS_LIMIT = 5;
  const isMenuOpen = Boolean(actionMenu);
  const customOnSelectionModelChange = (model: GridRowSelectionModel) => {
    let treeModelLength = true;

    if (treeCustomSelectionFunction) {
      const treeModel = treeCustomSelectionFunction(model);
      setSelectedRowsCount(treeModel.length);
      if (treeModel.length === 0) {
        treeModelLength = false;
      }
    } else {
      setSelectedRowsCount(model.length);
      setSelectionModelWrapper(model as GridRowId[]);
    }

    if (model.length > 0 && treeModelLength) {
      const actionsColumn = columns.find((column) => column.field === 'actions');
      if (actionsColumn) {
        const columnsWithoutActions = columns.filter((c) => c.field !== 'actions');
        setColumnsWrapper(columnsWithoutActions);
      }
    } else {
      setColumnsWrapper(columns);
    }
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(model);
    }
  };

  const resetBulkActionState = () => {
    setSelectionModelWrapper([]);
    setSelectedRowsCount(0);
    setColumnsWrapper(columns);
    if (customResetActions) {
      customResetActions();
    }
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setActionMenu(null);
  };

  return (
    <Box component={Paper} sx={{ overflowX: 'auto', width: 'auto', border: 1, borderColor: colors.borderOnEmpty }}>
      {/* Bulk action bar */}
      <Box
        data-testid='bulk-action-header'
        sx={{
          minHeight: '44px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: selectedRowsCount > 0 ? theme.palette.primary.main : theme.palette.background.paper,
          color: selectedRowsCount > 0 ? theme.palette.primary.contrastText : theme.palette.text.primary,
        }}
      >
        {selectedRowsCount > 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: '100%',
              flex: 1,
            }}
          >
            <Ty
              sx={{
                padding: '8px 16px',
                marginY: 'auto',
              }}
              variant={isMobile ? 'caption' : 'body2'}
            >{`${selectedRowsCount} ${t('global.selected')}`}</Ty>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              {isMobile && buttons.length > 1 ? (
                <>
                  <Button
                    variant='text'
                    size={isMobile ? 'small' : 'medium'}
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      '&.MuiButton-text': { color: theme.palette.primary.contrastText },
                    }}
                    onClick={showMenu}
                  >
                    {t('buttonText.actions')}
                  </Button>
                  <Menu
                    open={isMenuOpen}
                    anchorEl={actionMenu}
                    onClose={closeMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    data-testid='bulk-action-mobile-menu'
                  >
                    {buttons.map((currentButton) => (
                      <MenuItem
                        dense
                        key={currentButton.text}
                        onClick={() => {
                          if (rowSelectionModel) {
                            currentButton.functionality(rowSelectionModel as GridRowId[]);
                          } else {
                            currentButton.functionality(selectionModelWrapper);
                          }

                          if (currentButton.resetStateAfterAction) {
                            resetBulkActionState();
                          }
                          closeMenu();
                        }}
                        data-testid={currentButton.dataTestId}
                      >
                        <ListItemIcon>{currentButton.icon}</ListItemIcon>
                        <ListItemText>{currentButton.text}</ListItemText>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                buttons.slice(0, BUTTONS_LIMIT).map((currentButton) => (
                  <Button
                    key={currentButton.text}
                    variant='text'
                    size={isMobile ? 'small' : 'medium'}
                    startIcon={currentButton.icon}
                    sx={{
                      '&.MuiButton-text': { color: theme.palette.primary.contrastText },
                      marginLeft: 1,
                    }}
                    data-testid={currentButton.dataTestId}
                    onClick={() => {
                      if (rowSelectionModel) {
                        currentButton.functionality(rowSelectionModel as GridRowId[]);
                      } else {
                        currentButton.functionality(selectionModelWrapper);
                      }
                      if (currentButton.resetStateAfterAction) {
                        resetBulkActionState();
                      }
                    }}
                  >
                    {`${currentButton.text}`}
                  </Button>
                ))
              )}

              <GridSeparatorIcon
                sx={{
                  height: '100%',
                }}
              />
              <Button
                variant='text'
                size={isMobile ? 'small' : 'medium'}
                sx={{
                  margin: '2px 14px 2px 0px',
                  '&.MuiButton-text': { color: theme.palette.primary.contrastText },
                }}
                data-testid='bulk-action-cancel-button'
                onClick={resetBulkActionState}
              >
                {t('buttonText.cancel')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Ty
            sx={{
              padding: '8px 16px',
            }}
            variant={isMobile ? 'caption' : 'body2'}
          >{`${selectedRowsCount} ${t('global.selected')}`}</Ty>
        )}
      </Box>
      <DataGrid
        {...props}
        disableVirtualization={disableVirtualization}
        onRowSelectionModelChange={customOnSelectionModelChange}
        rowSelectionModel={rowSelectionModel ?? selectionModelWrapper}
        columns={columnsWrapper}
        onPageChange={onPageChange}
        sx={{
          ...sx,
          borderRadius: '0px 0px 8px 8px',
          border: 'none',
        }}
        hideFooterSelectedRowCount
      />
    </Box>
  );
}

BulkActionDataGrid.defaultProps = {
  treeCustomSelectionFunction: undefined,
  customResetActions: undefined,
};

export default BulkActionDataGrid;
