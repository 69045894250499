/* eslint-disable react-hooks/exhaustive-deps */
import { AuthData, getAuthDocPath } from '@cbo/shared-library';
import { Organization } from '@ncr-voyix-commerce/react-common-components';
import { useOktaAuth } from '@okta/okta-react';
import { User as FirebaseUser, getAuth, onIdTokenChanged, signOut } from 'firebase/auth';
import { MessagePayload, deleteToken, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../constants/routes';
import { useCreateSelfEmployeeMutation } from '../labor/requests/mutations';
import { useEmployeeDetailsSelfQuery } from '../labor/requests/queries';
import useFirebaseTokenExchange from '../lib/auth';
import checkUserRoleForEmployment from '../lib/employee';
import { AuthStatus, User } from '../models/User';
import UserRole from '../models/UserRole';
import { useOrgContext } from '../org/CommonComponentWrapper';
import useDocumentSubscription from '../utils/hooks/useDocumentSubscription';
import { useFirebaseApi } from './firebaseApiContext';
import { useNotifications } from './notificationContext';

export const baseUser: Readonly<User> = {
  fullyAuthenticated: 'undetermined',
  role: UserRole.None,
  oktaStatus: 'undetermined',
  firebaseStatus: 'undetermined',
  firebaseToken: null,
  oktaToken: null,
  id: null,
  profile: null,
  org: null,
  isOrgSwitching: false,
  fcmToken: null,
  bslAuth: null,
};

export const UserContext = createContext<User>({
  ...baseUser,
});

UserContext.displayName = 'UserContext';

export const useUsers = () => useContext(UserContext);

type UserContextProviderProps = {
  children: React.ReactNode;
  vapidKey: string;
};

type ErrorMessageProps = {
  details: {
    statusCode: number;
  };
};

function unsetFirebase(user: User, firebaseStatus: AuthStatus): User {
  return {
    ...user,
    firebaseStatus,
    firebaseToken: baseUser.firebaseToken,
    id: baseUser.id,
    fullyAuthenticated: 'undetermined',
    org: baseUser.org,
    profile: baseUser.profile,
    role: baseUser.role,
  };
}

function unsetOkta(user: User, oktaStatus: AuthStatus): User {
  return {
    ...user,
    fullyAuthenticated: 'undetermined',
    oktaStatus,
  };
}

const refreshMinimum = 1000 * 60 * 5; // Refresh at least five minutes before expiration
const refreshOffset = Math.random() * 1000 * 60 * 3; // Up to three minute random offset for refreshes so that every open browser does not send a refresh request simultaneously
export function whenToRefresh(expires: number) {
  const refreshTarget = expires - refreshMinimum - refreshOffset;
  const duration = refreshTarget - Date.now();
  return Math.max(duration, 0);
}

function UserContextProvider({ children, vapidKey }: UserContextProviderProps) {
  const [user, setUser] = useState<User>({ ...baseUser });
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null | undefined>(undefined);
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const location = useLocation();
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();
  const notifications = useNotifications();
  const { organization, userOrganizations, error, updateOrganization } = useOrgContext();
  const [preferredOrg, setPreferredOrg] = useState(localStorage.getItem('org') ?? '');
  const [currentOrg, setCurrentOrg] = useState(organization);
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const firebaseTokenExchange = useFirebaseTokenExchange();
  const {
    data: selfEmployeeData,
    status: selfEmployeeStatus,
    error: selfEmployeeError,
  } = useEmployeeDetailsSelfQuery(user.fullyAuthenticated === 'authenticated' && checkUserRoleForEmployment(user));

  const createSelfEmployeeInfo = useCreateSelfEmployeeMutation();
  const { refreshAuth } = useFirebaseApi();
  const authDocPath = useMemo(() => {
    if (!firebaseUser?.uid || !user.org?.bslId || (!organization && !currentOrg)) {
      return undefined;
    }
    return getAuthDocPath(firebaseUser.uid, organization?.id ?? currentOrg?.id ?? '');
  }, [firebaseUser?.uid, user.org?.bslId, user.firebaseToken, organization]);

  // TODO: Add an error handler
  const authDocSnapshot = useDocumentSubscription<AuthData>(authDocPath);

  const isUserFullyAuthenticated = (
    firebaseStatus: AuthStatus,
    oktaStatus: AuthStatus,
    bslAuth: AuthData | null
  ): AuthStatus => {
    if (oktaStatus === 'undetermined') {
      return 'undetermined';
    }

    if (oktaStatus === 'unauthenticated') {
      return 'unauthenticated';
    }

    if (firebaseStatus === 'undetermined' || firebaseStatus === 'unauthenticated' || bslAuth === null) {
      return 'undetermined';
    }

    if (oktaStatus === 'authenticated' && firebaseStatus === 'authenticated' && bslAuth) {
      return 'authenticated';
    }

    throw new Error('Error determining authentication');
  };

  const isOrgSwitching = (
    selectedOrg: Organization | null,
    previousOrg: Organization | null,
    selectedToken: string | null,
    previousToken: string | null
  ): boolean => !!((selectedOrg && selectedOrg !== previousOrg) || (selectedToken && selectedToken === previousToken));

  // Listen for Auth Document
  useEffect(() => {
    if (organization && user.isOrgSwitching) {
      setCurrentOrg(organization);
    }
    const bslAuth = authDocSnapshot?.data() ?? null;
    setUser((currentUser) => ({ ...currentUser, bslAuth }));
  }, [authDocSnapshot]);

  useEffect(() => {
    const oktaJwt = user.oktaToken;
    if (user.bslAuth == null || refreshAuth == null || oktaJwt == null) {
      return () => undefined;
    }
    const refresh = whenToRefresh(user.bslAuth.expires.toMillis());
    const future = setTimeout(() => refreshAuth({ oktaJwt }), refresh);
    return () => clearTimeout(future);
  }, [user.bslAuth, refreshAuth, user.oktaToken]);

  // Setup listener to Firebase Auth State
  useEffect(() => onIdTokenChanged(getAuth(), setFirebaseUser), []);

  // Update User when firebase user is updated
  useEffect(() => {
    switch (firebaseUser) {
      case undefined: // user login state is undetermined
        setUser((currentUser) => unsetFirebase(currentUser, 'undetermined'));
        break;
      case null: // user has logged out
        setUser((currentUser) => unsetFirebase(currentUser, 'unauthenticated'));
        break;
      default:
        firebaseUser.getIdTokenResult().then((idToken) => {
          setUser((currentUser) => ({
            ...currentUser,
            firebaseStatus: 'authenticated',
            firebaseToken: idToken.token,
            id: (idToken.claims.user_id ?? '').toString(),
            org: idToken.claims.org,
            profile: idToken.claims.user,
            role: UserRole.None,
          }));
        });
        break;
    }
  }, [firebaseUser]);

  // Update User when Okta user is updated
  useEffect(() => {
    if (!oktaAuthState || location.pathname === routes.LOGIN) {
      setUser((currentUser) => unsetOkta(currentUser, 'undetermined'));
    } else if (oktaAuthState.isAuthenticated === false) {
      setUser((currentUser) => unsetOkta(currentUser, 'unauthenticated'));
    } else {
      setUser((currentUser) => ({
        ...currentUser,
        oktaStatus: 'authenticated',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, oktaAuthState, user.oktaStatus, location.pathname]);

  useEffect(() => {
    setUser((currentUser) => ({
      ...currentUser,
      oktaToken: oktaAuthState?.accessToken?.accessToken ?? null,
    }));
  }, [oktaAuthState?.accessToken?.accessToken]);

  // This effect makes sure that we are logged into the firebase account
  // that matches the okta account email.
  useEffect(() => {
    async function getFCMToken() {
      if (!(await isSupported())) {
        // eslint-disable-next-line no-console
        console.warn('Messaging is not supported in your browser');
        return;
      }
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey,
      })
        .then((token) => {
          if (token) {
            setFcmToken(token);
            onMessage(messaging, (payload: MessagePayload) => {
              notifications.setNewNotification(payload);
            });
          }
        })
        .catch((e) => {
          // the user didn't allow access to notifications
        });
    }

    if (user.firebaseStatus === 'authenticated' && user.oktaStatus === 'unauthenticated') {
      // If we have a firebase email that does not match an okta email
      // then we need to log out of firebase
      signOut(getAuth()).then(() => {
        deleteToken(getMessaging());
        setFcmToken(null);
      });
    } else if (
      user.oktaStatus === 'authenticated' &&
      user.firebaseStatus === 'unauthenticated' &&
      oktaAuthState &&
      organization
    ) {
      // if we have an okta email but no firebase email
      // then we need to do the token exchange
      // TODO: Consider exchanging the token whenever we get a new okta token
      const accessToken = oktaAuthState.accessToken?.accessToken || '';
      firebaseTokenExchange(accessToken, organization).then(() => {
        getFCMToken();
      });
    } else if (user.oktaStatus === 'authenticated' && user.firebaseStatus === 'authenticated' && oktaAuthState) {
      getFCMToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.firebaseStatus, user.oktaStatus, oktaAuthState, organization, vapidKey]);

  useEffect(() => {
    if (!error && organization && oktaAuthState) {
      if (
        !currentOrg &&
        preferredOrg &&
        organization.organizationName !== preferredOrg &&
        userOrganizations.some(({ organizationName }) => organizationName === preferredOrg)
      ) {
        updateOrganization(preferredOrg);
        return;
      }
      setPreferredOrg(organization.organizationName);
      localStorage.setItem('org', organization.organizationName);
      const accessToken = oktaAuthState.accessToken?.accessToken || '';
      firebaseTokenExchange(accessToken, organization);
      setCurrentToken(user.firebaseToken);
    }
  }, [organization, oktaAuthState, firebaseTokenExchange, userOrganizations, updateOrganization]);

  useEffect(() => {
    setUser((oldUser) => ({
      ...oldUser,
      fcmToken,
    }));
  }, [fcmToken]);

  useEffect(() => {
    const authenticatedState: AuthStatus = isUserFullyAuthenticated(user.firebaseStatus, user.oktaStatus, user.bslAuth);
    setUser((currentUser) => ({
      ...currentUser,
      fullyAuthenticated: authenticatedState,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.firebaseStatus, user.oktaStatus, user.bslAuth]);

  useEffect(() => {
    const isOrganizationSwitching: boolean = isOrgSwitching(organization, currentOrg, user.firebaseToken, currentToken);
    setUser((currentUser) => ({
      ...currentUser,
      isOrgSwitching: isOrganizationSwitching,
    }));
  }, [organization, user.firebaseToken, currentOrg]);

  // This effect handles fetching and creating initial information for an employee user
  useEffect(() => {
    // Check that the user is authenticated and has one of the roles that requires fetching information
    if (
      user.fullyAuthenticated === 'authenticated' &&
      checkUserRoleForEmployment(user) &&
      selfEmployeeStatus !== 'loading'
    ) {
      const employeeDoesNotExist =
        selfEmployeeError && (selfEmployeeError as ErrorMessageProps).details.statusCode === 404;
      // If the employee info already exists, update id for user context
      if (!selfEmployeeError && selfEmployeeData) {
        setUser((currentUser) => ({
          ...currentUser,
          id: selfEmployeeData.employeeId,
        }));
      }
      // If the employee info does not exist, create it and update id for user context
      if (employeeDoesNotExist && user.profile) {
        createSelfEmployeeInfo.mutate(
          {
            ncrUserId: user.profile.userId,
            lastName: user.profile.lastName,
            firstName: user.profile.firstName,
            emailAddress: user.profile.email,
          },
          {
            onSuccess: (data) =>
              data?.employeeId && setUser((currentUser) => ({ ...currentUser, id: data.employeeId })),
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfEmployeeStatus, selfEmployeeError, selfEmployeeData, user.fullyAuthenticated, user.bslAuth?.personas]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
