import { createTheme as createMuiTheme, Theme, ThemeOptions, alpha } from '@mui/material/styles';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import Info from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { createTheme as createNCRTheme } from '@ncr-design-system/shared-frontend';

declare module '@mui/material/styles' {
  interface PaletteColor {
    main: string;
    dark: string;
    light: string;
    contrastText: string;
    subtle: string;
    subtleHover: string;
    subtleContrast: string;
    contrast: string;
  }

  interface Palette {
    graphColors: string[];
    surfaceBorder: string;
    interactiveBorder: string;
    category1: PaletteColor;
    category2: PaletteColor;
    category3: PaletteColor;
    category4: PaletteColor;
    category5: PaletteColor;
    category6: PaletteColor;
    category7: PaletteColor;
    category8: PaletteColor;
  }

  interface PaletteColorOptions extends Partial<Omit<PaletteColor, 'main'>> {
    main: string;
  }

  interface PaletteOptions {
    graphColors?: string[];
    surfaceBorder?: string;
    interactiveBorder?: string;
    category1?: PaletteColorOptions;
    category2?: PaletteColorOptions;
    category3?: PaletteColorOptions;
    category4?: PaletteColorOptions;
    category5?: PaletteColorOptions;
    category6?: PaletteColorOptions;
    category7?: PaletteColorOptions;
    category8?: PaletteColorOptions;
  }

  interface TypographyVariants {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
  }
}

/** RESTAURANTS UI KIT THEME */

const commonThemeOptions: ThemeOptions = {
  typography: {
    button: { textTransform: 'none' },
    fontFamily: `Inter Variable, sans-serif`,
    display1: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 2.5,
    },
    display2: {
      fontWeight: 600,
      fontSize: '1.8125rem',
      lineHeight: 2.2475,
    },
    h1: {
      fontWeight: 700,
      lineHeight: 2.031,
      fontSize: '1.5625rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.5075,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.25125,
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.25125,
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.995,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.245,
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableRipple: false,
        disableFocusRipple: false,
        disableTouchRipple: false,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        disableTouchRipple: false,
      },
      styleOverrides: {
        root: { boxShadow: 'none' },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          boxShadow: 'none',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none',
            '&.MuiButton-contained': {
              background: ownerState.color && ownerState.color !== 'inherit' && theme.palette[ownerState.color].dark,
            },
            '&.MuiButton-outlined .MuiButton-text': {
              background:
                ownerState.color && ownerState.color !== 'inherit' && alpha(theme.palette[ownerState.color].main, 0.04),
            },
          },
          '&.MuiButton-sizeLarge': {
            padding: '10px 22px',
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 16px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px 10px',
          },
          '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `inset 0 0 0 1px ${theme.palette.primary.contrastText}`,
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor:
            theme.palette[ownerState.color && ownerState.color !== 'default' ? ownerState.color : 'primary'].subtle,
          color:
            theme.palette[ownerState.color && ownerState.color !== 'default' ? ownerState.color : 'primary']
              .subtleContrast,
          fontSize: '0.8125rem',
          fontWeight: 500,
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <Info />,
          error: <ErrorIcon />,
          success: <CheckCircleIcon />,
          warning: <ReportProblemIcon />,
        },
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: theme.palette[ownerState.severity ? ownerState.severity : 'info'].subtle,
          color: theme.palette[ownerState.severity ? ownerState.severity : 'info'].subtleContrast,
          borderRadius: '8px',
          alignItems: 'center',
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: { boxShadow: 'none' },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { borderRadius: '8px' },
      },
    },
  },
};

const commonPaletteOptions = {
  common: { black: '#000', white: '#fff' },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#F5F5F5',
    A200: '#EEEEEE',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  tonalOffset: 0.5,
  contrastThreshold: 4.5,
};

const lightThemeOptions: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    ...commonPaletteOptions,
    mode: 'light',
    primary: {
      main: '#303030',
      dark: '#1A1A1A',
      light: '#828282',
      contrastText: '#FFFFFF',
      subtle: '#E0E0E0',
      subtleHover: '#CFCFCF',
      subtleContrast: '#000000',
    },
    secondary: {
      main: '#5F249F',
      dark: '#42196F',
      light: '#B280FF',
      contrastText: '#FFFFFF',
      subtle: '#ECE1FE',
      subtleHover: '#E0CDFE',
      subtleContrast: '#2F124F',
    },
    success: {
      main: '#24802D',
      dark: '#064E05',
      light: '#3CC84A',
      contrastText: '#FFFFFF',
      subtle: '#DAEED8',
      subtleHover: '#C3E3C0',
      subtleContrast: '#08400E',
    },
    error: {
      main: '#DD1708',
      dark: '#890802',
      light: '#FB9189',
      contrastText: '#FFFFFF',
      subtle: '#FDE3E3',
      subtleHover: '#FBCCCB',
      subtleContrast: '#710B01',
    },
    warning: {
      main: '#AB5A05',
      dark: '#653700',
      light: '#F99934',
      contrastText: '#FFFFFF',
      subtle: '#FCE3CA',
      subtleHover: '#FAD4AD',
      subtleContrast: '#552C07',
    },
    info: {
      main: '#086DD9',
      dark: '#134085',
      light: '#70B3FA',
      contrastText: '#FFFFFF',
      subtle: '#D8ECFD',
      subtleHover: '#BBDEFB',
      subtleContrast: '#053570',
    },
    text: {
      primary: '#212121',
      secondary: '#656565',
      disabled: '#9E9E9E',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0,0,0,0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0,0,0,0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    divider: '#E0E0E0',
    // Custom theming not part of MUI
    category1: {
      main: '#2B69DD',
      contrast: '#FFFFFF',
      subtle: '#E2EBFD',
      subtleHover: '#B7CEFB',
      subtleContrast: '#07307D',
    },
    category2: {
      main: '#139A7F',
      contrast: '#FFFFFF',
      subtle: '#E4F6F4',
      subtleHover: '#94E0D7',
      subtleContrast: '#07453E',
    },
    category3: {
      main: '#A6305C',
      contrast: '#FFFFFF',
      subtle: '#F8E2EC',
      subtleHover: '#F1C6D8',
      subtleContrast: '#621E3A',
    },
    category4: {
      main: '#B9840E',
      contrast: '#FFFFFF',
      subtle: '#FCF4E4',
      subtleHover: '#EFCD80',
      subtleContrast: '#583C0E',
    },
    category5: {
      main: '#C461C6',
      contrast: '#FFFFFF',
      subtle: '#F7E8F7',
      subtleHover: '#EBC5EC',
      subtleContrast: '#69156A',
    },
    category6: {
      main: '#213873',
      contrast: '#FFFFFF',
      subtle: '#E7ECF8',
      subtleHover: '#C4CFEE',
      subtleContrast: '#1E3267',
    },
    category7: {
      main: '#D85337',
      contrast: '#FFFFFF',
      subtle: '#F9E5E1',
      subtleHover: '#F3C8BF',
      subtleContrast: '#622113',
    },
    category8: {
      main: '#5F249F',
      contrast: '#FFFFFF',
      subtle: '#F0E6F9',
      subtleHover: '#DDC9F3',
      subtleContrast: '#48157F',
    },
    graphColors: ['#203A7E', '#4A5AFF', '#069887', '#D07420', '#B03569', '#C461C6', '#D85337', '#5F249F'],
    surfaceBorder: '#E0E0E0',
    interactiveBorder: '#919191',
  },
};

const darkThemeOptions: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    ...commonPaletteOptions,
    mode: 'dark',
    primary: {
      main: '#EEEEEE',
      dark: '#FFFFFF',
      light: '#757575',
      contrastText: '#292929',
      subtle: '#454545',
      subtleHover: '#575757',
      subtleContrast: '#FFFFFF',
    },
    secondary: {
      main: '#B280FF',
      dark: '#E3D1FF',
      light: '#5F259F',
      contrastText: '#292929',
      subtle: '#5C2499',
      subtleHover: '#521F89',
      subtleContrast: '#F3EBFF',
    },
    error: {
      main: '#FF6459',
      dark: '#FFC9C7',
      light: '#BA1408',
      contrastText: '#292929',
      subtle: '#6F2121',
      subtleHover: '#7E2525',
      subtleContrast: '#FFE3E1',
    },
    warning: {
      main: '#DC8126',
      dark: '#FECE8F',
      light: '#874D12',
      contrastText: '#292929',
      subtle: '#563514',
      subtleHover: '#623D18',
      subtleContrast: '#FFE6BF',
    },
    info: {
      main: '#379DEE',
      dark: '#B2DBFC',
      light: '#085DA0',
      contrastText: '#292929',
      subtle: '#043D72',
      subtleHover: '#0B467A',
      subtleContrast: '#CFEEFF',
    },
    success: {
      main: '#59A94F',
      dark: '#A3E796',
      light: '#2E6827',
      contrastText: '#292929',
      subtle: '#164612',
      subtleHover: '#1C4F17',
      subtleContrast: '#C4F7B5',
    },
    text: {
      primary: '#F2F2F2',
      secondary: '#A3A4A6',
      disabled: '#6B696D',
    },
    background: {
      default: '#171717',
      paper: '#1F1F1F',
    },
    action: {
      active: 'rgba(255,255,255,0.64)',
      hover: 'rgba(255,255,255,0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255,255,255,0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255,255,255,0.30)',
      disabledBackground: 'rgba(255,255,255,0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255,255,255,0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    divider: '#3C3C3C',
    tonalOffset: 0.5,
    contrastThreshold: 4.5,
    // Custom theming not part of MUI
    category1: {
      main: '#A2BDF0',
      contrast: '#000000',
      subtle: '#14387B',
      subtleHover: '#17408D',
      subtleContrast: '#E2E9FE',
    },
    category2: {
      main: '#07C5A3',
      contrast: '#000000',
      subtle: '#024539',
      subtleHover: '#034F41',
      subtleContrast: '#98FBE9',
    },
    category3: {
      main: '#DC4C88',
      contrast: '#000000',
      subtle: '#621E3A',
      subtleHover: '#7E264A',
      subtleContrast: '#F9D8E5',
    },
    category4: {
      main: '#FFB747',
      contrast: '#000000',
      subtle: '#4D3B00',
      subtleHover: '#5C4600',
      subtleContrast: '#FFE8B8',
    },
    category5: {
      main: '#B371CC',
      contrast: '#000000',
      subtle: '#572669',
      subtleHover: '#662C7C',
      subtleContrast: '#F1E5F6',
    },
    category6: {
      main: '#577CDB',
      contrast: '#000000',
      subtle: '#1F346B',
      subtleHover: '#274287',
      subtleContrast: '#D7E0F4',
    },
    category7: {
      main: '#E17B65',
      contrast: '#000000',
      subtle: '#6B2415',
      subtleHover: '#7C2A18',
      subtleContrast: '#F9E5E1',
    },
    category8: {
      main: '#9E63DE',
      contrast: '#000000',
      subtle: '#4B1C7D',
      subtleHover: '#5F249E',
      subtleContrast: '#E7DBF6',
    },
    graphColors: ['#5283E5', '#8A9BFF', '#07C5A3', '#FFB747', '#DC4C88', '#C589DC', '#E17B65', '#9E63DE'],
    surfaceBorder: '#3C3C3C',
    interactiveBorder: '#727273',
  },
};

/** DESIGN SYSTEM THEME */

const dsCommonThemeOptions: ThemeOptions = {
  typography: {
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: '#656565',
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableRipple: false,
        disableFocusRipple: false,
        disableTouchRipple: false,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        disableTouchRipple: false,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: <RadioButtonUnchecked />,
        checkedIcon: <RadioButtonChecked color='primary' />,
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <Info />,
          error: <ErrorIcon />,
          success: <CheckCircleIcon />,
          warning: <ReportProblemIcon />,
        },
      },
    },
  },
};

const dsLightTheme: ThemeOptions = {
  ...dsCommonThemeOptions,
  palette: {
    mode: 'light',
    background: {
      default: '#FAFAFA',
      paper: '#FFF',
    },
    graphColors: ['#203A7E', '#4A5AFF', '#069887', '#D07420', '#B03569', '#C461C6', '#D85337', '#5F249F'],
  },
};

const dsDarkTheme: ThemeOptions = {
  ...dsCommonThemeOptions,
  palette: {
    mode: 'dark',
    background: {
      default: '#303030',
      paper: '#292929',
    },
    graphColors: ['#5283E5', '#8A9BFF', '#07C5A3', '#FFB747', '#DC4C88', '#C589DC', '#E17B65', '#9E63DE'],
  },
};

function createTheme(darkMode: boolean, useDsTheme: boolean) {
  let theme: Theme;

  if (useDsTheme) {
    theme = createNCRTheme(darkMode ? dsDarkTheme : dsLightTheme);
  } else {
    theme = createMuiTheme(darkMode ? darkThemeOptions : lightThemeOptions);
  }

  return theme;
}

export default createTheme;
