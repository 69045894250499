import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UserInfo } from '@cbo/shared-library';
import { MutateOptions } from '@tanstack/react-query';
import Header from '../../components/Header/Header';
import { useUsers } from '../../contexts/userContext';
import { formatDate } from '../../utils';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import AcknowledgementInputArea, {
  AcknowledgementCheckboxProps,
  AcknowledgementDateInputProps,
  AcknowledgementInputProps,
} from '../modals/LaborRuleBaseConfirmationModal/AcknowledgementInputArea';
import { EulaRecordData, EulaRecordRequest } from '../models/EulaRecord';
import { useUpdateEulaRecord } from '../requests/mutations';

const padding = 2;
const tKey = 'laborRules';
interface UserAgreementContentProps {
  lastRevisedDate: string;
}

interface UserAgreementModalProps {
  onClose: () => void;
  eulaRecord: void | EulaRecordData | undefined;
}

function UserAgreementContent(props: UserAgreementContentProps) {
  const { t } = useTranslation();
  const { lastRevisedDate } = props;
  const text = t(`${tKey}.userAgreementContent`, { lastRevisedDate });

  return (
    <Box sx={{ p: padding, maxHeight: '40vh', overflow: 'auto' }}>
      <Typography data-testid='agreement-text' sx={{ whiteSpace: 'pre-line' }}>
        {text}
      </Typography>
    </Box>
  );
}

export const handleOnProceed = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
  userInfo: UserInfo | null,
  mutate: (
    variables: EulaRecordRequest,
    options?: MutateOptions<void | EulaRecordData, unknown, EulaRecordRequest, unknown> | undefined
  ) => void,
  onClose: () => void,
  t: TFunction<'translation', undefined>
) => {
  e.preventDefault();
  if (userInfo) {
    mutate(
      { accepterName: userInfo.name || '', eulaVersionNumber: t('laborRules.currEulaVersion') },
      {
        onSuccess: onClose,
      }
    );
  }
};

function UserAgreementModal(props: UserAgreementModalProps) {
  const { t } = useTranslation();
  const updateEulaRecordMutation = useUpdateEulaRecord();
  const { profile: userInfo } = useUsers();
  const { onClose, eulaRecord } = props;
  const theme = useTheme();

  const readOnly = !!eulaRecord?.acceptanceDate;
  const useDate = readOnly ? new Date(eulaRecord?.acceptanceDate?.concat('T00:00:00.000')) : new Date();
  const acceptanceDate = formatDate(useDate, null, {
    options: { year: 'numeric', month: '2-digit', day: '2-digit' },
  });

  const defaultValues = {
    acceptanceDate,
    fullName: eulaRecord?.accepterName,
    understandText: readOnly,
    agreeText: readOnly,
  };
  const validationSchema = yup.object({
    agreeText: yup.boolean().required().isTrue(),
    understandText: yup.boolean().required().isTrue(),
    acceptanceDate: yup.date().required(),
    fullName: yup.string().required().max(50, 'Name cannot exceed 50 characters'),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const formContext = useForm({ defaultValues, resolver });

  const {
    control,
    formState: { errors },
  } = formContext;

  const agreeCheckbox: AcknowledgementCheckboxProps = {
    name: 'agreeText',
    label: t(`${tKey}.agreeCheckboxLabel`),
    datatestid: 'agreeCheckbox',
    value: 'agreeCheckbox',
    required: true,
    disabled: readOnly,
    readOnly,
    control,
  };

  const understandCheckbox: AcknowledgementCheckboxProps = {
    name: 'understandText',
    label: t(`${tKey}.understandCheckboxLabel`),
    datatestid: 'understandCheckbox',
    value: 'understandCheckbox',
    required: true,
    disabled: readOnly,
    readOnly,
    control,
  };

  const fullNameField: AcknowledgementInputProps = {
    name: 'fullName',
    required: true,
    readOnly,
    error: !!errors?.fullName,
    dataTestId: 'fullName-textfield',
    disableUnderline: readOnly,
  };

  const acceptanceDateField: AcknowledgementDateInputProps = {
    name: 'acceptanceDate',
    dataTestId: 'acceptanceDate-textfield',
    readOnly: true,
    required: true,
    hasHelperText: !readOnly,
    disableUnderline: readOnly,
  };

  return (
    <Box>
      <Modal
        open
        data-testid='labor-rules-user-agreement-modal'
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            overflow: 'auto',
            background: theme.palette.background.paper,
            width: '55%',
            maxHeight: '90vh',
            borderRadius: 0.8,
          }}
        >
          <Box
            pt={padding}
            pl={padding}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Header title={t(`${tKey}.userAgreementTitle`)} dataTestId='user-agreement-title' />
            {eulaRecord?.acceptanceDate ? (
              <IconButton
                aria-label={t('buttonText.close')}
                data-testid='close-icon-btn'
                onClick={onClose}
                sx={{ mr: 1 }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
          <Divider />
          <UserAgreementContent lastRevisedDate='07/31/2024' />
          <Divider />
          <Box padding={3} data-testid='agreement-and-acknowledgement-div'>
            {!readOnly ? (
              <>
                <Typography variant='subtitle2' sx={{ color: theme.palette.text.secondary }}>
                  {t(`${tKey}.allFieldsRequired`)}
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    mt: 2,
                    color:
                      errors?.agreeText?.message || errors?.understandText?.message
                        ? theme.palette.error.main
                        : theme.palette.grey[600],
                  }}
                >
                  {t(`${tKey}.pleaseAccept`)}
                </Typography>
              </>
            ) : null}
            <FormContainer formContext={formContext}>
              <AcknowledgementInputArea
                tKey='laborRules'
                checkboxOne={agreeCheckbox}
                checkboxTwo={understandCheckbox}
                firstInput={fullNameField}
                dateInput={acceptanceDateField}
              />
              <Box
                data-testid='acknowledgement-buttons-div'
                sx={{ display: 'flex', justifyContent: 'right', mr: 1, mt: 2 }}
              >
                {readOnly ? (
                  <Button
                    aria-label='done button'
                    onClick={onClose}
                    color='primary'
                    variant='contained'
                    data-testid='done-button'
                  >
                    {t(`buttonText.done`)}
                  </Button>
                ) : (
                  <Button
                    aria-label='sign agreement button'
                    data-testid='proceed-button'
                    onClick={(e) =>
                      formContext.handleSubmit(() =>
                        handleOnProceed(e, userInfo, updateEulaRecordMutation.mutate, onClose, t)
                      )()
                    }
                    color='primary'
                    variant='contained'
                  >
                    {t(`${tKey}.proceedToLaborRules`)}
                  </Button>
                )}
              </Box>
            </FormContainer>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

UserAgreementModal.defaultProps = {};

export default UserAgreementModal;
