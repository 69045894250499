import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { SyntheticEvent } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

interface DialogCloseButtonProps {
  onClose: () => void;
  sx?: SxProps<Theme>;
}

export function DialogCloseButton(props: DialogCloseButtonProps) {
  const { onClose, sx } = props;
  const { t } = useTranslation();
  return (
    <IconButton aria-label={t('buttonText.close')} onClick={onClose} sx={sx} data-testid='dialog--close-icon-btn'>
      <CloseIcon />
    </IconButton>
  );
}

DialogCloseButton.defaultProps = {
  sx: {
    bottom: '3px',
  },
};

export interface ActionsDialogProps {
  open: boolean;
  onClose: () => void;
  dataTestId: string;
  dialogTitle: string;
  additionalDialogTitleContent?: React.ReactNode;
  dialogContentText?: string;
  dialogContentTextColor?: string;
  additionalDialogContent?: React.ReactNode;
  dialogKeepMounted?: boolean;
  dialogDisablePortal?: boolean;
  closeButtonText: string;
  onActionClick?: () => void;
  actionButtonText?: string;
  actionButtonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  disableActionButton?: boolean;
  closeButtonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  actionButtonVariant?: 'contained' | 'outlined' | 'text' | undefined;
  actionButtonType?: 'submit' | 'button' | 'reset' | undefined;
  closeButtonVariant?: 'contained' | 'outlined' | 'text' | undefined;
  checkboxText?: string;
  onCheckboxChange?: ((event: SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined;
  tooltipText?: string;
  tooltipSlotProps?: TooltipProps['slotProps'];
}

function ActionsDialog(props: ActionsDialogProps) {
  const {
    open,
    onClose,
    dataTestId,
    dialogTitle,
    dialogContentText,
    dialogContentTextColor,
    additionalDialogTitleContent,
    additionalDialogContent,
    dialogKeepMounted,
    dialogDisablePortal,
    closeButtonText,
    closeButtonColor,
    closeButtonVariant,
    onActionClick,
    actionButtonText,
    actionButtonColor,
    actionButtonVariant,
    actionButtonType,
    disableActionButton,
    checkboxText,
    onCheckboxChange,
    tooltipText,
    tooltipSlotProps,
  } = props;

  const actionButton = (
    <Button
      data-testid='dialog--action-btn'
      color={actionButtonColor}
      variant={actionButtonVariant}
      onClick={onActionClick}
      disabled={disableActionButton}
      type={actionButtonType}
      sx={{
        ml: 1,
        pointerEvents: 'auto',
        '&.Mui-disabled': {
          pointerEvents: 'none',
        },
      }}
    >
      {actionButtonText}
    </Button>
  );

  const actionButtonWithTooltip = (
    <Tooltip title={tooltipText} arrow slotProps={tooltipSlotProps}>
      <span>{actionButton}</span>
    </Tooltip>
  );

  return (
    <Dialog
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      fullWidth
      keepMounted={dialogKeepMounted}
      disablePortal={dialogDisablePortal}
      maxWidth='sm'
      sx={{
        '& .MuiDialogTitle-root': {
          padding: '24px 24px 8px 24px',
        },
        '& .MuiDialogContent-root': {
          paddingBottom: '32px',
        },
        '& .MuiDialogActions-root': {
          padding: '0px 24px 24px 24px',
        },
        '& .MuiDialogActions-root>:not(:first-of-type)': {
          marginLeft: 1.5,
        },
      }}
      disableEnforceFocus
    >
      <DialogTitle data-testid='dialog--title'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {dialogTitle}
          <DialogCloseButton onClose={onClose} />
        </Box>
        {additionalDialogTitleContent}
      </DialogTitle>
      <DialogContent data-testid='dialog--content'>
        {dialogContentText && dialogContentText.length > 0 && (
          <DialogContentText
            variant='body2'
            sx={{
              color: dialogContentTextColor,
              whiteSpace: 'pre-line',
            }}
          >
            {dialogContentText}
          </DialogContentText>
        )}
        {additionalDialogContent}
      </DialogContent>
      <DialogActions sx={{ justifyContent: checkboxText ? 'space-between' : 'flex-end' }}>
        {checkboxText && (
          <FormControlLabel
            control={<Checkbox data-testid='actions-checkbox' size='small' />}
            label={
              <Box data-testid='actions-checkbox-text' sx={{ typography: 'body2' }}>
                {checkboxText}
              </Box>
            }
            onChange={onCheckboxChange}
          />
        )}
        <Box>
          <Button
            variant={closeButtonVariant}
            data-testid='dialog--close-btn'
            onClick={onClose}
            color={closeButtonColor}
          >
            {closeButtonText}
          </Button>
          {/* If this dialog has actions, then show the actions button. If not it will just show the close button */}
          {!!actionButtonText && (tooltipText && tooltipText.length > 0 ? actionButtonWithTooltip : actionButton)}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

ActionsDialog.defaultProps = {
  onActionClick: undefined,
  actionButtonText: '',
  actionButtonColor: 'primary',
  closeButtonColor: 'primary',
  actionButtonVariant: 'contained',
  disableActionButton: false,
  actionButtonType: 'button',
  closeButtonVariant: 'contained',
  checkboxText: '',
  onCheckboxChange: undefined,
  dialogContentText: '',
  dialogContentTextColor: 'text.primary',
  additionalDialogContent: undefined,
  additionalDialogTitleContent: undefined,
  dialogKeepMounted: false,
  dialogDisablePortal: false,
  tooltipText: '',
  tooltipSlotProps: {},
};

export default ActionsDialog;
