import Stack from '@mui/material/Stack';
import { ButtonProps } from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import StickyFooter from './StickyFooter';

export type StickyButton = {
  name?: string;
  onClick?: () => void | Promise<void>;
  dataTestId?: string;
  disabled?: boolean;
  ariaLabel?: string;
  variant?: ButtonProps['variant'];
  submitType?: boolean;
  noButton?: boolean;
};

type FormFooterProps = {
  loading?: boolean;
  buttons: StickyButton[];
};

/**
 * This component implements the StickyFooter but with the common pattern of a button footer. This footer is used
 * on almost all pages with editable information/forms. The most common pattern is an outlined cancel button followed
 * by a contained save button. There are defaults in place to use that text and style unless other props are provided.
 *
 * @param buttons An array of buttons. I added customizable props to the StickyButton type. More can be added if needed.
 * In this array, please have buttons left to right. For example, the common pattern is an outlined cancel button and then
 * a contained save button. The array would look like [{cancelButton}, {saveButton}].
 * @param loading Currently only applied to the "save"/right-most button. If there is a need to have loading in more buttons,
 * feel free to edit the component. I did not see any use cases of that.
 */
function FormFooter(props: FormFooterProps) {
  const { loading, buttons } = props;
  const { t } = useTranslation();

  const getButtonText = (index: number) => {
    if (index === 0 && isNil(buttons[0].name)) {
      return t('buttonText.cancel');
    }
    if (index === buttons.length - 1 && isNil(buttons[buttons.length - 1].name)) {
      return t('buttonText.save');
    }
    // This should always be t(buttons[index].name) but name is optional so putting in the save as a default.
    return t(buttons[index].name ?? 'buttonText.save');
  };

  const getVariant = (index: number) => {
    if (index !== buttons.length - 1 && !buttons[index].variant) {
      return 'text';
    }
    if (index === buttons.length - 1 && !buttons[index].variant) {
      return 'contained';
    }
    return buttons[index].variant;
  };

  return (
    <StickyFooter>
      <Stack direction='row' spacing={2} justifyContent='flex-end' padding={2} mr={2}>
        {buttons &&
          buttons.map((button, i) =>
            button.noButton ? null : (
              <LoadingButton
                key={`sticky${getButtonText(i)}Button`}
                variant={getVariant(i)}
                size='large'
                onClick={button.onClick}
                data-testid={button.dataTestId}
                disabled={!isNil(button.disabled) ? button.disabled : false}
                aria-label={button.ariaLabel}
                type={button.submitType ? 'submit' : undefined}
                loading={i === buttons.length - 1 && !isNil(loading) ? loading : false}
              >
                {getButtonText(i)}
              </LoadingButton>
            )
          )}
      </Stack>
    </StickyFooter>
  );
}

FormFooter.defaultProps = {
  loading: false,
};

export default FormFooter;
