import { ScaleStyle } from '@cbo/shared-library';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SingleValueCard, { SingleValueCardProps } from '../../../components/SingleValueCard/SingleValueCard';
import DashboardLineChart, { DashboardLineChartProps } from '../LineChart/DashboardLineChart';

export interface ScorecardContainerProps {
  scaleStyle: ScaleStyle;
  key: number;
  singleValueCardProps: SingleValueCardProps;
  lineChartProps: Omit<DashboardLineChartProps, 'mobileSize'>;
}

export type MobileSize = 'small' | 'medium' | 'large' | undefined;

function ScorecardContainer({ scaleStyle, singleValueCardProps, lineChartProps }: ScorecardContainerProps) {
  const theme = useTheme();
  const isLargeMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumMobile = useMediaQuery('(max-width:390px)');
  const isSmallMobile = useMediaQuery('(max-width:374px)');

  const calculateMobileSize = (isSmall: boolean, isMedium: boolean, isLarge: boolean): MobileSize => {
    if (isSmall) return 'small';
    if (isMedium) return 'medium';
    if (isLarge) return 'large';

    return undefined;
  };

  const mobileSize = calculateMobileSize(isSmallMobile, isMediumMobile, isLargeMobile);

  if (scaleStyle === ScaleStyle.LARGE) {
    return <DashboardLineChart {...lineChartProps} mobileSize={mobileSize} />;
  }

  return <SingleValueCard {...singleValueCardProps} mobileSize={mobileSize} />;
}

export default ScorecardContainer;
