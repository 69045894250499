import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteInfo } from '@cbo/shared-library';
import SearchBar from '../../components/GlobalFilterBar/SearchBar/SearchBar';
import AllSitesList from './AllSitesList';

type SiteSelectorDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  handleSiteSelection: (site: SiteInfo) => void;
};

function SiteSelectorDialog(props: SiteSelectorDialogProps) {
  const { dialogOpen, setDialogOpen, handleSiteSelection } = props;
  const { t } = useTranslation();
  const [siteSearch, setSiteSearch] = useState('');

  return (
    <Dialog open={dialogOpen} PaperProps={{ sx: { width: '500px', height: '612px' } }}>
      <Box padding='16px 10px 16px 24px'>
        <DialogTitle sx={{ padding: 0 }} variant='h6'>
          {t('globalFilters.sites')}
        </DialogTitle>
      </Box>
      <IconButton
        onClick={() => setDialogOpen(false)}
        sx={{
          position: 'absolute',
          top: '12px',
          right: '16px',
        }}
      >
        <Close />
      </IconButton>
      <Box sx={{ padding: '0px 24px 24px' }}>
        <SearchBar showClearIcon={false} searchValue={siteSearch} setSearchValue={setSiteSearch} />
      </Box>
      <DialogContent sx={{ overflowY: 'auto', padding: 0 }}>
        <AllSitesList searchValue={siteSearch} handleSiteSelection={handleSiteSelection} />
      </DialogContent>
    </Dialog>
  );
}

export default SiteSelectorDialog;
