import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Ty, { TypographyTypeMap } from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';

export type HeaderProps = {
  title: string;
  description?: string;
  additionalDescription?: string | React.ReactNode;
  dataTestId: string;
  maxDescriptionWidth?: string;
  variant?: TypographyTypeMap['props']['variant'];
  chip?: {
    color: 'primary' | 'default' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
    label: string;
  };
  isReportHeader?: boolean;
  action?: React.ReactNode;
  headerActions?: React.ReactNode[];
  headerMarginBottom?: number;
  headerMarginTop?: number;
};

function Header(props: HeaderProps) {
  const {
    title,
    description,
    additionalDescription,
    dataTestId,
    maxDescriptionWidth,
    variant,
    chip,
    isReportHeader,
    action,
    headerActions,
    headerMarginBottom,
    headerMarginTop,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <header>
      <Box
        mt={isMobile ? 1 : headerMarginTop}
        mb={isReportHeader ? 1 : headerMarginBottom}
        data-testid={dataTestId}
        display='flex'
        justifyContent='flex-start'
      >
        <Ty data-testid='header-text' variant={variant} flexGrow={1}>
          {title}
          {chip ? <Chip color={chip.color} label={chip.label} sx={{ marginLeft: theme.spacing(1) }} /> : null}
        </Ty>
        {headerActions && (
          <Grid justifyContent='flex-end' alignItems='end' gap='16px' display='flex'>
            {headerActions.map((button) => (
              <React.Fragment key={uuidv4()}>{button}</React.Fragment>
            ))}
          </Grid>
        )}
      </Box>
      {(description || additionalDescription || action) && (
        <Grid container>
          <Grid xs={8} item>
            {!isNil(description) ? (
              <Box data-testid='description' sx={{ maxWidth: maxDescriptionWidth }} mb={2}>
                {description}
              </Box>
            ) : null}
            {!isNil(additionalDescription) ? (
              <Box sx={{ maxWidth: maxDescriptionWidth }} mb={2}>
                {additionalDescription}
              </Box>
            ) : null}
          </Grid>
          {!isNil(action) && (
            <Grid item sx={{ marginBottom: 2 }} display='flex' justifyContent='flex-end' alignItems='end' xs={4}>
              <Grid item> {action} </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </header>
  );
}

Header.defaultProps = {
  description: undefined,
  additionalDescription: undefined,
  maxDescriptionWidth: '70%',
  variant: 'h5',
  chip: undefined,
  isReportHeader: false,
  action: null,
  headerActions: undefined,
  headerMarginBottom: 2,
  headerMarginTop: 0,
};

export default Header;
