import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { useUsers } from '../../contexts/userContext';
import { formatDate } from '../../utils';
import { useGetEmployeesQuery } from '../requests/queries';
import { BaseEntity, EmploymentStatus } from '../types';
import LaborUtilities from '../utilities/laborUtilities';

const queryClient = new QueryClient();

function AuditStamp(props: { entity: BaseEntity | undefined | null }) {
  const { t } = useTranslation();
  const user = useUsers();
  const accountPreferences = useAccountPreferences();
  const { entity } = props;
  const isUserAuthenticated = user.fullyAuthenticated === 'authenticated';

  const { data: activeEmployees } = useGetEmployeesQuery(
    isUserAuthenticated,
    {
      employmentStatus: EmploymentStatus.HIRED,
    },
    true
  );
  const { data: terminatedEmployees } = useGetEmployeesQuery(
    isUserAuthenticated,
    {
      employmentStatus: EmploymentStatus.TERMINATED,
    },
    true
  );
  const { data: pendingEmployees } = useGetEmployeesQuery(
    isUserAuthenticated,
    {
      employmentStatus: EmploymentStatus.PENDING,
    },
    true
  );

  const { data: loaEmployees } = useGetEmployeesQuery(
    isUserAuthenticated,
    {
      employmentStatus: EmploymentStatus.LEAVE_OF_ABSENCE,
    },
    true
  );

  const getDisplayDate = useCallback(
    (dateStringToFormat: Date | string | undefined) => {
      if (dateStringToFormat) {
        if (accountPreferences.preferences) {
          return formatDate(dateStringToFormat, accountPreferences.preferences);
        }
        return dayjs(dateStringToFormat).format('MM-DD-YYYY');
      }
      return '-';
    },
    [accountPreferences.preferences]
  );

  const getUpdatedBy = useCallback(
    (stringToFormat: string) =>
      LaborUtilities.getUpdatedBy(
        stringToFormat,
        activeEmployees?.pageContent,
        [...(loaEmployees?.pageContent ?? []), ...(terminatedEmployees?.pageContent ?? [])],
        pendingEmployees?.pageContent,
        t
      ),
    [activeEmployees, terminatedEmployees, pendingEmployees, loaEmployees, t]
  );

  const getLastSaved = () =>
    entity?.updatedAt && entity?.updatedBy
      ? `${t('labor.lastEdit', {
          editTime: getDisplayDate(entity?.updatedAt),
          editBy: getUpdatedBy(entity?.updatedBy),
        })}`
      : '';

  return (
    <QueryClientProvider client={queryClient}>
      <Typography data-testid='audit-stamp' variant='caption' color='secondary'>
        {getLastSaved()}
      </Typography>
    </QueryClientProvider>
  );
}

export default AuditStamp;
