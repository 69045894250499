import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { useTranslation } from 'react-i18next';

export type SearchBarBaseProps = {
  readonly showClearIcon: boolean;
  readonly searchValue: string;
  readonly setSearchValue: (value: string) => void;
};

function SearchBar({ showClearIcon, searchValue, setSearchValue }: SearchBarBaseProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isDarkMode ? theme.palette.grey[800] : '#F5F5F5',
        height: '48px',
        borderRadius: 1,
        paddingLeft: '12px',
        paddingRight: '8px',
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '8px',
        '&:focus-within': {
          border: `2px solid ${theme.palette.primary.dark}`,
        },
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t('globalFilters.multiselect.search')}
        inputProps={{ 'data-testid': 'search-field' }}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />
      {showClearIcon && (
        <IconButton
          onClick={() => setSearchValue('')}
          type='button'
          sx={{
            p: '10px',
            paddingRight: '12px',
            '&:focus-visible': { outlineOffset: '-6px', outline: `2px solid ${theme.palette.primary.dark}` },
          }}
          aria-label='search'
          data-testid='search-close-icon'
        >
          <CancelIcon sx={{ maxHeight: '20px', maxWidth: '20px' }} />
        </IconButton>
      )}
      <Divider sx={{ height: 24, m: 1 }} orientation='vertical' />
      <IconButton
        type='button'
        sx={{ p: '10px', paddingLeft: '8px', paddingRight: '12px' }}
        aria-label='search'
        tabIndex={-1}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  );
}

export default SearchBar;
