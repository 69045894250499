import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export interface LabeledTextProps {
  styles?: SxProps;
  label: string;
  value: string | number;
  dataTestId: string;
  currency?: boolean;
  required?: boolean;
}

export default function LabeledText(props: LabeledTextProps) {
  const { styles, dataTestId, label, value, currency, required } = props;
  const additionalStyles: SxProps = {
    display: 'inline-block',
    verticalAlign: 'middle',
    ...styles,
  };

  return (
    <Grid data-testid={dataTestId} container sx={additionalStyles}>
      <Grid xs={12} item>
        <Typography color='secondary' data-testid={`${dataTestId}-label`}>
          {label}
          {required && '*'}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant='subtitle1' data-testid={`${dataTestId}-value`}>
          {currency && '$'}
          {currency ? Number(value ?? 0).toFixed(2) : value}
        </Typography>
      </Grid>
    </Grid>
  );
}

LabeledText.defaultProps = {
  styles: undefined,
  currency: false,
  required: false,
};
