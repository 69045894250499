import { FilterReportType, SiteInfo } from '@cbo/shared-library';
import { GetGuestTransactionDetailsResponseDto } from '@cbo/shared-library/response/sales.response';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import staleTimeSettings from '../../config/reactQueryConfig';
import { SitesContext, useSites } from '../../contexts/siteContext';
import { BodyOverflowError } from '../types';
import SalesUtilities from '../utilities';
import useSalesRequests, {
  DashboardGraphDataRequest,
  DiscountReportCommonRequest,
  DiscountReportKPICardsRequest,
  GlAccountFiltersResponse,
  GlAccountName,
  GlAccountUpsertInfoResponse,
  GlAccountsRequest,
  GlAccountsResponse,
  GuestCheckRequest,
  GuestCheckResponse,
  HouseAccount,
  HouseAccountActivity,
  HouseAccountStatement,
  HouseAccountSummary,
  KPIDataRequest,
  POSEventsRequest,
  PaymentsComparisonRequestDto,
  PaymentsRequestDto,
  ProductMixEmployeeSalesDataRequest,
  ProductMixMenuGridDataRequest,
  ProductMixTop5ItemsRequest,
  ProductMixTotalSalesRequest,
  ProfitLossGridDataRequest,
  RefundsCardsDataRequest,
  RefundsGridDataRequest,
  RevenueCenterCommonRequest,
  SaleTransaction,
  SalesDefinitionsSettings,
  SalesSummaryDataRequest,
  TaxesRequestDto,
  Transaction,
  TransactionDetailsExportDataRequest,
  VoidsReportCommonRequest,
  VoidsReportKPICardsDataRequest,
  GlAccount,
  TotalSalesByShiftResponseDto,
  GetAllJournalEntryRequest,
  GetAllJournalEntryResponse,
  GetOneJournalEntryResponse,
} from './requests';

const useTransactionQuery = (): {
  setTransactionId: Dispatch<SetStateAction<string>>;
  transaction: Transaction | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
} => {
  const { getTransaction } = useSalesRequests();
  const [transactionId, setTransactionId] = useState('');
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data } = useQuery(
    ['house-accounts', 'transactions', transactionId],
    (): Promise<Transaction | undefined> => getTransaction(transactionId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: !!transactionId && enabled,
    }
  );

  return { transaction: data, setTransactionId, setEnabled };
};

const useFiltersQuery = (filterType: FilterReportType) => {
  const { getFilterValues } = useSalesRequests();
  const { sites } = useSites();
  const siteIds = Object.values(sites).map((site: SiteInfo) => site.enterpriseUnitId);

  const { data, isLoading } = useQuery(
    ['filters', filterType],
    () =>
      getFilterValues({
        siteIds,
        filterType,
      }),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: siteIds.length > 0,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    loading: isLoading,
  };
};

const useBslGetDaypartFilters = () => {
  const { getDaypartFiltersBslRequest } = useSalesRequests();
  const { multiSelectedSites } = useSites();
  const siteIds = multiSelectedSites.map((site: SiteInfo) => site.enterpriseUnitId);

  const { data, isLoading } = useQuery(
    ['daypart-filters', siteIds.sort((a, b) => a.localeCompare(b))],
    () => getDaypartFiltersBslRequest(siteIds),
    {
      useErrorBoundary: false,
      staleTime: staleTimeSettings.HIGH,
      enabled: siteIds.length > 0,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    isLoading,
  };
};

const useSaleTransactionQuery = (): {
  setSaleTransactionId: Dispatch<SetStateAction<string>>;
  saleTransaction: SaleTransaction | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
} => {
  const { getSaleTransaction } = useSalesRequests();
  const [saleTransactionId, setSaleTransactionId] = useState('');
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data: saleTransaction } = useQuery(
    ['house-accounts', 'saleTransaction', saleTransactionId],
    (): Promise<SaleTransaction | undefined> => getSaleTransaction(saleTransactionId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: !!saleTransactionId && enabled,
    }
  );

  return { saleTransaction, setSaleTransactionId, setEnabled };
};

export interface GuestTransactionContext {
  transactionId: string;
  businessDate: string;
}

const useGuestTransactionQuery = (): {
  setGuestTransactionContext: Dispatch<SetStateAction<GuestTransactionContext>>;
  guestTransaction: GetGuestTransactionDetailsResponseDto | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
} => {
  const { getGuestTransaction } = useSalesRequests();
  const [guestTransactionContext, setGuestTransactionContext] = useState({
    transactionId: '',
    businessDate: '',
  } as GuestTransactionContext);
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data: guestTransaction } = useQuery(
    ['guest-transactions', guestTransactionContext.businessDate, guestTransactionContext.transactionId],
    async (): Promise<GetGuestTransactionDetailsResponseDto | undefined> =>
      getGuestTransaction(guestTransactionContext.transactionId, guestTransactionContext.businessDate),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: !!guestTransactionContext.transactionId && !!guestTransactionContext.businessDate && enabled,
      useErrorBoundary: false,
    }
  );

  return { guestTransaction, setGuestTransactionContext, setEnabled };
};

const useAllGuestTransactionsQuery = (): {
  setAllGuestTransactionsContext: Dispatch<SetStateAction<GuestCheckRequest>>;
  transactions: GuestCheckResponse[] | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  isError: boolean;
} => {
  const { getAllGuestChecks } = useSalesRequests();
  const [allGuestTransactionsContext, setAllGuestTransactionsContext] = useState({
    siteIds: [],
    startDate: '',
    endDate: '',
    hasComps: '',
    hasPromos: '',
    hasRefunds: '',
    hasVoids: '',
  } as GuestCheckRequest);
  const [enabled, setEnabled] = useState<boolean>(true);

  const {
    data: transactions,
    isLoading,
    isError,
  } = useQuery(
    [
      'guest-transactions',
      allGuestTransactionsContext.siteIds?.sort((a, b) => a.localeCompare(b)),
      allGuestTransactionsContext.startDate,
      allGuestTransactionsContext.endDate,
      allGuestTransactionsContext.hasComps,
      allGuestTransactionsContext.hasPromos,
      allGuestTransactionsContext.hasRefunds,
      allGuestTransactionsContext.hasVoids,
    ],
    async (): Promise<GuestCheckResponse[] | undefined> => getAllGuestChecks(allGuestTransactionsContext),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled:
        !!allGuestTransactionsContext.siteIds &&
        !!allGuestTransactionsContext.startDate &&
        !!allGuestTransactionsContext.endDate &&
        !!allGuestTransactionsContext.hasComps &&
        !!allGuestTransactionsContext.hasPromos &&
        !!allGuestTransactionsContext.hasRefunds &&
        !!allGuestTransactionsContext.hasVoids &&
        enabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { transactions, setAllGuestTransactionsContext, setEnabled, isLoading, isError };
};

const useHouseAccountQuery = (): {
  setHouseAccountId: Dispatch<SetStateAction<string>>;
  houseAccount: HouseAccount | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
} => {
  const { getHouseAccount } = useSalesRequests();
  const [houseAccountId, setHouseAccountId] = useState('');
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data, isLoading } = useQuery(
    ['house-accounts', houseAccountId],
    (): Promise<HouseAccount | undefined> => getHouseAccount(houseAccountId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: !!houseAccountId && enabled,
    }
  );

  return { houseAccount: data, isLoading, setHouseAccountId, setEnabled };
};

const useHouseAccountActivitiesQuery = (): {
  setHouseAccountActivitiesId: Dispatch<SetStateAction<string>>;
  houseAccountActivities: HouseAccountActivity[] | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
} => {
  const { getHouseAccountsActivities } = useSalesRequests();
  const [houseAccountActivitiesId, setHouseAccountActivitiesId] = useState('');
  const [enabled, setEnabled] = useState<boolean>(true);

  const { data, isLoading } = useQuery(
    ['house-accounts', 'activities', houseAccountActivitiesId],
    (): Promise<HouseAccountActivity[]> => getHouseAccountsActivities(houseAccountActivitiesId),
    {
      staleTime: staleTimeSettings.NONE,
      enabled: !!houseAccountActivitiesId && enabled,
    }
  );

  return { houseAccountActivities: data, isLoading, setHouseAccountActivitiesId, setEnabled };
};

const useHouseAccountSummaryQuery = (
  organizationId?: string
): {
  houseAccountSummaries: HouseAccountSummary[] | undefined;
  isLoading: boolean;
} => {
  const { getHouseAccountsSummary } = useSalesRequests();

  const { data, isLoading } = useQuery(
    ['house-accounts', 'summary', organizationId],
    (): Promise<HouseAccountSummary[]> => getHouseAccountsSummary(),
    {
      staleTime: staleTimeSettings.HIGH,
    }
  );

  return { houseAccountSummaries: data, isLoading };
};

const useStatementQuery = (
  statementId: string
): {
  statement: HouseAccountStatement | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const { getStatement } = useSalesRequests();

  const { data, isLoading, isError } = useQuery(
    ['house-accounts', 'statements', statementId],
    (): Promise<HouseAccountStatement> => getStatement(statementId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: !!statementId,
    }
  );

  return { statement: data, isLoading, isError };
};

const useGetCalendarFilters = (isAuthenticated: boolean) => {
  const { getCalendarFilters } = useSalesRequests();

  const fiscalCalendarFiltersResponse = useQuery(['fiscal-calendar', 'filters'], () => getCalendarFilters(), {
    staleTime: staleTimeSettings.HIGH,
    enabled: isAuthenticated,
    useErrorBoundary: false,
  });

  return { fiscalCalendarFiltersResponse };
};

const useGetPOSEventLogFilters = () => {
  const { getPOSEventFilters } = useSalesRequests();
  const { sites } = useSites();
  const siteIds = Object.values(sites).map((site: SiteInfo) => site.enterpriseUnitId);

  return useQuery(['filters', 'pos-event-log'], () => getPOSEventFilters({ siteIds }), {
    staleTime: staleTimeSettings.HIGH,
    useErrorBoundary: false,
  });
};

const useGetPOSEvents = () => {
  const { getPOSEvents } = useSalesRequests();

  const [posEventsContext, setPosEventsContext] = useState({
    siteIds: [],
    employeeId: [],
    terminalId: [],
    eventType: [],
    startDate: '',
    endDate: '',
  } as POSEventsRequest);

  const isEnabled = posEventsContext.startDate.length > 0 && posEventsContext.endDate.length > 0;

  const { data, isLoading, isError } = useQuery(
    [
      'pos-events',
      posEventsContext.siteIds?.sort((a, b) => a.localeCompare(b)),
      posEventsContext.employeeId,
      posEventsContext.terminalId,
      posEventsContext.eventType,
      posEventsContext.startDate,
      posEventsContext.endDate,
    ],
    () => getPOSEvents(posEventsContext),
    {
      staleTime: staleTimeSettings.HIGH,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
      enabled: isEnabled,
    }
  );

  return { posEventsResponse: data, setPosEventsContext, isLoading, isError };
};

const useSalesSummaryCardsData = () => {
  const { getSalesSummaryCardData } = useSalesRequests();
  const [request, setSalesSummaryRequest] = useState({
    startDate: '',
    endDate: '',
  } as SalesSummaryDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'sales-summary',
      'sales-cards',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
    () => getSalesSummaryCardData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setSalesSummaryRequest,
    loading: isLoading,
  };
};

const useSalesSummaryDataGrid = () => {
  const { getSalesSummaryGridData } = useSalesRequests();
  const [request, setSalesSummaryDataGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as SalesSummaryDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'sales-summary',
      'sales-grid',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
    () => getSalesSummaryGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setSalesSummaryDataGridRequest,
    loading: isLoading,
  };
};

const useSalesSummaryPaymentsDataGrid = () => {
  const { getSalesSummaryPaymentsGridData } = useSalesRequests();
  const [request, setSalesSummaryPaymentsDataGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as SalesSummaryDataRequest);
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'sales-summary',
      'payments-grid',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
    () => getSalesSummaryPaymentsGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setSalesSummaryPaymentsDataGridRequest,
    loading: isLoading,
  };
};

const useProductMixTop5Items = () => {
  const { getProductMixTop5Items } = useSalesRequests();

  const [productMixRequest, setProductMixTop5ItemsRequest] = useState({
    startDate: '',
    endDate: '',
  } as ProductMixTop5ItemsRequest);

  const isEnabled =
    productMixRequest.startDate.length > 0 &&
    productMixRequest.endDate.length > 0 &&
    productMixRequest.startDate !== 'Invalid Date' &&
    productMixRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'productmix',
      'top-items',
      productMixRequest.startDate,
      productMixRequest.endDate,
      productMixRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      productMixRequest.categories,
      productMixRequest.items,
    ],
    () => getProductMixTop5Items(productMixRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    productMixTop5ItemsResponse: data,
    setProductMixTop5ItemsRequest,
    isProductMixTop5ItemsLoading: isLoading,
  };
};

const useProductMixTotalSales = () => {
  const { getProductMixTotalSales } = useSalesRequests();

  const [productMixRequest, setProductMixTotalSalesRequest] = useState({
    startDate: '',
    endDate: '',
  } as ProductMixTotalSalesRequest);

  const isEnabled =
    productMixRequest.startDate.length > 0 &&
    productMixRequest.endDate.length > 0 &&
    productMixRequest.startDate !== 'Invalid Date' &&
    productMixRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'productmix',
      'total-sales',
      productMixRequest.startDate,
      productMixRequest.endDate,
      productMixRequest.previousStartDate,
      productMixRequest.previousEndDate,
      productMixRequest.selectionType,
      productMixRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      productMixRequest.categories,
      productMixRequest.items,
    ],
    () => getProductMixTotalSales(productMixRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );
  return {
    productMixTotalSalesResponse: data,
    setProductMixTotalSalesRequest,
    isProductMixTotalSalesLoading: isLoading,
  };
};

// Function to get common query keys for void report requests
function getVoidReportRequestQueryKeys(request: VoidsReportCommonRequest, apiPath: string) {
  return [
    'voids',
    apiPath,
    request.startDate,
    request.endDate,
    request.siteIds?.sort((a, b) => a.localeCompare(b)),
    request.voidReasons,
    request.employees,
  ];
}

const useGetVoidReportGridData = () => {
  const { getVoidsReportGridData } = useSalesRequests();
  const [request, setVoidReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as VoidsReportCommonRequest);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getVoidReportRequestQueryKeys(request, 'grid-data'),
    () => getVoidsReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    voidReportGridDataResponse: data,
    setVoidReportGridDataRequest,
    isGetVoidReportGridDataLoading: isLoading,
  };
};

const useGetVoidReportTop5ReasonsData = () => {
  const { getVoidsReportTop5ReasonsData } = useSalesRequests();
  const [request, setVoidReportTop5ReasonsRequest] = useState({
    startDate: '',
    endDate: '',
  } as VoidsReportCommonRequest);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getVoidReportRequestQueryKeys(request, 'top-void-reasons'),
    () => getVoidsReportTop5ReasonsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    voidReportTop5ReasonsDataResponse: data,
    setVoidReportTop5ReasonsRequest,
    isGetVoidReportTop5ReasonsDataLoading: isLoading,
  };
};

const useGetVoidReportKPICardsData = () => {
  const { getVoidsReportKPICardsData } = useSalesRequests();
  const [request, setVoidReportKPICardsDataRequest] = useState({
    startDate: '',
    endDate: '',
    previousStartDate: '',
    previousEndDate: '',
  } as VoidsReportKPICardsDataRequest);

  const isEnabled = dayjs(request.previousEndDate).diff(request.previousStartDate, 'day') >= 0;

  const { data, isLoading } = useQuery(
    [
      'voids',
      'kpi-cards',
      request.startDate,
      request.endDate,
      request.previousStartDate,
      request.previousEndDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.voidReasons,
      request.employees,
    ],
    () => getVoidsReportKPICardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    voidReportKPICardsDataResponse: data,
    setVoidReportKPICardsDataRequest,
    isGetVoidReportKPICardsDataLoading: isLoading,
  };
};

const useGetProductMixMenuGridData = () => {
  const { getProductMixMenuGridData } = useSalesRequests();

  const [productMixRequest, setProductMixMenuRequest] = useState({
    startDate: '',
    endDate: '',
  } as ProductMixMenuGridDataRequest);

  const isEnabled =
    productMixRequest.startDate.length > 0 &&
    productMixRequest.endDate.length > 0 &&
    productMixRequest.startDate !== 'Invalid Date' &&
    productMixRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'productmix',
      'grid-data',
      productMixRequest.startDate,
      productMixRequest.endDate,
      productMixRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      productMixRequest.categories,
      productMixRequest.items,
    ],
    () => getProductMixMenuGridData(productMixRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    productMixMenuGridDataResponse: data,
    setProductMixMenuRequest,
    isProductMixMenuDataLoading: isLoading,
  };
};

const useGetProductMixTop5Sellers = () => {
  const { getProductMixTop5Sellers } = useSalesRequests();

  const [productMixRequest, setProductMixTop5SellersRequest] = useState({
    startDate: '',
    endDate: '',
  } as ProductMixEmployeeSalesDataRequest);

  const isEnabled =
    productMixRequest.startDate.length > 0 &&
    productMixRequest.endDate.length > 0 &&
    productMixRequest.startDate !== 'Invalid Date' &&
    productMixRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'productmix',
      'top-sellers',
      productMixRequest.startDate,
      productMixRequest.endDate,
      productMixRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      productMixRequest.categories,
      productMixRequest.items,
      productMixRequest.employees,
    ],
    () => getProductMixTop5Sellers(productMixRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    top5SellersResponse: data,
    setProductMixTop5SellersRequest,
    top5SellersLoading: isLoading,
  };
};

const useGetProductMixEmployeeSalesGridData = () => {
  const { getProductMixEmployeeSalesGrid } = useSalesRequests();

  const [productMixRequest, setProductMixEmployeeSalesGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as ProductMixEmployeeSalesDataRequest);

  const isEnabled =
    productMixRequest.startDate.length > 0 &&
    productMixRequest.endDate.length > 0 &&
    productMixRequest.startDate !== 'Invalid Date' &&
    productMixRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'productmix',
      'employee-sales-grid-data',
      productMixRequest.startDate,
      productMixRequest.endDate,
      productMixRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      productMixRequest.categories,
      productMixRequest.items,
      productMixRequest.employees,
    ],
    () => getProductMixEmployeeSalesGrid(productMixRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    employeeSalesGridDataResponse: data,
    setProductMixEmployeeSalesGridRequest,
    employeeSalesGridDataLoading: isLoading,
  };
};

const useGetRefundsGridData = () => {
  const { getRefundsGridData } = useSalesRequests();

  const [refundsGridDataRequest, setRefundsGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as RefundsGridDataRequest);

  const isEnabled =
    refundsGridDataRequest.startDate.length > 0 &&
    refundsGridDataRequest.endDate.length > 0 &&
    refundsGridDataRequest.startDate !== 'Invalid Date' &&
    refundsGridDataRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'refunds',
      'grid-data',
      refundsGridDataRequest.startDate,
      refundsGridDataRequest.endDate,
      refundsGridDataRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      refundsGridDataRequest.employees,
    ],
    () => getRefundsGridData(refundsGridDataRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    refundDataGridResponse: data,
    setRefundsGridDataRequest,
    isRefundDataGridLoading: isLoading,
  };
};

const useGetRefundsCardsData = () => {
  const { getRefundsCardsData } = useSalesRequests();

  const [refundCardsDataRequest, setRefundCardsDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as RefundsCardsDataRequest);

  const isEnabled =
    refundCardsDataRequest.startDate.length > 0 &&
    refundCardsDataRequest.endDate.length > 0 &&
    refundCardsDataRequest.startDate !== 'Invalid Date' &&
    refundCardsDataRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'refunds',
      'kpi-cards',
      refundCardsDataRequest.startDate,
      refundCardsDataRequest.endDate,
      refundCardsDataRequest.previousStartDate,
      refundCardsDataRequest.previousEndDate,
      refundCardsDataRequest.siteIds?.sort((a, b) => a.localeCompare(b)),
      refundCardsDataRequest.employees,
    ],
    () => getRefundsCardsData(refundCardsDataRequest),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    refundCardsDataResponse: data,
    setRefundCardsDataRequest,
    isRefundCardsDataLoading: isLoading,
  };
};

const useGetKPIData = () => {
  const { getKPIData2 } = useSalesRequests();
  const { selectedSite } = useContext(SitesContext);

  const [request, setRequest] = useState({
    startDate: '',
    endDate: '',
    currentDate: '',
    metricsRequested: [],
  } as KPIDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'dashboard',
      'getkpi',
      request.startDate,
      request.endDate,
      request.currentDate,
      request.metricsRequested,
      selectedSite.enterpriseUnitId,
    ],
    () => getKPIData2(request),
    {
      staleTime: staleTimeSettings.LOW,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    kpiData: data,
    setRequest,
    isKPIDataLoading: isLoading,
  };
};

const useTransactionsDetailsExportDataGrid = () => {
  const { getTransactionsDetailsExportGridData } = useSalesRequests();
  const [request, setTransactionsDetailsExportDataGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as TransactionDetailsExportDataRequest);
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'transactions-export-view',
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
    () => getTransactionsDetailsExportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setTransactionsDetailsExportDataGridRequest,
    loading: isLoading,
  };
};

const useGetDashboardGraphData = () => {
  const { getDashboardGraphData } = useSalesRequests();
  const { selectedSite } = useContext(SitesContext);

  const [graphRequest, setGraphRequest] = useState({
    startDate: '',
    endDate: '',
  } as DashboardGraphDataRequest);

  const isEnabled =
    graphRequest.startDate.length > 0 &&
    graphRequest.endDate.length > 0 &&
    graphRequest.startDate !== 'Invalid Date' &&
    graphRequest.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    ['dashboard', 'graph-data', graphRequest.startDate, graphRequest.endDate, selectedSite.enterpriseUnitId],
    () => getDashboardGraphData(graphRequest),
    {
      staleTime: staleTimeSettings.LOW,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    graphDataResponse: data,
    setGraphRequest,
    isGraphDataLoading: isLoading,
  };
};

const useGlAccountsQuery = (
  organizationId?: string
): {
  setGlAccountsContext: Dispatch<SetStateAction<GlAccountsRequest>>;
  glAccounts: GlAccountsResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  refetchGlAccounts: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GlAccountsResponse | undefined, BodyOverflowError>>;
} => {
  const { getGlAccounts } = useSalesRequests();
  const [glAccountsContext, setGlAccountsContext] = useState({
    isActive: '',
    categoryIds: [],
    accountTypes: [],
    accountDetailTypes: [],
  } as GlAccountsRequest);

  const {
    data: glAccounts,
    isLoading,
    isError,
    refetch: refetchGlAccounts,
  } = useQuery(
    [
      'gl-accounts',
      glAccountsContext.isActive,
      glAccountsContext.categoryIds,
      glAccountsContext.accountTypes,
      glAccountsContext.accountDetailTypes,
      organizationId,
    ],
    (): Promise<GlAccountsResponse | undefined> => getGlAccounts(glAccountsContext),
    {
      staleTime: staleTimeSettings.HIGH,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { glAccounts, setGlAccountsContext, isLoading, isError, refetchGlAccounts };
};

const useGetGlAccountFilters = () => {
  const { getGlAccountFilters } = useSalesRequests();

  const { data, isLoading, isError } = useQuery(
    ['gl-accounts', 'filters'],
    (): Promise<GlAccountFiltersResponse | undefined> => getGlAccountFilters(),
    { staleTime: staleTimeSettings.HIGH, retry: SalesUtilities.shouldRetry }
  );

  return {
    glAccountFilters: data,
    isGetGlAccountFiltersDataLoading: isLoading,
    isGetGlAccountFiltersError: isError,
  };
};

const useGetGlAccountUpsertInfo = (): {
  upsertInfo: GlAccountUpsertInfoResponse | undefined;
  refetchUpsertInfo: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GlAccountUpsertInfoResponse | undefined, BodyOverflowError>>;
} => {
  const { getGlAccountUpsertInfo } = useSalesRequests();

  const { data: upsertInfo, refetch: refetchUpsertInfo } = useQuery(
    ['gl-accounts', 'upsert-info'],
    (): Promise<GlAccountUpsertInfoResponse | undefined> => getGlAccountUpsertInfo(),
    { staleTime: staleTimeSettings.HIGH, retry: SalesUtilities.shouldRetry }
  );

  return { upsertInfo, refetchUpsertInfo };
};

const useGetAllGlAccountNames = (): {
  glAccountNames: GlAccountName[] | undefined;
  isLoading: boolean;
} => {
  const { getAllGlAccountNames } = useSalesRequests();

  const { data: glAccountNames, isLoading } = useQuery(
    ['gl-accounts', 'names'],
    (): Promise<GlAccountName[] | undefined> => getAllGlAccountNames(),
    { staleTime: staleTimeSettings.HIGH, retry: SalesUtilities.shouldRetry }
  );

  return { glAccountNames, isLoading };
};

export const useGlAccountByIdQuery = (glAccountId: string): UseQueryResult<GlAccount> => {
  const { getGlAccountById } = useSalesRequests();

  return useQuery(['gl-accounts', glAccountId], () => getGlAccountById(glAccountId), {
    enabled: glAccountId.length > 0,
  });
};

const useGetSalesDefinitionSettings = (
  nepOrganizationId?: string
): {
  salesDefinitionSettings: SalesDefinitionsSettings | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const { getSalesDefinitionsSettings } = useSalesRequests();

  const {
    data: salesDefinitionSettings,
    isLoading,
    isError,
  } = useQuery(
    ['sales-definitions-settings', nepOrganizationId],
    (): Promise<SalesDefinitionsSettings> => getSalesDefinitionsSettings(),
    {
      staleTime: staleTimeSettings.HIGH,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { salesDefinitionSettings, isLoading, isError };
};

const useGetProfitLossGridData = () => {
  const { getProfitLossGridData } = useSalesRequests();
  const [request, setProfitLossGridDataRequest] = useState({
    startDate: '',
    endDate: '',
    comparisonStartDate: '',
    comparisonEndDate: '',
  } as ProfitLossGridDataRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'profit-loss',
      'grid-data',
      request.startDate,
      request.endDate,
      request.comparisonStartDate,
      request.comparisonEndDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
    () => getProfitLossGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { data, setProfitLossGridDataRequest, isLoading };
};

const useTotalTaxesQuery = () => {
  const { getTotalTaxesData } = useSalesRequests();
  const [request, setTotalTaxesRequest] = useState({
    startDate: '',
    endDate: '',
  } as TaxesRequestDto);
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'taxes',
      'total-taxes',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.taxNames?.sort(),
    ],
    () => getTotalTaxesData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setTotalTaxesRequest,
    loading: isLoading,
  };
};

const useTaxesGridQuery = () => {
  const { getTaxesGridData } = useSalesRequests();
  const [request, setTaxesGridRequest] = useState({
    startDate: '',
    endDate: '',
  } as TaxesRequestDto);
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'taxes',
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.taxNames?.sort(),
    ],
    () => getTaxesGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setTaxesGridRequest,
    loading: isLoading,
  };
};

const useTaxesByTypeQuery = () => {
  const { getTaxesByTypeData } = useSalesRequests();
  const [request, setTaxesByTypeRequest] = useState({
    startDate: '',
    endDate: '',
  } as TaxesRequestDto);
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'taxes',
      'taxes-by-type',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.taxNames?.sort(),
    ],
    () => getTaxesByTypeData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    data,
    setTaxesByTypeRequest,
    loading: isLoading,
  };
};

// Function to get common query keys for discount report requests
function getDiscountReportRequestQueryKeys(request: DiscountReportCommonRequest, apiPath: string) {
  return [
    'discounts',
    apiPath,
    request.startDate,
    request.endDate,
    request.siteIds?.sort((a, b) => a.localeCompare(b)),
    request.approvedBy?.sort(),
    request.discountNames?.sort(),
    request.employees?.sort(),
  ];
}

const useGetDiscountReportKPICardsData = () => {
  const { getDiscountReportKPICardsData } = useSalesRequests();
  const [request, setGetDiscountReportKPICardsRequest] = useState({
    startDate: '',
    endDate: '',
    previousStartDate: '',
    previousEndDate: '',
  } as DiscountReportKPICardsRequest);

  const isEnabled = dayjs(request.previousEndDate).diff(request.previousStartDate, 'day') >= 0;

  const { data, isLoading } = useQuery(
    [
      'discounts',
      'kpi-cards',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.approvedBy?.sort(),
      request.discountNames?.sort(),
      request.employees?.sort(),
      request.previousStartDate,
      request.previousEndDate,
    ],
    () => getDiscountReportKPICardsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    discountReportKPICardsData: data,
    setGetDiscountReportKPICardsRequest,
    isGetDiscountReportKPICardsLoading: isLoading,
  };
};

const useGetDiscountReportTop5DiscountsData = () => {
  const { getDiscountReportTop5DiscountsData } = useSalesRequests();
  const [request, setGetDiscountReportTop5DiscountsRequest] = useState({
    startDate: '',
    endDate: '',
  } as DiscountReportCommonRequest);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getDiscountReportRequestQueryKeys(request, 'top-discounts'),
    () => getDiscountReportTop5DiscountsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    discountReportTop5DiscountsData: data,
    setGetDiscountReportTop5DiscountsRequest,
    isGetDiscountReportTop5DiscountsLoading: isLoading,
  };
};

const useGetDiscountReportGridData = () => {
  const { getDiscountReportGridData } = useSalesRequests();
  const [request, setGetDiscountReportGridDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as DiscountReportCommonRequest);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getDiscountReportRequestQueryKeys(request, 'grid-data'),
    () => getDiscountReportGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    discountReportGridData: data,
    setGetDiscountReportGridDataRequest,
    isGetDiscountReportGridDataLoading: isLoading,
  };
};

const useGetTotalPaymentsData = () => {
  const { getTotalPaymentsData } = useSalesRequests();
  const [request, setGetTotalPaymentsData] = useState({
    startDate: '',
    endDate: '',
    previousStartDate: '',
    previousEndDate: '',
  } as PaymentsComparisonRequestDto);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'payments',
      'total-payments',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.paymentTypes?.sort(),
      request.employees?.sort(),
      request.previousStartDate,
      request.previousEndDate,
    ],
    () => getTotalPaymentsData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    totalPaymentsData: data,
    setGetTotalPaymentsData,
    isGetTotalPaymentsLoading: isLoading,
  };
};

const useGetPaymentsByTypeData = () => {
  const { getPaymentsByTypeData } = useSalesRequests();
  const [request, setGetPaymentsByTypeData] = useState({
    startDate: '',
    endDate: '',
  } as PaymentsRequestDto);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'payments',
      'payments-by-type',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.paymentTypes?.sort(),
      request.employees?.sort(),
    ],
    () => getPaymentsByTypeData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    paymentsByTypeData: data,
    setGetPaymentsByTypeData,
    isGetPaymentsByTypeLoading: isLoading,
  };
};

const useGetPaymentsGridData = () => {
  const { getPaymentsGridData } = useSalesRequests();
  const [request, setGetPaymentsGridData] = useState({
    startDate: '',
    endDate: '',
  } as PaymentsRequestDto);

  /* istanbul ignore next */
  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    [
      'payments',
      'grid',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.paymentTypes?.sort(),
      request.employees?.sort(),
    ],
    () => getPaymentsGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    paymentsGridData: data,
    setGetPaymentsGridData,
    isGetPaymentsGridLoading: isLoading,
  };
};

// Function to get common query keys for discount report requests
function getRevenueCenterQueryKey(request: RevenueCenterCommonRequest, apiPath: string) {
  return [
    'revenue-centers',
    apiPath,
    request.startDate,
    request.endDate,
    request.siteIds?.sort((a, b) => a.localeCompare(b)),
    request.dayparts?.sort(),
    request.revenueCenters?.sort(),
  ];
}

const useGetSalesByRevenueCenterData = () => {
  const { getSalesByRevenueCenterData } = useSalesRequests();
  const [request, setGetSalesByRevenueCenterDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as RevenueCenterCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getRevenueCenterQueryKey(request, 'sales-by-revenue-center'),
    () => getSalesByRevenueCenterData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    salesByRevenueCenterResults: data,
    setGetSalesByRevenueCenterDataRequest,
    isGetSalesByRevenueCenterDataLoading: isLoading,
  };
};

const useGetPerPersonAverageByRevenueCenterData = () => {
  const { getPerPersonAverageByRevenueCenterData } = useSalesRequests();
  const [request, setGetPerPersonAverageByRevenueCenterDataRequest] = useState({
    startDate: '',
    endDate: '',
  } as RevenueCenterCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getRevenueCenterQueryKey(request, 'per-person-average-by-revenue-center'),
    () => getPerPersonAverageByRevenueCenterData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    perPersonAverageByRevenueCenterResults: data,
    setGetPerPersonAverageByRevenueCenterDataRequest,
    isGetPerPersonAverageByRevenueCenterDataLoading: isLoading,
  };
};

const useGetRevenueCenterGridData = () => {
  const { getRevenueCenterGridData } = useSalesRequests();
  const [request, setGetRevenueCenterGridDataRequest] = useState({
    startDate: '',
    endDate: '',
    siteIds: [],
  } as RevenueCenterCommonRequest);

  const isEnabled =
    request.startDate.length > 0 &&
    request.endDate.length > 0 &&
    request.startDate !== 'Invalid Date' &&
    request.endDate !== 'Invalid Date';

  const { data, isLoading } = useQuery(
    getRevenueCenterQueryKey(request, 'grid'),
    () => getRevenueCenterGridData(request),
    {
      staleTime: staleTimeSettings.MEDIUM,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return {
    revenueCenterGridResults: data,
    setGetRevenueCenterGridDataRequest,
    isGetRevenueCenterGridDataLoading: isLoading,
  };
};

const useGetTotalSalesByShift = (
  date: string
): {
  totalDayNetSalesDataByShift: TotalSalesByShiftResponseDto[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const { getTotalSalesByShift } = useSalesRequests();
  const { sites } = useSites();

  const {
    data: totalSalesByShift,
    isLoading,
    isError,
  } = useQuery(
    ['punch-summary', 'total-sales', sites.enterpriseUnitId, date, sites.timeZone],
    (): Promise<TotalSalesByShiftResponseDto[]> => getTotalSalesByShift(date),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: true,
      keepPreviousData: true,
      retry: SalesUtilities.shouldRetry,
      enabled: date?.length > 0,
    }
  );

  return { totalDayNetSalesDataByShift: totalSalesByShift, isLoading, isError };
};

/**
 * Journal Entry
 */
const useGetAllJournalEntryQuery = (
  organizationId?: string
): {
  setJournalEntryContext: Dispatch<SetStateAction<GetAllJournalEntryRequest>>;
  journalEntries: GetAllJournalEntryResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  refetchJournalEntries: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GetAllJournalEntryResponse | undefined, BodyOverflowError>>;
} => {
  const { getAllJournalEntries } = useSalesRequests();
  const [journalEntryContext, setJournalEntryContext] = useState({
    startDate: '',
    endDate: '',
  } as GetAllJournalEntryRequest);

  const { startDate, endDate, siteIds, frequencyTypes, glAccountIds } = journalEntryContext;

  const isEnabled =
    startDate.length > 0 && endDate.length > 0 && startDate !== 'Invalid Date' && endDate !== 'Invalid Date';

  const {
    data: journalEntries,
    isLoading,
    isError,
    refetch: refetchJournalEntries,
  } = useQuery(
    ['journal-entry', 'get-all', organizationId, startDate, endDate, siteIds, glAccountIds, frequencyTypes],
    () => getAllJournalEntries(journalEntryContext),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { journalEntries, setJournalEntryContext, isLoading, isError, refetchJournalEntries };
};

const useGetOneJournalEntryQuery = (
  organizationId?: string
): {
  journalEntry: GetOneJournalEntryResponse | undefined;
  setJournalEntryId: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  isError: boolean;
} => {
  const { getOneJournalEntry } = useSalesRequests();
  const [journalEntryId, setJournalEntryId] = useState('');

  const isEnabled = !!organizationId && journalEntryId.length > 0;

  const {
    data: journalEntry,
    isLoading,
    isError,
  } = useQuery(
    ['journal-entry', 'get-one', journalEntryId, organizationId],
    (): Promise<GetOneJournalEntryResponse | undefined> => getOneJournalEntry(journalEntryId),
    {
      staleTime: staleTimeSettings.HIGH,
      enabled: isEnabled,
      useErrorBoundary: false,
      retry: SalesUtilities.shouldRetry,
    }
  );

  return { journalEntry, setJournalEntryId, isLoading, isError };
};

export {
  useAllGuestTransactionsQuery,
  useBslGetDaypartFilters,
  useFiltersQuery,
  useGetAllGlAccountNames,
  useGetCalendarFilters,
  useGetDashboardGraphData,
  useGetDiscountReportGridData,
  useGetDiscountReportKPICardsData,
  useGetDiscountReportTop5DiscountsData,
  useGetGlAccountFilters,
  useGetGlAccountUpsertInfo,
  useGetKPIData,
  useGetPOSEventLogFilters,
  useGetPOSEvents,
  useGetPaymentsByTypeData,
  useGetPaymentsGridData,
  useGetPerPersonAverageByRevenueCenterData,
  useGetProductMixEmployeeSalesGridData,
  useGetProductMixMenuGridData,
  useGetProductMixTop5Sellers,
  useGetProfitLossGridData,
  useGetRefundsCardsData,
  useGetRefundsGridData,
  useGetRevenueCenterGridData,
  useGetSalesByRevenueCenterData,
  useGetSalesDefinitionSettings,
  useGetTotalPaymentsData,
  useGetVoidReportGridData,
  useGetVoidReportKPICardsData,
  useGetVoidReportTop5ReasonsData,
  useGlAccountsQuery,
  useGuestTransactionQuery,
  useHouseAccountActivitiesQuery,
  useHouseAccountQuery,
  useHouseAccountSummaryQuery,
  useProductMixTop5Items,
  useProductMixTotalSales,
  useSaleTransactionQuery,
  useSalesSummaryCardsData,
  useSalesSummaryDataGrid,
  useSalesSummaryPaymentsDataGrid,
  useStatementQuery,
  useTaxesByTypeQuery,
  useTaxesGridQuery,
  useTotalTaxesQuery,
  useTransactionQuery,
  useTransactionsDetailsExportDataGrid,
  useGetTotalSalesByShift,
  useGetAllJournalEntryQuery,
  useGetOneJournalEntryQuery,
};
