import dayjs from 'dayjs';
import React from 'react';
import { ShiftInfo } from '../Scheduling/types';

// Helper function for returning good format of time only so that SR can read it nicely (ex.'8:20 PM' instead of '08:20p')
const getReadableTimeFormatFromShiftEventLabel = (str: string): string => {
  const trimmedStr = str.trim();
  // Transforming string ex. '08:15p' to '08:15 PM'
  const readableFormat = `${trimmedStr.slice(0, trimmedStr.length - 1)} ${trimmedStr
    .slice(trimmedStr.length - 1)
    .toUpperCase()}M`;

  return readableFormat;
};

const getEmployeeCellInTheSameRow = (shiftCell: HTMLElement): Element | undefined => {
  const employeeCell = document?.querySelector(
    `.calendar-custom-employee-name[data-resource-id="${shiftCell.dataset.resourceId}"]`
  ) as HTMLElement;

  return employeeCell;
};

const getAllShiftCellsFromTheSameRow = (element: HTMLElement): NodeListOf<Element> => {
  const allCells = document.querySelectorAll(
    `.calendar-custom-add-shift[data-resource-id='${element.dataset.resourceId}']`
  );

  return allCells;
};

const focusLastShiftCellInTheSameRow = (element: HTMLElement): void => {
  const allShiftCells = getAllShiftCellsFromTheSameRow(element);

  if (allShiftCells.length > 0) {
    (allShiftCells[allShiftCells.length - 1] as HTMLElement).focus();
  }
};

const getFirstShiftEventInSameAddShiftCell = (addShiftDiv: HTMLElement): HTMLElement | null => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${addShiftDiv?.dataset.resourceId}"]`
  );
  const firstShiftEventForDesiredDate = Array.from(shiftEventsForEmployee).find((shiftEvent) => {
    if (addShiftDiv.dataset.shiftDate)
      return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(addShiftDiv.dataset.shiftDate.split('T')[0]);
    return false;
  });

  return firstShiftEventForDesiredDate as HTMLElement;
};

const focusFirstShiftCellInTheSameRow = (element: HTMLElement): void => {
  const allShiftCells = getAllShiftCellsFromTheSameRow(element);
  if (allShiftCells.length > 0) {
    const shiftEventToBeFocused = getFirstShiftEventInSameAddShiftCell(allShiftCells[0] as HTMLElement);
    (shiftEventToBeFocused ?? (allShiftCells[0] as HTMLElement)).focus();
  }
};

const getAdjacentEmployeeCell = (employeeCell: HTMLElement, direction: 'up' | 'down'): HTMLElement | undefined => {
  const allEmployees = document.querySelectorAll('.calendar-custom-employee-name');
  if (allEmployees.length) {
    const indexOfCurrent = Array.from(allEmployees).findIndex(
      (emp) => (emp as HTMLElement).dataset.resourceId === employeeCell.dataset.resourceId
    );
    if (direction === 'up' && indexOfCurrent !== 0) {
      return allEmployees[indexOfCurrent - 1] as HTMLElement;
    }
    if (direction === 'down' && indexOfCurrent !== allEmployees.length - 1) {
      return allEmployees[indexOfCurrent + 1] as HTMLElement;
    }
  }
  return undefined;
};

const getAddShiftFromShiftEvent = (shiftEvent: HTMLElement): HTMLElement | undefined => {
  const addShiftDivsForEmployee = document.querySelectorAll(
    `.calendar-custom-add-shift[data-resource-id="${shiftEvent.dataset.resourceId}"]`
  );

  const addShiftForDesiredDate = Array.from(addShiftDivsForEmployee as NodeListOf<HTMLElement>).find((addShiftDiv) => {
    if (shiftEvent.dataset.shiftDate)
      return addShiftDiv.dataset.shiftDate?.includes(shiftEvent.dataset.shiftDate.split('T')[0]);
    return false;
  });

  return addShiftForDesiredDate as HTMLElement;
};

const getLastShiftEventInSameAddShiftCell = (addShiftDiv: HTMLElement): HTMLElement | null => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${addShiftDiv?.dataset.resourceId}"]`
  );
  const lastShiftEventForDesiredDate = Array.from(shiftEventsForEmployee)
    .reverse()
    .find((shiftEvent) => {
      if (addShiftDiv?.dataset.shiftDate)
        return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(addShiftDiv.dataset.shiftDate.split('T')[0]);
      return false;
    });

  return lastShiftEventForDesiredDate as HTMLElement;
};

const getAdjacentAddShift = (
  element: HTMLElement,
  direction: 'up' | 'down' | 'left' | 'right',
  isFirstShiftEvent = false
): HTMLElement | undefined => {
  let queryRow = `[data-row-index="${element.dataset.rowIndex}"]`;
  if (direction === 'down' || direction === 'up') {
    queryRow = `[data-row-index="${Number(element.dataset.rowIndex) + (direction === 'down' ? 1 : -1)}"]`;
  }

  let queryCol = `.calendar-custom-add-shift-${element.dataset.index}`;
  if (direction === 'left' || direction === 'right') {
    queryCol = `.calendar-custom-add-shift-${Number(element.dataset.index) + (direction === 'right' ? 1 : -1)}`;
  }

  const addShiftToBeFocused = document?.querySelector(
    `.fc-scrollgrid .calendar-custom-add-shift${queryCol}${queryRow}`
  ) as HTMLElement;

  if (direction === 'up' && !isFirstShiftEvent) {
    const shiftEventToBeFocused = getLastShiftEventInSameAddShiftCell(element);
    return shiftEventToBeFocused ?? addShiftToBeFocused;
  }

  const shiftEventToBeFocused = getFirstShiftEventInSameAddShiftCell(addShiftToBeFocused);
  return shiftEventToBeFocused ?? addShiftToBeFocused;
};

const getAdjacentShift = (element: HTMLElement, direction: 'before' | 'after'): HTMLElement | undefined => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${element.dataset.resourceId}"]`
  );
  const shiftsForSameDayOnly = Array.from(shiftEventsForEmployee as NodeListOf<HTMLElement>).filter((shiftEvent) => {
    if (element.dataset.shiftDate && shiftEvent.dataset.shiftDate)
      return shiftEvent.dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
    return false;
  });

  let desiredShiftEvent: HTMLElement | undefined;
  if (direction === 'after') {
    desiredShiftEvent = shiftsForSameDayOnly.find((shiftEvent) => {
      if (element.dataset.shiftDate && shiftEvent.dataset.shiftDate)
        // return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
        return dayjs(shiftEvent.dataset.shiftDate).diff(dayjs(element.dataset.shiftDate)) > 0;
      return false;
    });
  }

  if (direction === 'before') {
    desiredShiftEvent = shiftsForSameDayOnly.reverse().find((shiftEvent) => {
      if (element.dataset.shiftDate && (shiftEvent as HTMLElement).dataset.shiftDate)
        // return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
        return dayjs((shiftEvent as HTMLElement).dataset.shiftDate).diff(dayjs(element.dataset.shiftDate)) < 0;
      return false;
    }) as HTMLElement;
  }
  return desiredShiftEvent;
};

const shiftsTableMoveLeft = (element: HTMLElement) => {
  const left = getAdjacentAddShift(element, 'left');
  if (left) {
    left.focus();
  } else {
    (getEmployeeCellInTheSameRow(element) as HTMLElement).focus();
  }
};

const shiftsTableOverridePressTab = (element: HTMLElement) => {
  const cellBelow = getAdjacentAddShift(element, 'down');

  if (cellBelow) (getEmployeeCellInTheSameRow(cellBelow) as HTMLElement).focus();
};

const employeeCellSpecialShiftTabCallback = (element: HTMLElement): void => {
  const employeeCellAbove = getAdjacentEmployeeCell(element, 'up');
  if (employeeCellAbove) {
    focusLastShiftCellInTheSameRow(employeeCellAbove);
  }
};

const handleKeyDownResourceLabelEmployeeName = (
  event: React.KeyboardEvent<HTMLButtonElement> | KeyboardEvent,
  employeeCell: HTMLElement
): void => {
  if (event.key === 'ArrowRight' || (event.key === 'Tab' && !event.shiftKey)) {
    event.preventDefault();
    focusFirstShiftCellInTheSameRow(employeeCell);
  }

  if (event.key === 'ArrowUp') {
    const employeeAbove = getAdjacentEmployeeCell(employeeCell, 'up');
    if (employeeAbove) employeeAbove.focus();
  }

  if (event.key === 'ArrowDown') {
    const employeeBelow = getAdjacentEmployeeCell(employeeCell, 'down');
    if (employeeBelow) employeeBelow.focus();
  }

  if (event.key === 'Tab' && event.shiftKey) {
    /**
     * Overriding default Shift+Tab behaviour for tranfesring focus to last cell in row above in
     * Shifts table ONLY if current employeeCell is NOT in the first row
     */
    if (getAdjacentEmployeeCell(employeeCell, 'up')) {
      event.preventDefault();
      // Not necessary to have separate Tab behaviour - the right arrow will always suffice as there will always be shift in front of the employee portion of each row, so we will reuse it.
      employeeCellSpecialShiftTabCallback(employeeCell);
    }
  }
};

const setAddShiftListeners = (addShiftButton: HTMLElement, click: (element: HTMLElement) => void): void => {
  // Intended use is to modify HTMLElement sent through, so disabling this rule for this function
  addShiftButton.addEventListener('click', (event) => {
    if (click) {
      click(addShiftButton);
    }
  });

  addShiftButton.addEventListener('keydown', (event) => {
    if (event.key === 'ArrowLeft') {
      shiftsTableMoveLeft(addShiftButton);
    }

    if (event.key === 'ArrowRight') {
      getAdjacentAddShift(addShiftButton, 'right')?.focus();
    }

    if (event.key === 'ArrowUp') {
      getAdjacentAddShift(addShiftButton, 'up')?.focus();
    }

    if (event.key === 'ArrowDown') {
      getAdjacentAddShift(addShiftButton, 'down')?.focus();
    }

    if (event.key === 'Tab' && !event.shiftKey) {
      // Overriding default tab behaviour when not in last column - to simulate moving right (as logic for focusing on shifts is encapsulated in there)
      // and when in last column but not last row - to switch to next row in Employees table (headers on the left).
      // This way, when we get to last column in last row, default tab will occur and focus will leave table.

      const rightElement = getAdjacentAddShift(addShiftButton, 'right');

      if (rightElement) {
        event.preventDefault();
        rightElement.focus();
      } else if (getAdjacentAddShift(addShiftButton, 'down')) {
        event.preventDefault();
        // Not necessary to have separate Shift+Tab behaviour - the left arrow callback will always suffice as there will always be employee before the shift portion of each row, so we will reuse it.
        shiftsTableOverridePressTab(addShiftButton);
      }
    }
    if (event.key === 'Tab' && event.shiftKey) {
      // Overriding default Shift+Tab behaviour by focusing same-row cell in Employees table
      // (headers on the left) ONLY if current element the in the first column of shifts table
      const lastShiftEvent = getLastShiftEventInSameAddShiftCell(addShiftButton);

      if (lastShiftEvent) {
        event.preventDefault();
        getAdjacentAddShift(addShiftButton, 'up')?.focus();
      } else {
        event.preventDefault();
        shiftsTableMoveLeft(addShiftButton);
      }
    }
  });
};

const handleKeyDownEditShiftEvent = (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent, element: HTMLElement) => {
  if (event.key === 'ArrowDown') {
    const shiftElementBelow = getAdjacentShift(element, 'after');
    if (shiftElementBelow) shiftElementBelow.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(element);
      if (addShiftInSameCell) addShiftInSameCell?.focus();
    }
  }

  if (event.key === 'ArrowUp') {
    const shiftElementAbove = getAdjacentShift(element, 'before');
    if (shiftElementAbove) shiftElementAbove.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(element);
      if (addShiftInSameCell) getAdjacentAddShift(addShiftInSameCell, 'up', true)?.focus();
    }
  }

  if (event.key === 'ArrowRight') {
    const addShiftInSameCell = getAddShiftFromShiftEvent(element);
    if (addShiftInSameCell) getAdjacentAddShift(addShiftInSameCell, 'right')?.focus();
  }

  if (event.key === 'ArrowLeft') {
    const addShiftInSameCell = getAddShiftFromShiftEvent(element);
    if (addShiftInSameCell) shiftsTableMoveLeft(addShiftInSameCell);
  }

  if (event.key === 'Tab' && !event.shiftKey) {
    event.preventDefault();
    const shiftElementBelow = getAdjacentShift(element, 'after');
    if (shiftElementBelow) shiftElementBelow.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(element);
      if (addShiftInSameCell) addShiftInSameCell?.focus();
    }
  }

  if (event.key === 'Tab' && event.shiftKey) {
    event.preventDefault();
    const addShiftCell = getAddShiftFromShiftEvent(element);
    if (addShiftCell) {
      const firstShiftEvent = getFirstShiftEventInSameAddShiftCell(addShiftCell);
      if (element.dataset.testid === firstShiftEvent?.dataset.testid) {
        const addShiftInSameCell = getAddShiftFromShiftEvent(element);
        if (addShiftInSameCell) shiftsTableMoveLeft(addShiftInSameCell);
      } else {
        const shiftEventAbove = getAdjacentShift(element, 'before');
        if (shiftEventAbove) shiftEventAbove.focus();
      }
    }
  }
};

const getShiftInfoFromEvent = (title: string, shiftDateTime: string): ShiftInfo => {
  const startTime = getReadableTimeFormatFromShiftEventLabel(title.split('-')[0]);
  const endTimeAndJobcode = title.split('-')[1].trim();
  const endTime = getReadableTimeFormatFromShiftEventLabel(
    endTimeAndJobcode.substring(0, endTimeAndJobcode.indexOf(' '))
  );
  const jobCodeName = endTimeAndJobcode.substring(endTimeAndJobcode.indexOf(' ') + 1);
  const shiftDate = dayjs(shiftDateTime.split('T')[0]).format('LL');

  return { shiftStartTime: startTime, shiftEndTime: endTime, jobCode: jobCodeName, date: shiftDate };
};

const removeNarratorBlockerInFullCalendarLib = (fullCalendarElement: Element) => {
  if (fullCalendarElement) {
    fullCalendarElement
      .querySelectorAll("table[aria-hidden='true']")
      ?.forEach((el) => el?.removeAttribute('aria-hidden'));

    fullCalendarElement.querySelectorAll("table[role='grid']")?.forEach((el) => el?.removeAttribute('role'));
    ['presentation', 'rowgroup', 'grid', 'gridcell', 'row', 'columnheader'].forEach((role) => {
      fullCalendarElement.querySelectorAll(`table [role="${role}"]`)?.forEach((el) => el?.removeAttribute('role'));
    });
  }
};

const SchedulingA11yUtilities = {
  handleKeyDownResourceLabelEmployeeName,
  setAddShiftListeners,
  getAdjacentEmployeeCell,
  getAdjacentAddShift,
  handleKeyDownEditShiftEvent,
  getShiftInfoFromEvent,
  removeNarratorBlockerInFullCalendarLib,
};

export default SchedulingA11yUtilities;
