import Grid, { GridProps } from '@mui/material/Grid';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { SxProps } from '@mui/material/styles';
import { InputBaseProps } from '@mui/material/InputBase';
import { useEffect, useState } from 'react';
import { Controller, FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { isNil } from 'lodash';
import { getCurrencyFormat } from '../../utils';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';

export type TCurrencyProps = {
  formContext: UseFormReturn<FieldValues>;
  gridProps?: GridProps;
  label?: string;
  name: string;
  dataTestId: string;
  includeCurrencySpace?: boolean;
  textFieldSx?: SxProps;
  variant?: TextFieldVariants;
  required?: boolean;
  allowNegative?: boolean;
  onValueChange?: (newValue: NumberFormatValues, id?: string) => void;
  onBlur?: () => void;
  inputValue?: number;
  helperText?: string;
  readOnly?: boolean;
  defaultValue?: number;
  inputSx?: InputBaseProps['inputProps'];
  useNumberDefault?: boolean;
};

// in case we are dealing with objects
// e.g name = rules.0.rule.amount
function hasError(errorObject: FieldErrors<FieldValues>, dotNotationPath: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let returnValue: any = errorObject;

  const tokens = dotNotationPath.split('.');

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const index in tokens) {
    returnValue = returnValue[tokens[index]];
    if (!returnValue) break;
  }

  return !!returnValue;
}

function CurrencyTextField(props: TCurrencyProps) {
  const {
    formContext,
    gridProps,
    textFieldSx,
    variant,
    label,
    required,
    allowNegative,
    name,
    dataTestId,
    includeCurrencySpace,
    onValueChange,
    onBlur,
    inputValue,
    helperText,
    readOnly,
    defaultValue,
    inputSx,
    useNumberDefault,
  } = props;
  const { preferences } = useAccountPreferences();
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
  } = formContext;

  register(name, { required });

  const { options, locale } = getCurrencyFormat(preferences);
  const opts: Intl.NumberFormatOptions = { ...(options as Intl.NumberFormatOptions), style: 'currency' };
  const parts = Intl.NumberFormat(locale, opts).formatToParts(123456.78);
  let currencySymbol: string | undefined;
  let decimalSymbol: string | undefined;
  let thousandSeparator: string | undefined;
  parts.forEach((v) => {
    if (v.type === 'currency') {
      currencySymbol = v.value;
    } else if (v.type === 'decimal') {
      decimalSymbol = v.value;
    } else if (v.type === 'group') {
      thousandSeparator = v.value;
    }
  });

  const [prefix, setPrefix] = useState<string>();
  const [suffix, setSuffix] = useState<string>();

  let helperTextValue = '';
  if (hasError(errors, name)) {
    helperTextValue = t('formMessage.required');
  } else if (helperText) {
    helperTextValue = helperText;
  }

  const extraSpace = includeCurrencySpace ? ' ' : '';
  useEffect(() => {
    if (!isNil(preferences) && preferences.showCurrencySymbol) {
      if (parts[0].type === 'currency') {
        setPrefix(`${currencySymbol}${extraSpace}`);
      } else {
        setSuffix(`${extraSpace}${currencySymbol}`);
      }
    }
  }, [preferences, parts, currencySymbol, extraSpace, setPrefix, setSuffix]);

  const defaultEmptyValue = useNumberDefault ? 0 : '';

  return (
    <Controller
      control={control}
      name={name}
      key={name}
      render={({ field: { ref, onChange, value, ...field } }) => (
        <Grid item {...gridProps}>
          <NumericFormat
            sx={{ ...textFieldSx }}
            {...field}
            inputRef={ref}
            value={inputValue ?? value}
            onValueChange={(v) => {
              onChange(v.floatValue ?? defaultEmptyValue);
              if (onValueChange) onValueChange(v, dataTestId);
            }}
            onBlur={onBlur}
            customInput={TextField}
            prefix={prefix}
            suffix={suffix}
            fixedDecimalScale
            thousandSeparator={thousandSeparator}
            decimalScale={2}
            decimalSeparator={decimalSymbol}
            variant={variant}
            label={label && label.length > 0 ? t(label) : ''}
            fullWidth
            required={required}
            allowNegative={allowNegative}
            error={hasError(errors, name)}
            data-testid={dataTestId}
            inputProps={{
              'data-testid': `${dataTestId}-field`,
              readOnly,
              ...inputSx,
            }}
            defaultValue={defaultValue}
            helperText={helperTextValue}
          />
        </Grid>
      )}
    />
  );
}

CurrencyTextField.defaultProps = {
  includeCurrencySpace: true,
  textFieldSx: {},
  gridProps: {},
  label: '',
  variant: 'standard',
  required: false,
  allowNegative: false,
  onValueChange: undefined,
  onBlur: undefined,
  inputValue: undefined,
  helperText: undefined,
  readOnly: false,
  defaultValue: undefined,
  inputSx: {},
  useNumberDefault: false,
};

export default CurrencyTextField;
