import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useGridApiContext, GridRenderCellParams, GridGroupNode, GridRowId } from '@mui/x-data-grid-premium';
import { SxProps } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export interface CustomTreeGroupCellProps extends GridRenderCellParams {
  cellText?: string;
  cellLink?: string;
  isLink?: boolean;
  showDescendantCount?: boolean;
  textStyle?: SxProps;
  countDescendantLeafNodes?: boolean;
  offsetMultiplier?: number;
}

function CustomTreeGroupCell(params: CustomTreeGroupCellProps) {
  const apiRef = useGridApiContext();
  const {
    cellText,
    cellLink,
    isLink,
    showDescendantCount,
    textStyle,
    countDescendantLeafNodes,
    rowNode,
    formattedValue,
    offsetMultiplier = 10,
    ...rest
  } = params;

  const children = apiRef.current.getRowGroupChildren({
    groupId: rowNode.id,
    applyFiltering: true,
    applySorting: true,
  });
  const hasChildren = children && children.length > 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(
      rest.id,
      !apiRef.current.getRowNode<GridGroupNode>(rest.id)?.childrenExpanded
    );
    apiRef.current.setCellFocus(rest.id, rest.field);
    event.stopPropagation();
  };

  const getDescendantsLength: () => number = () => {
    if (!countDescendantLeafNodes) return children.length;
    let count = 0;
    children.forEach((childId: GridRowId) => {
      const child: GridRowId[] = apiRef.current.getRowGroupChildren({
        groupId: childId,
        applyFiltering: true,
        applySorting: true,
      });
      if (!child || child.length === 0) count += 1;
    });
    return count;
  };

  const treeCellText = (
    <Typography variant='body2' color='text/primary' sx={{ marginLeft: hasChildren ? '5px' : '45px', ...textStyle }}>
      {cellText || formattedValue}
      {showDescendantCount && hasChildren ? ` (${getDescendantsLength()})` : ''}
    </Typography>
  );

  return (
    <Box
      sx={{ marginLeft: rowNode.depth * offsetMultiplier, display: 'flex', alignItems: 'center' }}
      data-testid='tree-group-cell'
    >
      {hasChildren && (
        <IconButton onClick={handleClick}>
          {apiRef.current.getRowNode<GridGroupNode>(rest.id)?.childrenExpanded ? (
            <ExpandMoreIcon data-testid='expanded-row-icon' />
          ) : (
            <ChevronRightIcon data-testid='right-arrow-icon' />
          )}
        </IconButton>
      )}
      {isLink && cellLink ? <Link to={cellLink}>{treeCellText}</Link> : treeCellText}
    </Box>
  );
}

export default CustomTreeGroupCell;
