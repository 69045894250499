import CheckCircle from '@mui/icons-material/CheckCircle';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import PriorityHighOutlined from '@mui/icons-material/PriorityHighOutlined';
import Alert, { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarOrigin, SnackbarProps } from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationPriority } from '../admin/models/NotificationSettings';
import ContextProviderProps from '../models/ContextProviderProps';

export interface ISnackbarStatus extends SnackbarProps {
  color?: AlertColor;
  actionText?: string;
  actionCallback?: () => void;
  disableAutoHide?: boolean;
  notificationPriority?: NotificationPriority;
  hideCloseButton?: boolean;
  closeOnClickAway?: boolean;
  onCloseCallback?: () => void;
}

export type SnackbarContextActions = {
  setSnackbarState: (snackbarState: ISnackbarStatus) => void;
};

export const SnackbarContext = createContext({} as SnackbarContextActions);

function SnackbarProvider({ children }: ContextProviderProps) {
  const theme = useTheme();
  const isMedOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const defaultState: ISnackbarStatus = useMemo(
    () => ({
      open: false,
      autoHideDuration: 5000,
      color: 'info',
      disableAutoHide: false,
      notificationPriority: undefined,
      hideCloseButton: false,
      closeOnClickAway: true,
      anchorOrigin: isMedOrLess
        ? { vertical: 'bottom', horizontal: 'center' }
        : { vertical: 'top', horizontal: 'right' },
      sx: {},
      message: '',
    }),
    [isMedOrLess]
  );
  const [snackbarState, _setSnackbarState] = useState<ISnackbarStatus>(defaultState);

  const setSnackbarState = useCallback(
    (newSnackbarState: ISnackbarStatus) => {
      _setSnackbarState({ ...defaultState, ...newSnackbarState });
    },
    [defaultState]
  );

  function getIconFromPriority(notificationPriority?: NotificationPriority) {
    switch (notificationPriority) {
      case NotificationPriority.Normal:
        return <div />;
      case NotificationPriority.Priority:
        return <PriorityHighOutlined fontSize='inherit' style={{ color: theme.palette.primary.main }} />;
      default:
        return <Info fontSize='inherit' />;
    }
  }

  const snackbarValue = useMemo(
    () => ({
      snackbarState,
      setSnackbarState,
    }),
    [snackbarState, setSnackbarState]
  );
  const handleClose = () => {
    _setSnackbarState({ ...snackbarState, open: false });
    snackbarState.onCloseCallback?.();
  };

  return (
    <SnackbarContext.Provider value={snackbarValue}>
      <Snackbar
        data-testid='global-snackbar'
        open={snackbarState.open}
        autoHideDuration={snackbarState.disableAutoHide ? null : snackbarState.autoHideDuration}
        anchorOrigin={snackbarState.anchorOrigin as SnackbarOrigin}
        onClose={handleClose}
        ClickAwayListenerProps={snackbarState.closeOnClickAway ? {} : { onClickAway: () => null }}
        sx={snackbarState.sx}
      >
        <Alert
          data-testid='global-snackbar-alert'
          onClose={handleClose}
          iconMapping={{
            success: <CheckCircle fontSize='inherit' />,
            warning: <Warning fontSize='inherit' />,
            info: getIconFromPriority(snackbarState.notificationPriority),
            error: <ErrorIcon fontSize='inherit' />,
          }}
          severity={snackbarState.color}
          sx={{
            // TODO this ternary operator should be replaced in the future when we add a new severity for notifications
            backgroundColor: snackbarState.notificationPriority ? theme.palette.background.default : null,
            color: snackbarState.notificationPriority ? theme.palette.text.primary : null,
            marginTop: 7,
            marginRight: -1,
            whiteSpace: 'pre-line',
            boxShadow:
              '0px 6px 28px 5px rgba(0, 0, 0, 0.12), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 8px 9px -5px rgba(0, 0, 0, 0.2)',
          }}
          action={
            snackbarState.actionCallback && snackbarState.actionText ? (
              <>
                <Button color='inherit' size='small' onClick={() => snackbarState.actionCallback?.()}>
                  {snackbarState.actionText}
                </Button>
                {!snackbarState.hideCloseButton && (
                  <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                )}
              </>
            ) : (
              !snackbarState.hideCloseButton && (
                <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              )
            )
          }
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}

const useSnackbar = (): SnackbarContextActions => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbar must be used within an SnackbarProvider');
  }

  return context;
};

export { SnackbarProvider, useSnackbar };
