import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import {
  getRuleTokens,
  valueFormatter,
  isTippedMinWageRule,
  isMinorDefinedSchoolDaysRule,
  getValueByRegExpMatchArray,
} from '../utils/utils';
import {
  ChangeType,
  LaborRule,
  MinimumWageRule,
  MinorProhibitedHoursRule,
  WagesRuleTypes,
} from '../models/LaborRuleConfiguration';
import TippedMinWageTypography from '../TippedMinWageTypography/TippedMinWageTypography';
import SchoolDayDefinitionTypography from '../SchoolDayDefinitionTypography/SchoolDayDefinitionTypography';

export interface RuleTypographyProps {
  rule: LaborRule | ChangeType;
  templateString: string;
  dynamicSx?: SxProps;
  staticSx?: SxProps;
  isDrawer?: boolean;
}

/** Template strings formatted with {{parameters}} will access the
 * properties of the rule object with the string in the brackets (so
 * they should match) */
export default function RuleTypography(props: RuleTypographyProps) {
  const { rule, isDrawer, templateString, dynamicSx, staticSx } = props;
  const { t } = useTranslation();

  if (rule.type === WagesRuleTypes.MinimumWageRule && isTippedMinWageRule(rule) && !isDrawer) {
    return (
      <TippedMinWageTypography
        rule={rule as MinimumWageRule}
        templateString={t('laborRules.rules.MinimumWageRule.Tipped.template')}
      />
    );
  }

  if (isMinorDefinedSchoolDaysRule(rule as LaborRule) && !isDrawer) {
    return <SchoolDayDefinitionTypography rule={rule as MinorProhibitedHoursRule} />;
  }

  return (
    <Box>
      {templateString !== ''
        ? getRuleTokens(templateString).map((match, i) => {
            // If there's a second non-falsey element we're working with a dynamic value
            if (match[1]) {
              const value = getValueByRegExpMatchArray(match, rule);
              return (
                <Box
                  component='span'
                  key={`${match[1]}-${value}`}
                  sx={dynamicSx}
                  data-testid={`${rule.type}-rule-text-value-1`}
                >
                  {valueFormatter(value, match[1], rule.type, t)}
                </Box>
              );
            }
            return (
              <Box component='span' key={`${match[0]}`} sx={staticSx} data-testid={`${rule.type}-rule-text-value-2`}>
                {match[0]}
              </Box>
            );
          })
        : t('laborRules.rulesGuidance.noGuidance')}
    </Box>
  );
}

RuleTypography.defaultProps = {
  dynamicSx: { fontWeight: 'bold' },
  staticSx: {},
  isDrawer: false,
};
