import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch, { SwitchProps } from '@mui/material/Switch';
import React from 'react';

interface ToggleButtonProps extends SwitchProps {
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

function ToggleButton({ label, onChange, checked, disabled, labelPlacement, name }: ToggleButtonProps) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch onChange={onChange} checked={checked} />}
        label={label}
        labelPlacement={labelPlacement}
        data-testid={`${name}-toggle-button`}
        disabled={disabled}
      />
    </FormGroup>
  );
}

ToggleButton.defaultProps = {
  onChange: null,
  labelPlacement: 'end',
};

export default ToggleButton;
