import Box, { BoxProps } from '@mui/material/Box';

function SidebarFooter(props: BoxProps) {
  const { children, sx, ...rest } = props;

  return (
    <Box
      px={1}
      py={2}
      display='flex'
      justifySelf='flex-end'
      justifyContent='center'
      alignItems='center'
      {...rest}
      sx={{
        height: 64,
        flexShrink: 0,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default SidebarFooter;
