import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Sales as SalesRequestTypes } from '@cbo/shared-library/request';
import useSalesRequests, {
  CreateGlAccountRequest,
  CreateGlAccountsResponse,
  GlAccountsDeleteRequest,
  HouseAccount,
  HouseAccountStatement,
  Id,
  KPIDataRequest,
  KPIDataResponse,
  Transaction,
  UpdateGlAccountRequest,
  UpdateIsActiveGlAccountsRequest,
  UpdateSalesDefinitionsSettings,
  CreateJournalEntryRequest,
  JournalEntryId,
} from './requests';
import {
  CreateStatementPDFBodySchema,
  CreateTransactionBodySchema,
  CreateTransactionPDFBodySchema,
  HouseAccountRequestSchema,
  HouseAccountResponseSchema,
  PatchStatementBodySchema,
  StatementBodySchema,
  UpdateHouseAccountRequestSchema,
} from '../types';

interface PdfMutationResult {
  transactionId: string;
  isDownload: boolean;
  transactionNumber: string | undefined;
  pdfContent: string | undefined;
}

interface PdfMutationVariables {
  body: CreateTransactionPDFBodySchema | SalesRequestTypes.TransactionPdfDto;
  transactionId: string;
  transactionNumber: string | undefined;
}

interface CreateStatementVariables {
  houseAccountId: string;
  body: StatementBodySchema;
}

interface PatchStatementVariables {
  statementId: string;
  body: PatchStatementBodySchema;
}

interface PatchHouseAccountVariables {
  houseAccountId: string;
  body: UpdateHouseAccountRequestSchema;
}

interface PutHouseAccountVariables {
  houseAccountId: string;
  body: HouseAccountRequestSchema;
}

interface CreateTransactionVariables {
  houseAccountId: string;
  body: CreateTransactionBodySchema;
}

interface UpdateGlAccountVariables {
  glAccountId: string;
  body: UpdateGlAccountRequest;
}

interface UpdateJournalEntryVariables {
  body: Partial<CreateJournalEntryRequest>;
  id: string;
}

const useCreatePdfMutation = () => {
  const { createPdf } = useSalesRequests();

  async function mutationFunc({ body, transactionId, transactionNumber }: PdfMutationVariables) {
    const result: PdfMutationResult = {
      transactionId,
      isDownload: !transactionNumber,
      transactionNumber,
      pdfContent: await createPdf(transactionNumber ? { ...body, transactionNumber } : body),
    };
    return result;
  }

  return useMutation<PdfMutationResult, unknown, PdfMutationVariables, unknown>(mutationFunc, {});
};

const useCreateStatementPdfMutation = () => {
  const { createStatementPDF } = useSalesRequests();

  async function mutationFunc(body: CreateStatementPDFBodySchema) {
    const results = await createStatementPDF(body);
    return results;
  }

  return useMutation<string, unknown, CreateStatementPDFBodySchema, unknown>(mutationFunc, {});
};

const useCreateStatementMutation = () => {
  const { createStatement } = useSalesRequests();

  async function mutationFunc({ houseAccountId, body }: CreateStatementVariables) {
    const results = await createStatement(houseAccountId, body);
    return results;
  }

  return useMutation<HouseAccountStatement | undefined, unknown, CreateStatementVariables, unknown>(mutationFunc, {});
};

const useDeleteStatementMutation = () => {
  const { deleteStatement } = useSalesRequests();

  async function mutationFunc(statementId: string) {
    const results = await deleteStatement(statementId);
    return results;
  }

  return useMutation<boolean, unknown, string, unknown>(mutationFunc, {});
};

const usePatchStatementMutation = () => {
  const { patchStatement } = useSalesRequests();

  async function mutationFunc({ statementId, body }: PatchStatementVariables) {
    const results = await patchStatement(statementId, body);
    return results;
  }

  return useMutation<boolean, unknown, PatchStatementVariables, unknown>(mutationFunc, {});
};

const useCreateHouseAccountMutation = () => {
  const { createHouseAccount } = useSalesRequests();

  async function mutationFunc(body: HouseAccountRequestSchema) {
    const results = await createHouseAccount(body);
    return results;
  }

  return useMutation<HouseAccountResponseSchema | undefined, unknown, HouseAccountRequestSchema, unknown>(
    mutationFunc,
    {}
  );
};

const usePatchHouseAccountMutation = () => {
  const { patchHouseAccount } = useSalesRequests();

  async function mutationFunc({ houseAccountId, body }: PatchHouseAccountVariables) {
    const results = await patchHouseAccount(houseAccountId, body);
    return results;
  }

  return useMutation<boolean, unknown, PatchHouseAccountVariables, unknown>(mutationFunc, {});
};

const useRestoreHouseAccountMutation = () => {
  const { restoreHouseAccount } = useSalesRequests();

  async function mutationFunc(houseAccountId: string) {
    const results = await restoreHouseAccount(houseAccountId);
    return results;
  }

  return useMutation<boolean, unknown, string, unknown>(mutationFunc, {});
};

const useDeleteHouseAccountMutation = () => {
  const { deleteHouseAccount } = useSalesRequests();

  async function mutationFunc(houseAccountId: string) {
    const results = await deleteHouseAccount(houseAccountId);
    return results;
  }

  return useMutation<boolean, unknown, string, unknown>(mutationFunc, {});
};

const usePutHouseAccountMutation = () => {
  const { putHouseAccount } = useSalesRequests();

  async function mutationFunc({ houseAccountId, body }: PutHouseAccountVariables) {
    const results = await putHouseAccount(houseAccountId, body);
    return results;
  }

  return useMutation<HouseAccount | undefined, unknown, PutHouseAccountVariables, unknown>(mutationFunc, {});
};

const useCreateTransactionMutation = () => {
  const { createTransaction } = useSalesRequests();

  async function mutationFunc({ houseAccountId, body }: CreateTransactionVariables) {
    const results = await createTransaction(houseAccountId, body);
    return results;
  }

  return useMutation<Transaction, unknown, CreateTransactionVariables, unknown>(mutationFunc, {});
};

const useGetKPIDataMutation = () => {
  const { getKPIData } = useSalesRequests();

  async function mutationFunc({ body }: { body: KPIDataRequest }) {
    const results = await getKPIData(body);
    return results;
  }

  return useMutation<KPIDataResponse, unknown, { body: KPIDataRequest }, unknown>(mutationFunc, {});
};

const useCreateGlAccountMutation = () => {
  const { createGlAccount } = useSalesRequests();

  async function mutationFunc(body: CreateGlAccountRequest) {
    const results = await createGlAccount(body);
    return results;
  }

  return useMutation<CreateGlAccountsResponse | undefined, unknown, CreateGlAccountRequest, unknown>(mutationFunc, {});
};

const useDeleteGlAccountMutation = () => {
  const { deleteGlAccount } = useSalesRequests();

  async function mutationFunc(body: GlAccountsDeleteRequest) {
    return deleteGlAccount(body);
  }

  return useMutation<boolean, unknown, GlAccountsDeleteRequest, unknown>(mutationFunc, {});
};

const useUpdateGlAccountMutation = () => {
  const { updateGlAccount } = useSalesRequests();

  async function mutationFunc({ glAccountId, body }: UpdateGlAccountVariables) {
    return updateGlAccount(glAccountId, body);
  }

  return useMutation<boolean, unknown, UpdateGlAccountVariables, unknown>(mutationFunc, {});
};

const useRestoreDeleteGlAccountMutation = () => {
  const { restoreDeleteGlAccount } = useSalesRequests();

  async function mutationFunc(body: GlAccountsDeleteRequest) {
    const results = await restoreDeleteGlAccount(body);
    return results;
  }

  return useMutation<boolean, unknown, GlAccountsDeleteRequest, unknown>(mutationFunc, {});
};

const useUpdateIsActiveGlAccountsMutation = () => {
  const { updateIsActiveGlAccounts } = useSalesRequests();

  async function mutationFunc(body: UpdateIsActiveGlAccountsRequest) {
    const results = await updateIsActiveGlAccounts(body);
    return results;
  }

  return useMutation<boolean, unknown, UpdateIsActiveGlAccountsRequest, unknown>(mutationFunc, {});
};

const useUploadGlAccountsMutation = () => {
  const { uploadGlAccounts } = useSalesRequests();

  async function mutationFunc(file: File) {
    const results = await uploadGlAccounts(file);
    return results;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMutation<AxiosResponse<any, any>, unknown, File, unknown>(mutationFunc, {});
};

const useUpdateSalesDefinitionsSettings = () => {
  const { updateSalesDefinitionsSettings } = useSalesRequests();

  async function mutationFunc(body: UpdateSalesDefinitionsSettings) {
    const results = await updateSalesDefinitionsSettings(body);
    return results;
  }

  return useMutation<Id, unknown, UpdateSalesDefinitionsSettings, unknown>(mutationFunc, {});
};

const useCreateJournalEntryMutation = () => {
  const { createJournalEntry } = useSalesRequests();

  async function mutationFunc(body: CreateJournalEntryRequest) {
    const results = await createJournalEntry(body);
    return results;
  }

  return useMutation<JournalEntryId | undefined, unknown, CreateJournalEntryRequest, unknown>(mutationFunc, {});
};

const useUpdateJournalEntryMutation = () => {
  const { updateJournalEntry } = useSalesRequests();

  async function mutationFunc({ body, id }: UpdateJournalEntryVariables) {
    const results = await updateJournalEntry(body, id);
    return results;
  }

  return useMutation<JournalEntryId | undefined, unknown, UpdateJournalEntryVariables, unknown>(mutationFunc, {});
};

const useDeleteJournalEntryMutation = () => {
  const { deleteJournalEntry } = useSalesRequests();

  async function mutationFunc(id: string) {
    return deleteJournalEntry(id);
  }

  return useMutation<JournalEntryId | undefined, unknown, string, unknown>(mutationFunc, {});
};

export {
  useCreatePdfMutation,
  useCreateStatementPdfMutation,
  useCreateStatementMutation,
  useDeleteStatementMutation,
  usePatchStatementMutation,
  useCreateHouseAccountMutation,
  usePatchHouseAccountMutation,
  useRestoreHouseAccountMutation,
  useDeleteHouseAccountMutation,
  usePutHouseAccountMutation,
  useCreateTransactionMutation,
  useGetKPIDataMutation,
  useCreateGlAccountMutation,
  useDeleteGlAccountMutation,
  useUpdateGlAccountMutation,
  useUploadGlAccountsMutation,
  useRestoreDeleteGlAccountMutation,
  useUpdateIsActiveGlAccountsMutation,
  useUpdateSalesDefinitionsSettings,
  useCreateJournalEntryMutation,
  useUpdateJournalEntryMutation,
  useDeleteJournalEntryMutation,
};
