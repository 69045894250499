import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { getCustomDesignTheme } from './navigationUtils';

// eslint-disable-next-line react/require-default-props
export type NavigationBackButtonProps = { title?: string } & Omit<ListItemButtonProps, 'title' | 'children'>;

export function NavigationBackButton(props: NavigationBackButtonProps) {
  const { title, ...rest } = props;
  const theme = useTheme();
  const { mode } = theme.palette;
  const customDesignComponentStyles = getCustomDesignTheme(mode);
  return (
    <ListItemButton
      sx={{
        color: theme.palette.text.secondary,
        marginTop: '8px',
        '&.MuiListItemButton-root:hover': {
          backgroundColor: customDesignComponentStyles.emptyHovered,
          borderRadius: '0.5rem',
        },
      }}
      {...rest}
    >
      <ListItemAvatar
        sx={{
          minWidth: 18,
          mr: '8px',
          display: 'flex',
          color: theme.palette.text.secondary,
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiSvgIcon-root': {
            width: '18px',
            height: '18px',
          },
        }}
      >
        <ArrowBackOutlinedIcon />
      </ListItemAvatar>
      {typeof title === 'string' ? <ListItemText>{title}</ListItemText> : title}
    </ListItemButton>
  );
}
