import Grid, { GridProps } from '@mui/material/Grid';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox';
import createFormTextField from '../../../components/FormTextFields/FormTextFields';

export interface AcknowledgementCheckboxProps {
  datatestid: string;
  label: string;
  name: string;
  value: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, object>;
  gridProps?: GridProps;
}

export interface AcknowledgementInputProps {
  name: string;
  dataTestId: string;
  readOnly?: boolean;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  variant?: string;
  disableUnderline?: boolean;
  options?: { label: string; value: string }[];
}

export interface AcknowledgementDateInputProps {
  name: string;
  dataTestId: string;
  readOnly?: boolean;
  required: boolean;
  disableUnderline?: boolean;
  hasHelperText?: boolean;
  variant?: string;
  minDate?: string;
}

interface AcknowledgementInputAreaProps {
  firstInput: AcknowledgementInputProps;
  dateInput: AcknowledgementDateInputProps;
  checkboxOne: AcknowledgementCheckboxProps;
  checkboxTwo: AcknowledgementCheckboxProps | null;
  tKey: string;
}

export default function AcknowledgementInputArea(props: AcknowledgementInputAreaProps) {
  const { firstInput, dateInput, checkboxOne, checkboxTwo, tKey } = props;
  const formContext = useFormContext();
  const { register } = formContext;
  const { t } = useTranslation();

  return (
    <Grid item container spacing={2} direction='column'>
      {FormCheckbox(checkboxOne.datatestid, checkboxOne, true)}
      {checkboxTwo && FormCheckbox(checkboxTwo.datatestid, checkboxTwo, true)}
      <Grid item container xs={12} direction='row' justifyContent='space-around'>
        <Grid item xs={6} sx={{ pr: 1 }}>
          {createFormTextField(firstInput, { t, tKey }, register)}
        </Grid>
        <Grid item xs={6} sx={{ pl: 1 }}>
          {createFormTextField({ inputFormat: 'MM/DD/YYYY', ...dateInput }, { t, tKey }, register)}
        </Grid>
      </Grid>
    </Grid>
  );
}
