import Box from '@mui/material/Box';
import { StackProps } from '@mui/material/Stack';

function SidebarContent(props: StackProps) {
  const { children } = props;

  return (
    <Box
      sx={{
        height: 'calc(100% - 128px)',
        overflowY: 'auto',
        padding: '0 16px',
      }}
    >
      {children}
    </Box>
  );
}

export default SidebarContent;
